
const ArrayHelper = {
    
    getNextOrderNumber(array: any[]) {
        if ( !array || array.length === 0) {
            return 0;
        }

        const sortedArray: any[] = this.sortByOrder(array).reverse();
        return sortedArray[0].order + 1;
    },

    sortByOrder(items: any[]): any[] {

        if (items == null) {
            return items;
        }

        return items.sort((obj1, obj2) => {
            if (obj1.order > obj2.order) {
                return 1;
            }

            if (obj1.order < obj2.order) {
                return -1;
            }

            return 0;
        });
    },

    sortByField(items: any[], field: string): any[] {

        if (items == null) {
            return items;
        }

        return items.sort((obj1, obj2) => {
            if (obj1[field] > obj2[field]) {
                return 1;
            }

            if (obj1[field] < obj2[field]) {
                return -1;
            }

            return 0;
        });
    },

}

export default ArrayHelper;
