import * as React from 'react';
import {useState} from 'react';
import {format} from "date-fns";
import {SkillConversation, SmartGoalStatus} from "./CoachingModel";
import CoachingHelper from "./CoachingHelper";
import {Button} from "primereact/button";
import {CoachingService} from "./CoachingService";
import {User} from "../Shared/UserModel";

type Props = {
    conversation: SkillConversation;
    updateConversationsFn?: any;
    user: User;
};

export function SmartGoalViewer(props: Props) {
    
    const formModel = CoachingHelper.skillFormModel;
    const [loading, setLoading] = useState<boolean>(false);

    function getButtonLabel() {
        return props.conversation.smartGoal.achieved ? 'Mark as Open' : 'Mark as Achieved';
    }
    
    function updateStatus() {
        setLoading(true);
        if (props.conversation.smartGoal.achieved) {
            props.conversation.smartGoal.achieved = null
            props.conversation.smartGoal.status = SmartGoalStatus[SmartGoalStatus.OPEN];
        } else {
            props.conversation.smartGoal.achieved = new Date();
            props.conversation.smartGoal.status = SmartGoalStatus[SmartGoalStatus.COMPLETED];
        } 
        
        CoachingService.getInstance().upsertConversation(props.conversation).then(response => {
           props.updateConversationsFn(response.data);
           setLoading(false);
        });
    }

    return (
        <div className="smart-goal-viewer-container">
            <div className="grid">
                <div className="col-6 md:col-6 sm:col-12">
                    <div className="field conversation-response">
                        <h3>{formModel.formField.smartGoal.label}</h3>
                        <p>{props.conversation.smartGoal.smartGoalText}</p>
                    </div>
                </div>
                <div className="col-3 md:col-3 sm:col-12">
                    <div className="field conversation-response">
                        <h3>{formModel.formField.smartGoalDueDate.label}</h3>
                        <p>{format(new Date(props.conversation.smartGoal.dueDate), 'EEEE do MMMM yyyy')}</p>
                    </div>
                </div>
            </div>
            {props.user.precedaNumber == props.conversation.employeeId &&
                    <div className="field conversation-response">
                        <Button label={getButtonLabel()} onClick={() => updateStatus()} loading={loading}/>
                    </div>
            }
        </div>
    );
};