import React from 'react';
import {Menubar} from "primereact/menubar";
import './Navbar.css';
import {useNavigate} from "react-router-dom";
import {User} from "../Shared/UserModel";
import UserHelper from "../Shared/UserHelper";
import {Permission} from "../Admin/Permissions/PermissionModel";

type PropsType = any & {
    fnSignOut: Function,
    user: User,
    history: any
}

function Navbar(props: PropsType) {

    const navigate = useNavigate();

    const canViewPlanning = (): boolean => {
        return canViewBusinessPlan() || canViewBusinessExplorer();
    }

    const canViewAdmin = (): boolean => {
        return canViewPermissions() || canViewTemplateManager();
    }

    const canViewPermissions = (): boolean => {
        return UserHelper.hasPermission(props.user, Permission.PERMISSION_VIEWER);
    }

    const canViewTemplateManager = (): boolean => {
        return UserHelper.hasPermission(props.user, Permission.CONSULTING_TEMPLATE_CREATOR);
    }

    const canViewBusinessPlan = (): boolean => {
        return UserHelper.hasPermission(props.user, Permission.BUSINESS_PLAN_VIEWER);
    }

    const canViewBusinessExplorer = (): boolean => {
        return UserHelper.hasPermission(props.user, Permission.BUSINESS_PLAN_EXPLORER_VIEWER);
    }

    const navigateToPage = (path: string) => {
        navigate(path);
    }

    const setVisible = (): string => {
        return 'visible';
    }

    const setHidden = (): string => {
        return 'hidden';
    }

    const canViewObservations = () => {
        return UserHelper.hasPermission(props.user, Permission.CONSULTING_OBSERVATION_VIEWER);
    }

    const canViewObservationExplorer = () => {
        return UserHelper.hasPermission(props.user, Permission.OBSERVATION_EXPLORER_VIEWER);
    }

    const canViewSkill = () => {
        return canViewObservations() || canViewObservationExplorer() || canViewCoaching();
    }

    function canViewCoaching() {
        return UserHelper.hasPermission(props.user, Permission.COACHING_VIEWER);
    }

    const items = [
        {
            label: 'Home',
            command: () => navigateToPage('/')
        },
        {
            label: 'Skill',
            className: 'skill-menu ' + (canViewSkill() ? setVisible() : setHidden()),
            items: [
                {
                    label: 'Observations',
                    className: 'observation-menu ' + (canViewObservations() ? setVisible() : setHidden()),
                    command: () => navigateToPage('/observation')
                },
                {
                    label: 'Observation Explorer',
                    className: 'observation-menu ' + (canViewObservationExplorer() ? setVisible() : setHidden()),
                    command: () => navigateToPage('/observation/explorer')
                },
                {
                    label: 'Coaching',
                    className: 'coaching-menu ' + (canViewCoaching() ? setVisible() : setHidden()),
                    command: () => navigateToPage('/coaching')
                }
            ]
        },
        {
            label: 'Planning',
            className: 'planning-menu ' + (canViewPlanning() ? setVisible() : setHidden()),
            items: [{
                label: 'Business Plan',
                className: 'business-plan-menu ' + ((canViewBusinessPlan() || canViewBusinessExplorer()) ? setVisible() : setHidden()),

                command: () => navigateToPage('business-plan')
            },
            ]
        },
        {
            label: 'Admin',
            className: 'admin-menu ' + (canViewAdmin() ? setVisible() : setHidden()),
            items: [
                {
                    label: 'Permissions',
                    className: 'permission-menu ' + (canViewPermissions() ? setVisible() : setHidden()),
                    command: () => navigateToPage('permissions')
                },
                {
                    label: 'Configuration',
                    className: 'configuration-menu ' + (canViewAdmin() ? setVisible() : setHidden()),
                    command: () => navigateToPage('/admin/configuration')
                }
            ]
        },
        {
            label: props.user?.fullName as string,
            className: 'user-menu',
            items: [{
                label: 'Logout',
                icon: 'pi pi-fw pi-sign-out',
                command: () => {
                    navigateToPage('/');
                    props.fnSignOut();
                }
            }
            ]
        }
    ];

    return (
        <div className="app-navbar">

            <Menubar model={items} start={
                <div className="logo">
                    <img width="50" alt="Artisan Logo" src="/images/Canvas.jpg"/>
                </div>}
            />
        </div>

    )


}

export default Navbar;