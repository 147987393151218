import React, {useEffect, useState} from "react";
import {User} from "../Shared/UserModel";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {CoachingService} from "./CoachingService";
import {SkillConversation} from "./CoachingModel";
import CoachingConversationLog from "./CoachingConversationLog";

interface CoachingDashboardProps {
    user: User;
}

function CoachingDashboard(props: CoachingDashboardProps) {
    
    const navigate = useNavigate();
    const [conversations, setConversations] = useState<SkillConversation[]>([]);
    
    useEffect(() => {
        CoachingService.getInstance().getMyConversations().then(response => setConversations(response.data));
    }, []);
    
    function updateConversations(conversation: SkillConversation) {
        const filtered = conversations.filter((x) => x.id !== conversation.id);
        filtered.push(conversation)
        setConversations(filtered);
    }
    
    return (
        <div className="coaching-dashboard-container">
            <div className="header-group flex justify-content-between mb-5">
                <h2 className="mb-0">Coaching</h2>
                <Button className="app-button" label="Add Conversation" onClick={() => navigate('/coaching/new')} />
            </div>
            <CoachingConversationLog user={props.user} conversations={conversations} updateConversationsFn={updateConversations} />
        </div>

    )
}

export default CoachingDashboard;