import React, {useEffect, useState} from 'react';
import {BusinessPlanItemStatus, BusinessPlanItemStatusType} from "./BusinessPlanModel";
import {v4} from "uuid";
import BusinessPlanHelper from "./BusinessPlanHelper";
import {Button} from "primereact/button";

interface BusinessPlanItemStatusIndicatorProps {
    statusHistory: BusinessPlanItemStatus[];
}

function BusinessPlanItemStatusIndicator(props: BusinessPlanItemStatusIndicatorProps) {

    const [className, setClassName] = useState<string>();
    const [currentStatus, setCurrentStatus] = useState<BusinessPlanItemStatus>();

    useEffect(() => {
        if (props.statusHistory.length === 0) {
            const newStatus: BusinessPlanItemStatus = {
                addedBy: "",
                dateAdded: new Date(),
                id: v4(),
                status: BusinessPlanItemStatusType[BusinessPlanItemStatusType.NEW]
            }

            setCurrentStatus(newStatus);
            setClassName(`pi pi-circle item-status-indicator-icon ${newStatus.status}`);

        } else {
            const currentStatus = BusinessPlanHelper.getCurrentItemStatus(props.statusHistory);
            setCurrentStatus(currentStatus);
            setClassName(`pi pi-circle item-status-indicator-icon ${currentStatus.status}`);
        }

    }, [props.statusHistory]);

    const typeMetadataMap = new Map<BusinessPlanItemStatusType, { hoverText: string }>([
        [BusinessPlanItemStatusType.NEW, {hoverText: 'New and awaiting approval'}],
        [BusinessPlanItemStatusType.CHANGED, {hoverText: 'Changed and awaiting approval'}],
        [BusinessPlanItemStatusType.APPROVED, {hoverText: 'Approved'}],
        [BusinessPlanItemStatusType.CANCELLED, {hoverText: 'Cancelled'}],
        [BusinessPlanItemStatusType.DECLINED, {hoverText: 'Declined. Please make changes'}]
    ]);

    return (
        <>
            <div
                title={typeMetadataMap.get(BusinessPlanItemStatusType[currentStatus?.status as keyof typeof BusinessPlanItemStatusType])?.hoverText}
                className="item-status-indicator">
                <i className={className}/> {currentStatus?.status} {BusinessPlanHelper.isItemStatusDeclined(props.statusHistory) &&
            <Button type="button" className="show-decline-reason-button" label="Show Reason" tooltip={currentStatus?.declineReason || ''}
                    tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}}/>}
            </div>
        </>
    )
}

export default BusinessPlanItemStatusIndicator;