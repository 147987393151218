import React, {useEffect, useRef, useState} from 'react';
import './Observations.css';
import {Employee} from "../Shared/UserModel";
import {ObservationResult} from "./ObservationsModel";
import {format} from "date-fns";
import {Knob} from "primereact/knob";
import {ObservationHelper} from "./ObservationHelper";
import HierarchyService from "../Shared/HierarchyService";
import {OverlayPanel} from "primereact/overlaypanel";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import DateHelper from "../Shared/DateHelper";

interface ObservationResultSummaryProps {
    observationResults: ObservationResult;
}


function ObservationResultSummary(props: ObservationResultSummaryProps) {

    const [overallPercentage, setOverallPercentage] = useState<number>(0);
    const [overallScore, setOverallScore] = useState<number>(0);
    const [showScoreByStage, setShowScoreByStage] = useState<boolean>(false);
    const [totalWeighting, setTotalWeighting] = useState<number>(0);
    const [observerDetails, setObserverDetails] = useState<Employee>();
    const [scoreByStage, setScoreByStage] = useState<any[]>([]);
    const op = useRef<OverlayPanel>(null);


    useEffect(() => {
        let isMounted = true;
        calculateOverallScore();
        setScoreByStage(ObservationHelper.calculateScoreByStage(props.observationResults));
        HierarchyService.getInstance().fetchUser(props.observationResults.observer)
            .then(response => {
                if (isMounted) {
                    setObserverDetails(response.data);
                }
            });

        return () => {
            isMounted = false;
        }
    }, [])

    function calculateOverallScore() {
        const scoreObject = ObservationHelper.calculateOverallScore(props.observationResults);
        setOverallPercentage(scoreObject.percentageScore);
        setTotalWeighting(scoreObject.totalPoints);
        setOverallScore(scoreObject.pointsScore);
    }

    function toggleScoreByStage() {
        setShowScoreByStage(!showScoreByStage);
    }

    return (
        <div className="grid result-summary-container">
            <div className="col-8">
                <div className="details-container">
                    <h2 className="result-header">{props.observationResults?.candidateDetails?.preferredName} {props.observationResults?.candidateDetails?.surname}
                        <span style={{'color': 'black'}}> {props.observationResults?.templateName}</span> <i
                            className="pi pi-info-circle show-description-button"
                            onClick={(e) => op.current?.toggle(e)}/></h2>
                    <div className="candidate-details">
                        {props.observationResults?.candidateDetails?.fullTitle} | {props.observationResults?.candidateDetails?.businessUnitName}
                    </div>
                    <OverlayPanel ref={op} dismissable>
                        {props.observationResults.templateDescription ? props.observationResults.templateDescription : 'Observation description not found'}
                    </OverlayPanel>
                    <div className="observation-details">
                        Observed
                        By {observerDetails?.preferredName} {observerDetails?.surname} on {format(DateHelper.toLocalTime(props.observationResults?.dateAdded!), 'dd/MM/yyyy')}
                    </div>
                    {/* JA-159 - This code was hidden on purpose */}
                    {/*<div className="published-status" data-testid="published-status">*/}
                    {/*    <i className={`pi pi-circle published-icon ${props.observationResults?.published ? 'published' : 'unpublished'}`} /> {props.observationResults?.published ? `Published on ${format(new Date(props.observationResults?.datePublished!), 'dd/MM/yyyy')}` : 'Not Published'}*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="col-4">
                <div className="score-container">
                    {!showScoreByStage &&
                        <div className="overall-score-container">
                            <Knob value={overallPercentage} readOnly={true} valueTemplate={"{value}%"}
                                  valueColor={'#d07dcc'} size={200}/>
                            <div className="score-details-container">
                                {overallScore} / {totalWeighting}
                            </div>
                        </div>
                    }
                    {showScoreByStage &&
                        <div className="score-by-stage-container">
                            <div className="score-by-stage-table mx-auto">
                                <DataTable value={scoreByStage} size="small" scrollable scrollDirection="vertical"
                                           scrollHeight='225px'>
                                    <Column field="stage" header="Stage" sortable/>
                                    <Column field="totalScore" header="Scored Points" body={(s) => Math.round(s.totalScore * 10) / 10} sortable/>
                                    <Column field="totalWeighting" header="Max Points" sortable/>
                                    <Column field="scorePercent" header="Percent"
                                            body={(s) => `${Math.round(s.scorePercent)}%`} sortable/>
                                </DataTable>
                            </div>
                        </div>
                    }

                    <Button className="app-button"
                            label={showScoreByStage ? 'Show Overall Score' : 'Show Score by Stage'}
                            onClick={() => toggleScoreByStage()}/>
                </div>
            </div>
        </div>
    )
}

export default ObservationResultSummary;