
export interface PermissionToRole {
   id?: string;
   permission: string;
   associatedRoles: PositionToRole[];
}

export interface PositionToRole {
   id: string;
   name: string;
   associatedPositionTitles: string[];
   associatedEmployeeNumbers: string[];
   active: boolean;
}

export enum Permission {
   BUSINESS_PLAN_VIEWER,
   BUSINESS_PLAN_EXPLORER_VIEWER,
   BUSINESS_PLAN_CONFIGURATOR,
   PERMISSION_VIEWER,
   BUSINESS_PLAN_APPROVER,
   CONSULTING_TEMPLATE_CREATOR,
   CONSULTING_OBSERVATION_PERFORM,
   CONSULTING_OBSERVATION_PUBLISH,
   CONSULTING_OBSERVATION_VIEWER,
   OBSERVATION_EXPLORER_VIEWER,
   COACHING_VIEWER,
   CONSULTING_OBSERVATION_DELETE

}