import React from "react";
import BusinessPlanHelper from "./BusinessPlanHelper";
import {Button} from "primereact/button";
import {ItemSummary} from "./BusinessPlanSummary";

interface PropsType {
    approveFn: any;
    declineFn: any;
    showPreviousFn: any;
    itemSummary: ItemSummary;
    arrayTarget: string;
}

function PendingItemManagerButtons(props: PropsType) {

    return (
        <div className="item-manager-buttons">
            {BusinessPlanHelper.isItemStatusChanged(props.itemSummary.item?.itemStatusHistory) &&
                <Button icon="pi pi-history" className="app-button" tooltipOptions={{ mouseTrack: true, mouseTrackTop: 15 }}  onClick={(e) => props.showPreviousFn(e, props.itemSummary)}/> }
            <Button icon="pi pi-times" className="app-button danger" data-testid="decline-item-button" tooltip="Decline" tooltipOptions={{ mouseTrack: true, mouseTrackTop: 15 }} onClick={() => props.declineFn(props.itemSummary, props.arrayTarget)} />
            <Button icon="pi pi-check" className="app-button success" tooltip="Approve" tooltipOptions={{ mouseTrack: true, mouseTrackTop: 15 }} onClick={() => props.approveFn(props.itemSummary)} />
        </div>
    )
}

export default PendingItemManagerButtons;