import React from 'react';
import {useFormik} from "formik";
import * as Yup from "yup";
import {InputText} from "primereact/inputtext";
import './Observations.css';
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";
import {Message} from "primereact/message";
import TagDropdown from "./TagDropdown";

interface ObservationTemplateFormProps {
    handleSubmit: any;
    loading: boolean
    tags: string[];
}

function ObservationTemplateForm(props: ObservationTemplateFormProps) {
    const templateValidation = Yup.object().shape({
        name: Yup.string()
            .required('Name is a required field'),
        description: Yup.string()
            .required('Description is a required field'),
    });


    const newTemplateForm = useFormik({
        initialValues: {
            name: '',
            description: '',
            tag: ''
        },
        validationSchema: templateValidation,
        onSubmit: (data) => props.handleSubmit(data)
    });

    return (
        <div className="consulting-template-container">
            <form onSubmit={newTemplateForm.handleSubmit} className="template-form">

                <div className="field">
                        <span className="p-float-label">
                            <InputText id="name" name="name" title="Name" autoComplete="off"
                                       onChange={newTemplateForm.handleChange}/>
                            <label htmlFor="name">Template Name</label>
                        </span>
                    {newTemplateForm.errors.name && newTemplateForm.touched.name ? (
                        <div className="form-error-container" data-testid="error-container">
                            <Message severity="warn" text={newTemplateForm.errors.name}/>
                        </div>
                    ) : null}
                </div>

                <div className="field">
                       <span className="p-float-label">
                            <InputTextarea name="description" title="Description" autoComplete="off" id="description"
                                           rows={5}
                                           value={newTemplateForm.values.description}
                                           onChange={newTemplateForm.handleChange}/>
                            <label htmlFor="description">Template Description</label>   
                       </span>
                    {newTemplateForm.errors.description && newTemplateForm.touched.description ? (
                        <div className="form-error-container" data-testid="error-container">
                            <Message severity="warn" text={newTemplateForm.errors.description}/>
                        </div>
                    ) : null}
                </div>

                <div className="field">
                    <span className="p-float-label">
                        <TagDropdown value={newTemplateForm.values.tag} tags={props.tags} changeFn={newTemplateForm.handleChange} />
                        <label htmlFor="tag-input">Tag</label>
                    </span>
                    {newTemplateForm.errors.tag && newTemplateForm.touched.tag ? (
                        <Message severity="warn" text={newTemplateForm.errors.tag}/>
                    ) : null}
                </div>

                <div className="form-buttons">
                    <button type="button" className="app-button" onClick={() => newTemplateForm.resetForm()}>Clear
                    </button>
                    <Button id="submit-template-form-button" type="submit" data-testid="submit-template-button"
                            className="app-button danger"
                            loading={props.loading} label="Add"/>
                </div>
            </form>
        </div>
    )


}

export default ObservationTemplateForm;