import React from "react";
import Navbar from "./Navigation/Navbar";
import {Route, Routes} from "react-router-dom";
import Dashboard from "./Dashboard";
import {userContext} from "./App";
import BusinessPlanDashboard from "./BusinessPlan/BusinessPlanDashboard";
import BusinessPlan from "./BusinessPlan/BusinessPlan";
import PlanStructureManager from "./BusinessPlan/PlanStructureManager";
import ReferenceBusinessPlan from "./BusinessPlan/ReferenceBusinessPlan";
import UserList from "./Admin/Users/UserList";
import PermissionManager from "./Admin/Permissions/PermissionManager";
import ConfigurationManager from "./Admin/ConfigurationManager/ConfigurationManager";
import ObservationDashboard from "./Observations/ObservationDashboard";
import PrepareObservation from "./Observations/PrepareObservation";
import PerformObservation from "./Observations/PerformObservation";
import ObservationTemplateManager from "./Observations/ObservationTemplateManager";
import ObservationTemplateEditor from "./Observations/ObservationTemplateEditor";
import ViewObservation from "./Observations/ViewObservation";
import ScrollToTop from "./Shared/ScrollToTop";
import ObservationExplorer from "./Observations/ObservationExplorer";
import CoachingDashboard from "./Coaching/CoachingDashboard";
import {SkillCoachingForm} from "./Coaching/SkillForm/SkillCoachingForm";

type PropsType = any & {
    fnSignOut: Function,
}


function Layout(props: PropsType) {

    return (
        <div>
            <userContext.Consumer>
                {currentUser => (
                    <div>
                        <Navbar fnSignOut={props.fnSignOut} user={currentUser}/>
                        <ScrollToTop />
                        <Routes>
                            <Route path="*" element={<Dashboard />}/>
                            <Route path="business-plan"
                                   element={<BusinessPlanDashboard user={currentUser}/>}/>
                            <Route path="/business-plan/current/*"
                                   element={<BusinessPlan/>}>
                            </Route>
                            <Route path="/business-plan/manage"
                                   element={<PlanStructureManager/>}/>
                            <Route path="/business-plan/manage/reference"
                                   element={<ReferenceBusinessPlan />}/>
                            <Route path="permissions"
                                   element={<PermissionManager />}/>
                            <Route path="/observation"
                                   element={<ObservationDashboard user={currentUser} />}/>
                            <Route path="/observation/prepare"
                                   element={<PrepareObservation user={currentUser} />}/>
                            <Route path="/observation/perform"
                                   element={<PerformObservation user={currentUser} />}/>
                            <Route path="/observation/view"
                                   element= {<ViewObservation user={currentUser} />}/>
                            <Route path="/observation/templates"
                                   element={<ObservationTemplateManager />}/>
                            <Route path="/observation/explorer"
                                   element={<ObservationExplorer />}/>
                            <Route path="/observation/templates/edit"
                                   element={<ObservationTemplateEditor user={currentUser} />}/>
                            <Route path="users"
                                   element={<UserList />}/>
                            <Route path="/admin/configuration/"
                                   element={<ConfigurationManager user={currentUser} />}/>
                            <Route path="/coaching"
                                   element={<CoachingDashboard user={currentUser} />}/>
                            <Route path="/coaching/new"
                                   element={<SkillCoachingForm user={currentUser} />}/>
                        </Routes>
                    </div>
                )}
            </userContext.Consumer>

        </div>
    )
}

export default Layout;