import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    BusinessPlanBusinessDriver,
    BusinessPlanItemStatusType,
    BusinessPlanTopic,
    BusinessUnitPlan
} from "./BusinessPlanModel";
import {PlanContentHeader} from "./PlanContentHeader";
import {Toast} from "primereact/toast";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FilterMatchMode} from "primereact/api";
import {MultiSelect} from "primereact/multiselect";
import {format} from "date-fns";
import DirectionalStatements, {StatementToUserMap} from "./DirectionalStatements";
import BusinessPlanHelper from "./BusinessPlanHelper";
import {Accordion, AccordionTab} from "primereact/accordion";
import {Badge} from "primereact/badge";
import {TabPanel, TabView} from "primereact/tabview";
import {userContext} from "../App";
import {BusinessPlanService} from "./BusinessPlanService";
import BusinessPlanItemStatusIndicator from "./BusinessPlanItemStatusIndicator";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {OverlayPanel} from "primereact/overlaypanel";
import PendingItemManagerButtons from "./PendingItemManagerButtons";
import UserHelper from "../Shared/UserHelper";
import {Permission} from "../Admin/Permissions/PermissionModel";
import {useReactToPrint} from "react-to-print";
import {PrintableSummary} from "./PrintableSummary";
import {User} from "../Shared/UserModel";

interface BusinessPlanSummaryProps {
    planDetail: BusinessUnitPlan;
    structure: BusinessPlanBusinessDriver[];
    updatePlanDetailFn: any;
    refreshPlanFn: any;
    isReference: boolean;
    user: User;
    toast: any;
}

export interface ItemSummary {
    driver: BusinessPlanBusinessDriver;
    topic: BusinessPlanTopic;
    item: any;
}

function BusinessPlanSummary(props: BusinessPlanSummaryProps) {
    const op = useRef<OverlayPanel>(null);
    const ACTION_ARRAY_TARGET = 'actions';
    const INSIGHT_ARRAY_TARGET = 'insights';
    const STATEMENTS_ARRAY_TARGET = 'directionalStatements';
    const [showDeclineReasonDialog, setShowDeclineReasonDialog] = useState<boolean>(false);
    const [mostRecentEdit, setMostRecentEdit] = useState<any>();
    const [declineReason, setDeclineReason] = useState<string>('');
    const [arrayTarget, setArrayTarget] = useState<string>();
    const [itemToDecline, setItemToDecline] = useState<ItemSummary>();
    const [selectedInsightRows, setSelectedInsightRows] = useState<ItemSummary[]>([]);
    const [selectedActionRows, setSelectedActionRows] = useState<ItemSummary[]>([]);
    const [approvedInsights, setApprovedInsights] = useState<ItemSummary[]>([]);
    const [approvedActions, setApprovedActions] = useState<ItemSummary[]>([]);
    const [pendingInsights, setPendingInsights] = useState<ItemSummary[]>([]);
    const [pendingActions, setPendingActions] = useState<ItemSummary[]>([]);
    const [pendingStatements, setPendingStatements] = useState<ItemSummary[]>([]);
    const [totalPendingChanges, setTotalPendingChanges] = useState<number>(0);
    const [tabActiveIndex, setTabActiveIndex] = useState<number>();
    const [canManageItems, setCanManageItems] = useState<boolean>();
    const [insightFilters] = useState({
        'driver.name': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'topic.name': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'item.insightText': {value: null, matchMode: FilterMatchMode.CONTAINS},
    });
    const [actionFilters] = useState({
        'driver.name': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'topic.name': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'item.action': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'item.outcome': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'item.dueDate': {value: null, matchMode: FilterMatchMode.DATE_IS},
        'item.status': {value: null, matchMode: FilterMatchMode.IN},
    });
    const statusOptions = ["OPEN", "COMPLETED"];
    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `${props.planDetail.businessUnit.businessUnitName} Business Plan (FY${props.planDetail.currentPlan!.fiscalYear} Q${props.planDetail.currentPlan!.fiscalQuarter}`
    });
    const statusRowFilterTemplate = (options: any) => {
        return <MultiSelect value={options.value} options={statusOptions}
                            onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Status"
                            className="p-column-filter" maxSelectedLabels={1}/>;
    }

    const dueDateTemplate = (options: ItemSummary) => {
        return format(new Date(options.item.dueDate), 'dd/MM/yyyy');
    }

    const setPendingTabIndex = (pendingInsights: any, pendingActions: any) => {
        const insightTabIndex = 0;
        const actionTabIndex = 1;
        const directionalStatementTabIndex = 2;

        if (pendingInsights.length > 0) {
            setTabActiveIndex(insightTabIndex);
            return;
        }

        if (pendingInsights.length === 0 && pendingActions.length > 0) {
            setTabActiveIndex(actionTabIndex);
            return;
        }

        setTabActiveIndex(directionalStatementTabIndex);
    }

    useEffect(() => {
        const generateSummaryObject = (items: any[]): ItemSummary[] => {
            return items
                .filter(item => !item.deleted)
                .map(item => {
                    let driver = props.structure?.find(driver => driver.topics.filter(topic => topic.id === item.topicId).length > 0);
                    let topic = driver?.topics.find((topic) => topic.id === item.topicId);
                    return {driver: driver!, topic: topic!, item: item}
                });
        }

        const [pendingActionsObj, approvedActionsObj] = BusinessPlanHelper.partitionByStatusHistory(props.planDetail.currentPlan!.actions, BusinessPlanHelper.isItemPending);
        setApprovedActions(generateSummaryObject(approvedActionsObj));
        setPendingActions(generateSummaryObject(pendingActionsObj));

        const [pendingInsightObj, approvedInsightsObj] = BusinessPlanHelper.partitionByStatusHistory(props.planDetail.currentPlan!.insights, BusinessPlanHelper.isItemPending);
        setApprovedInsights(generateSummaryObject(approvedInsightsObj));
        setPendingInsights(generateSummaryObject(pendingInsightObj));

        const [pendingStatementsObj,] = BusinessPlanHelper.partitionByStatusHistory(props.planDetail.currentPlan!.directionalStatements, BusinessPlanHelper.isItemPending);
        setPendingStatements(generateSummaryObject(pendingStatementsObj));

        setTotalPendingChanges(pendingActionsObj.length + pendingInsightObj.length + pendingStatementsObj.length);
        setPendingTabIndex(pendingInsightObj, pendingActionsObj);
        setCanManageItems(UserHelper.hasPermission(props.user, Permission.BUSINESS_PLAN_APPROVER));

    }, [props.planDetail.currentPlan, props.structure, props.user]);

    const insightTabHeaderTemplate = (options: any) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                {options.titleElement} <Badge className="tab-changes-badge" severity="danger"
                                              value={pendingInsights?.length}/>
            </button>
        );
    };

    const actionTabHeaderTemplate = (options: any) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                {options.titleElement} <Badge className="tab-changes-badge" severity="danger"
                                              value={pendingActions?.length}/>
            </button>
        );
    };

    const statementsTabHeaderTemplate = (options: any) => {
        return (
            <button type="button" onClick={options.onClick} className={options.className}>
                {options.titleElement} <Badge className="tab-changes-badge" severity="danger"
                                              value={pendingStatements?.length}/>
            </button>
        );
    };

    function getItemsToUpdate(itemSummaries: ItemSummary[], status: BusinessPlanItemStatusType) {
        const itemsToUpdate: any[] = [];

        itemSummaries.forEach(selectedItem => {
            const isCancelled = BusinessPlanHelper.isItemStatusCancelled(selectedItem.item.itemStatusHistory);

            if (isCancelled && (status === BusinessPlanItemStatusType.APPROVED)) {
                selectedItem.item.deleted = true;
            } else {
                selectedItem.item.itemStatusHistory.push(
                    BusinessPlanHelper.generateStatusHistoryObject(props.user.precedaNumber, status, declineReason)
                );
            }

            itemsToUpdate.push(selectedItem.item);
        });
        return itemsToUpdate;
    }

    function updateBusinessPlan(itemsToUpdate: any[]) {
        BusinessPlanService.getInstance().upsertBusinessPlan(props.planDetail.currentPlan!).then(response => {
            props.planDetail.currentPlan = response.data;
            props.refreshPlanFn(response.data);

            // @ts-ignore
            props.toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: `${itemsToUpdate.length} ${itemsToUpdate.length > 1 ? 'items' : 'item'} updated successfully.`,
                life: 3000
            });
        })
    }

    const dateFormatTemplate = (row: ItemSummary) => {
        return format(new Date(row.item.dueDate), "dd/MM/yyyy");
    }

    const manageItems = (itemSummaries: ItemSummary[], status: BusinessPlanItemStatusType, arrayTarget: string) => {
        const itemsToUpdate = getItemsToUpdate(itemSummaries, status);
        // @ts-ignore
        const filteredItems = props.planDetail.currentPlan[arrayTarget].filter(currentItem => {
            return itemsToUpdate.filter(updatedItem => updatedItem.id === currentItem.id).length === 0;
        });

        // @ts-ignore
        props.planDetail.currentPlan[arrayTarget] = [...filteredItems, ...itemsToUpdate];
        updateBusinessPlan(itemsToUpdate);
    }

    const approveSelectedInsights = () => {
        if (selectedInsightRows.length === 0) {
            // @ts-ignore
            props.toast.current.show({
                severity: 'warn',
                summary: 'Whoops!',
                detail: `Please select the insights that you wish to approve.`,
                life: 3000
            });

            return;
        }

        manageItems(selectedInsightRows, BusinessPlanItemStatusType.APPROVED, INSIGHT_ARRAY_TARGET);
    }

    const approveSelectedActions = () => {
        if (selectedActionRows.length === 0) {
            // @ts-ignore
            props.toast.current.show({
                severity: 'warn',
                summary: 'Whoops!',
                detail: `Please select the actions that you wish to approve.`,
                life: 3000
            });

            return;
        }

        manageItems(selectedActionRows, BusinessPlanItemStatusType.APPROVED, ACTION_ARRAY_TARGET);
    }

    const showCurrentStatus = (row: ItemSummary) => {
        return <BusinessPlanItemStatusIndicator statusHistory={row.item.itemStatusHistory}/>
    }

    const showDeclineReason = (row: ItemSummary, arrayTarget: string) => {
        setItemToDecline(row);
        setArrayTarget(arrayTarget);
        setShowDeclineReasonDialog(true);
    }

    function declineItem() {
        if (declineReason === '') {
            // @ts-ignore
            props.toast.current.show({
                severity: 'warn',
                summary: 'Invalid',
                detail: `Please enter a valid decline reason.`,
                life: 3000
            });

            return;
        }

        if (declineReason.length > 500) {
            // @ts-ignore
            props.toast.current.show({
                severity: 'warn',
                summary: 'Invalid',
                detail: `Please enter a valid decline reason. Too many characters`,
                life: 3000
            });

            return;
        }

        const arr = new Array(itemToDecline!);
        manageItems(arr, BusinessPlanItemStatusType.DECLINED, arrayTarget!);
        setShowDeclineReasonDialog(false);
        setDeclineReason('');
    }

    const showPreviousState = (e: any, row: ItemSummary) => {
        const itemHistory = row.item.itemHistory;
        setMostRecentEdit(BusinessPlanHelper.getMostRecentEdit(itemHistory) as any);
        op.current?.show(e, e.target);
    }

    const insightItemManagerTemplate = (row: ItemSummary) => {
        return (
            <>
                {canManageItems &&
                    <>
                        <PendingItemManagerButtons approveFn={approveInsight} declineFn={showDeclineReason}
                                                   showPreviousFn={showPreviousState} itemSummary={row}
                                                   arrayTarget={INSIGHT_ARRAY_TARGET}/>

                        <OverlayPanel ref={op} breakpoints={{'960px': '75vw', '640px': '100vw'}}
                                      style={{width: '500px'}}>
                            <div className="previous-item">
                                <h2>Previous State</h2>
                                <h3>Insight</h3>
                                <p className="pre-line-format">{mostRecentEdit?.stateSnapshot.insightText}</p>
                                <div className="previous-item-metadata">
                                    <div className="previous-item-date-added">
                                        <div>
                                            Added on {format(new Date(row.item.dateAdded), 'EEEE do MMMM yyyy')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </OverlayPanel>
                    </>
                }

            </>
        )
    }

    const actionItemManagerTemplate = (row: ItemSummary) => {
        return (
            <>
                {canManageItems &&
                    <>
                        <PendingItemManagerButtons approveFn={approveAction} declineFn={showDeclineReason}
                                                   showPreviousFn={showPreviousState} itemSummary={row}
                                                   arrayTarget={ACTION_ARRAY_TARGET}/>
                        <OverlayPanel ref={op} breakpoints={{'960px': '75vw', '640px': '100vw'}}
                                      style={{width: '500px'}}>
                            <div className="previous-item">
                                <h2>Previous State</h2>

                                <div className="field">
                                    <h3>Action</h3>
                                    <p className="pre-line-format">{mostRecentEdit?.stateSnapshot.action}</p>
                                </div>

                                <div className="field">
                                    <h3>Outcome</h3>
                                    <p className="pre-line-format">{mostRecentEdit?.stateSnapshot.outcome}</p>
                                </div>

                                <div className="grid">
                                    {mostRecentEdit?.stateSnapshot.dueDate &&
                                        <div className="col">
                                            <h3>Target Date</h3>
                                            <p>{format(new Date(mostRecentEdit?.stateSnapshot?.dueDate).setHours(0, 0, 0, 0), 'dd/MM/yyyy')}</p>
                                        </div>
                                    }
                                    <div className="col">
                                        <h3>Status</h3>
                                        <p>{mostRecentEdit?.stateSnapshot.status}</p>
                                    </div>
                                </div>

                                <div className="previous-item-metadata">
                                    <div className="previous-item-date-added">
                                        <div>
                                            Added on {format(new Date(row.item.dateAdded), 'EEEE do MMMM yyyy')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </OverlayPanel>
                    </>

                }


            </>
        )
    }

    const statementItemManagerTemplate = (row: StatementToUserMap) => {
        if (row.statement) {
            const pendingStatementItem: ItemSummary = pendingStatements.find(pendingStatement => pendingStatement.item.id === row.statement?.id)!;

            return (
                <>
                    {canManageItems &&
                        <>
                            <PendingItemManagerButtons approveFn={approveStatement} declineFn={showDeclineReason}
                                                       showPreviousFn={showPreviousState}
                                                       itemSummary={pendingStatementItem}
                                                       arrayTarget={STATEMENTS_ARRAY_TARGET}/>

                            <OverlayPanel ref={op} breakpoints={{'960px': '75vw', '640px': '100vw'}}
                                          style={{width: '500px'}}>
                                <div className="previous-item">
                                    <h2>Previous State</h2>
                                    <h3>Directional Statement</h3>
                                    <p>{mostRecentEdit?.stateSnapshot.statement}</p>
                                    <div className="previous-item-metadata">
                                        <div className="previous-item-date-added">
                                            <div>
                                                Added
                                                on {format(new Date(row.statement.dateAdded), 'EEEE do MMMM yyyy')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </OverlayPanel>
                        </>
                    }

                </>
            )
        }


    }

    const approveInsight = (row: ItemSummary) => {
        const arr = new Array(row);
        manageItems(arr, BusinessPlanItemStatusType.APPROVED, INSIGHT_ARRAY_TARGET);
    }

    const approveAction = (row: ItemSummary) => {
        const arr = new Array(row);
        manageItems(arr, BusinessPlanItemStatusType.APPROVED, ACTION_ARRAY_TARGET);
    }

    const approveStatement = (row: ItemSummary) => {
        const arr = new Array(row);
        manageItems(arr, BusinessPlanItemStatusType.APPROVED, STATEMENTS_ARRAY_TARGET);
    }

    const insightTextTemplate = (row: ItemSummary) => {
        return <div dangerouslySetInnerHTML={{__html: row.item.insightText}}/>;
    }

    const actionTextTemplate = (row: ItemSummary) => {
        return <div dangerouslySetInnerHTML={{__html: row.item.action}}/>;
    }

    const outcomeTextTemplate = (row: ItemSummary) => {
        return <div dangerouslySetInnerHTML={{__html: row.item.outcome}}/>;
    }

    return (
        <>
            <PlanContentHeader title='Summary' subtitle='Overall'/>
            <div style={{display: "none"}}>
                <PrintableSummary actions={[...pendingActions, ...approvedActions]}
                                  structure={props.structure}
                                  insights={[...pendingInsights, ...approvedInsights]} planDetail={props.planDetail}
                                  ref={componentRef}/>
            </div>
            <div className="plan-metric-content">
                <div className="print-icon-container">
                    <i className="print-icon pi pi-print" onClick={handlePrint} />
                    <span>Print</span>
                </div>
                <div className="clearfix" />

                {totalPendingChanges > 0 &&
                    <>
                        <Accordion activeIndex={0}>
                            <AccordionTab header={<React.Fragment>Pending Changes <Badge value={totalPendingChanges}
                                                                                         severity="danger"/></React.Fragment>}>
                                <TabView activeIndex={tabActiveIndex} onTabChange={(e) => setTabActiveIndex(e.index)}>
                                    {pendingInsights.length > 0 &&
                                        <TabPanel header="Insights" headerTemplate={insightTabHeaderTemplate}>
                                            <DataTable value={pendingInsights} selection={selectedInsightRows}
                                                       onSelectionChange={e => setSelectedInsightRows(e.value)}
                                                       sortMode="multiple" responsiveLayout="scroll" size="small">
                                                <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
                                                <Column headerStyle={{width: '250px'}}
                                                        field="driver.name" header="Business Driver"
                                                        sortable/>
                                                <Column headerStyle={{width: '250px'}}
                                                        field="topic.name" header="Topic" sortable/>
                                                <Column field="item.insightText" header="Insight"
                                                        className="pre-line-format" body={insightTextTemplate}/>
                                                <Column field="item.itemStatusHistory" header="Approval Status"
                                                        body={showCurrentStatus}/>
                                                {canManageItems && <Column field="item" header="Manage Item"
                                                                           body={insightItemManagerTemplate}/>}
                                            </DataTable>

                                            {canManageItems &&
                                                <div className="summary-approval-button">
                                                    <button className="app-button" data-testid="approve-selected-insights"
                                                            onClick={() => approveSelectedInsights()}>Approve
                                                        Selected
                                                    </button>
                                                </div>}
                                        </TabPanel>
                                    }

                                    {pendingActions.length > 0 &&
                                        <TabPanel header="Actions" headerTemplate={actionTabHeaderTemplate}>
                                            <DataTable value={pendingActions} selection={selectedActionRows}
                                                       onSelectionChange={e => setSelectedActionRows(e.value)}
                                                       sortMode="multiple" responsiveLayout="scroll" size="small">
                                                <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
                                                <Column headerStyle={{width: '250px'}}
                                                        field="driver.name" header="Business Driver"
                                                        sortable/>
                                                <Column headerStyle={{width: '250px'}}
                                                        field="topic.name" header="Topic" sortable/>
                                                <Column field="item.action" header="Action"
                                                        className="pre-line-format" body={actionTextTemplate}/>
                                                <Column field="item.outcome" header="Outcome"
                                                        className="pre-line-format" body={outcomeTextTemplate}/>
                                                <Column field="item.dueDate" sortable header="Target Date"
                                                        dataType="date" body={dateFormatTemplate}/>
                                                <Column field="item.status" header="Action Status"/>
                                                <Column field="item.itemStatusHistory" header="Approval Status"
                                                        body={showCurrentStatus}/>
                                                {canManageItems && <Column field="item" header="Manage Item"
                                                                           body={actionItemManagerTemplate}/>}

                                            </DataTable>
                                            {canManageItems &&
                                                <div className="summary-approval-button">
                                                    <button className="app-button"
                                                            onClick={() => approveSelectedActions()}>Approve Selected
                                                    </button>
                                                </div>
                                            }
                                        </TabPanel>
                                    }

                                    {pendingStatements.length > 0 &&
                                        <TabPanel header="Directional Statements"
                                                  headerTemplate={statementsTabHeaderTemplate}>
                                            <DirectionalStatements manageItemsFn={manageItems}
                                                                   isReference={props.isReference}
                                                                   planDetail={props.planDetail}
                                                                   allowManagement={canManageItems}
                                                                   readOnly={true}
                                                                   showPending={true} itemSummary={pendingStatements}
                                                                   itemManagerTemplate={statementItemManagerTemplate}/>
                                        </TabPanel>
                                    }
                                </TabView>
                            </AccordionTab>
                        </Accordion>
                    </>
                }
                <h2>Insights</h2>
                <DataTable value={approvedInsights.filter(
                    action => BusinessPlanHelper.isItemStatusApproved(action.item.itemStatusHistory)
                )} filters={insightFilters} filterDisplay="row" paginator rows={5}
                           sortMode="multiple" responsiveLayout="scroll" size="large">
                    <Column headerStyle={{width: '250px'}} filterField="driver.name"
                            filterPlaceholder="Search by driver" field="driver.name" filter header="Business Driver"
                            sortable/>
                    <Column headerStyle={{width: '250px'}} filterField="topic.name" filterPlaceholder="Search by topic"
                            field="topic.name" filter header="Topic" sortable/>
                    <Column field="item.insightText" body={insightTextTemplate} filterPlaceholder="Search by insight"
                            filter header="Insight"/>
                </DataTable>

                <h2>Actions</h2>
                <DataTable value={approvedActions.filter(
                    action => BusinessPlanHelper.isItemStatusApproved(action.item.itemStatusHistory)
                )} filters={actionFilters} filterDisplay="row" paginator rows={5}
                           sortMode="multiple" responsiveLayout="scroll" size="large">
                    <Column filter filterPlaceholder="Search by driver" filterField="driver.name"
                            headerStyle={{width: '250px'}} field="driver.name" sortable header="Business Driver"/>
                    <Column filter filterPlaceholder="Search by topic" headerStyle={{width: '250px'}} field="topic.name"
                            sortable header="Topic"/>
                    <Column filter filterPlaceholder="Search by action" filterField="item.action" field="item.action"
                            header="Action" body={actionTextTemplate}/>
                    <Column filter filterPlaceholder="Search by outcome" field="item.outcome" body={outcomeTextTemplate}
                            header="Outcome"/>
                    <Column field="item.dueDate" header="Target Date" body={dueDateTemplate} sortable/>
                    <Column filter filterElement={statusRowFilterTemplate} filterField="item.status" field="item.status"
                            header="Status" sortable showFilterMatchModes={false}/>
                </DataTable>

                <DirectionalStatements isReference={props.isReference} approvedOnly={true} planDetail={props.planDetail}
                                       allowManagement={false} readOnly={true}/>

            </div>

            <Dialog header='Decline Reason' visible={showDeclineReasonDialog} style={{width: '50vw'}}
                    onHide={() => setShowDeclineReasonDialog(false)}>

                <div className="field">
                    <InputTextarea id="decline-reason" data-testid="decline-reason-input" name="decline-reason" rows={5} value={declineReason}
                                   onChange={(e) => setDeclineReason(e.target.value)}
                                   autoResize placeholder="Please enter your reason for declining this item..."/>
                </div>
                <button className="app-button pull-right" onClick={() => declineItem()}>Save</button>
                <div className="clearfix"/>
            </Dialog>
        </>
    )
}

export default BusinessPlanSummary;