import * as React from 'react';
import {Steps} from "primereact/steps";
import {useEffect, useState} from "react";
import {Card} from "primereact/card";
import {User} from "../../Shared/UserModel";
import {StrengthsOpportunitiesForm} from "./StrengthsOpportunitiesForm";
import {CoachingTopicForm} from "./CoachingTopicForm";
import {SmartGoalForm} from "./SmartGoalForm";
import * as Yup from 'yup';
import {FieldMetaProps, Form, Formik} from "formik";
import {SkillCoachingFormSummary} from "./SkillCoachingFormSummary";
import {Button} from "primereact/button";
import '../Coaching.css'
import {Message} from "primereact/message";
import {ObservationService} from "../../Observations/ObservationService";
import {ObservationTemplate} from "../../Observations/ObservationsModel";
import {SkillConversation, SmartGoalStatus} from "../CoachingModel";
import {v4} from "uuid";
import {confirmDialog} from "primereact/confirmdialog";
import {useNavigate} from "react-router-dom";
import {CoachingService} from "../CoachingService";
import CoachingHelper from "../CoachingHelper";

type Props = {
    user: User;
};

export function SkillCoachingForm(props: Props) {

    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [coachingTopics, setCoachingTopics] = useState<ObservationTemplate[]>([]);
    const formModel = CoachingHelper.skillFormModel;
    const formSteps = [
        {
            label: 'What happened and why?',
        },
        {
            label: 'Coaching topic',
        },
        {
            label: 'SMART goal',
        },
        {
            label: 'Review Conversation',
        },
    ]

    const validationObject = [
        Yup.object().shape({
            [formModel.formField.strengths.name]: Yup.string().required(`${formModel.formField.strengths.requiredErrorMsg}`),
            [formModel.formField.strengthsExplained.name]: Yup.string().required(`${formModel.formField.strengthsExplained.requiredErrorMsg}`),
            [formModel.formField.opportunities.name]: Yup.string().required(`${formModel.formField.opportunities.requiredErrorMsg}`),
            [formModel.formField.opportunitiesExplained.name]: Yup.string().required(`${formModel.formField.opportunitiesExplained.requiredErrorMsg}`),
        }),
        Yup.object().shape({
            [formModel.formField.coachingTopic.name]: Yup.string().required(`${formModel.formField.coachingTopic.requiredErrorMsg}`),
        }),
        Yup.object().shape({
            [formModel.formField.smartGoal.name]: Yup.string().required(`${formModel.formField.smartGoal.requiredErrorMsg}`),
            [formModel.formField.smartGoalDueDate.name]: Yup.string().required(`${formModel.formField.smartGoalDueDate.requiredErrorMsg}`),
        }),
    ];

    const formInitialValues = {
        [formModel.formField.strengths.name]: '',
        [formModel.formField.strengthsExplained.name]: '',
        [formModel.formField.opportunities.name]: '',
        [formModel.formField.opportunitiesExplained.name]: '',
        [formModel.formField.coachingTopic.name]: '',
        [formModel.formField.coachingTopicSkill.name]: '',
        [formModel.formField.smartGoal.name]: '',
        [formModel.formField.smartGoalDueDate.name]: '',
    };

    const currentValidationSchema = validationObject[activeIndex];
    const isLastStep = activeIndex === formSteps.length - 1;
    const COACHING_PATH = '/coaching';

    useEffect(() => {
        ObservationService.getInstance().getAllTemplates().then(response => {
            setCoachingTopics(response.data.filter(x => !x.deleted && x.active));
        });
        
    }, []);

    function renderStepContent(step: number) {
        switch (step) {
            case 0:
                return <StrengthsOpportunitiesForm renderErrorTextFn={renderErrorText}
                                                   formField={formModel.formField}/>;
            case 1:
                return <CoachingTopicForm formField={formModel.formField} topics={coachingTopics}
                                          renderErrorTextFn={renderErrorText}/>
            case 2:
                return <SmartGoalForm formField={formModel.formField} renderErrorTextFn={renderErrorText}/>;
            case 3:
                return <SkillCoachingFormSummary formField={formModel.formField} user={props.user}/>;
            default:
                return <div>Not Found</div>;
        }
    }

    function renderErrorText(meta: FieldMetaProps<any>) {
        if (meta.touched && meta.error) {
            return <Message severity="warn" className="w-full" text={meta.error}/>
        }
    }

    function _submitForm(values: any, actions: any) {
        const formData: SkillConversation = {
            coachingTopic: values.coachingTopic,
            coachingTopicSkill: values.coachingTopicSkill,
            dateAdded: new Date(),
            employeeId: props.user.precedaNumber,
            id: v4(),
            opportunities: values.opportunities,
            opportunitiesExplained: values.opportunitiesExplained,
            smartGoal: {
                id: v4(),
                smartGoalText: values.smartGoal,
                dueDate: values.smartGoalDueDate,
                dateAdded: new Date(),
                employeeId: props.user.precedaNumber,
                status: SmartGoalStatus[SmartGoalStatus.OPEN]
            },
            strengths: values.strengths,
            strengthsExplained: values.strengthsExplained
        }

        CoachingService.getInstance().upsertConversation(formData).then(_ => {
            actions.setSubmitting(false);
            navigate(COACHING_PATH);
        });
    }

    function _handleSubmit(values: any, actions: any) {
        if (isLastStep) {
            _submitForm(values, actions);
        } else {
            setActiveIndex(activeIndex + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
        }
    }

    function _handleBack() {
        setActiveIndex(activeIndex - 1);
    }

    function confirmCancellation() {
        confirmDialog({
            message: 'Are you sure you want to cancel this conversation?',
            header: 'Cancel Conversation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => cancelConversation(),
        });
    }

    function cancelConversation() {
        navigate(COACHING_PATH);
    }

    return (
        <Card className="skill-form-container">
            <h2 className="text-center mb-5">Coaching Conversation</h2>
            <Steps model={formSteps} activeIndex={activeIndex}
                   readOnly={true}/>
            {activeIndex === formSteps.length ? (
                <SkillCoachingFormSummary formField={formModel} user={props.user}/>
            ) : (
                <Formik
                    initialValues={formInitialValues}
                    validationSchema={currentValidationSchema}
                    onSubmit={_handleSubmit}
                >
                    {({isSubmitting}) => (
                        <Form id={formModel.formId}>
                            {renderStepContent(activeIndex)}

                            <div className={`skill-form-buttons`}>
                                {activeIndex !== 0 && (
                                    <Button onClick={_handleBack} className='app-button form-back-button' type="button">
                                        Back
                                    </Button>
                                )}
                                <Button label="Cancel" className={`app-button ${activeIndex !== 0 ? 'ml-3' : ''}`}
                                        onClick={confirmCancellation}/>
                                <Button
                                    disabled={isSubmitting}
                                    type="submit"
                                    color="primary"
                                    className="app-button skill-form-submit-button"
                                >
                                    {isLastStep ? 'Submit' : 'Next'}
                                </Button>
                            </div>
                        </Form>
                    )
                    }
                </Formik>
            )
            }
        </Card>
    );
}