import React, {ReactNode, useEffect, useState} from "react";
import {User} from "../Shared/UserModel";
import {SkillConversation} from "./CoachingModel";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {format} from "date-fns";
import {ToggleButton} from "primereact/togglebutton";
import SkillConversationViewer from "./SkillForm/SkillConversationViewer";
import {FilterMatchMode} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {Chip} from "primereact/chip";

interface ConversationLogProps {
    user: User;
    conversations: SkillConversation[];
    updateConversationsFn: any;
}

function CoachingConversationLog(props: ConversationLogProps) {
    
    const [expandedRows, setExpandedRows] = useState<any[]>([]);
    const [myConversations, setMyConversations] = useState<SkillConversation[]>([]);
    const [teamConversations, setTeamConversations] = useState<SkillConversation[]>([]);
    const [displayedConversations, setDisplayedConversations] = useState<SkillConversation[]>([]);
    const [showTeamConversations, setShowTeamConversations] = useState<boolean>(false);
    const [filterValue, setFilterValue] = useState<string>('');
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    
    useEffect(() => {
        const [my, team] = partitionByOwnership(props.conversations);
        setMyConversations(my);
        setTeamConversations(team);
        setDisplayedConversations(my);
    }, [props.conversations]);

    function partitionByOwnership(array: any[]) {
        return array
            // @ts-ignore
            .reduce(([pass, fail], elem) => {
                return elem.employeeId === props.user.precedaNumber ? [[...pass, elem], fail] : [pass, [...fail, elem]];
            }, [[], []]);
    }

    function onFilterValueChange(e: any) {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setFilterValue(value);
    }
    
    function toggleDisplayedConversations() {
       if (showTeamConversations) {
           setDisplayedConversations(myConversations);
           setShowTeamConversations(false);
       } else {
           setDisplayedConversations(teamConversations);
           setShowTeamConversations(true); 
       } 
    }
    
    function header() {
            return (
                <>
                    <div className="conversation-log-header-group flex justify-content-between align-items-center">
                        <h2 className="m-0">Conversation Log</h2>
                        <div>
                            {teamConversations.length > 0 &&
                                <ToggleButton onLabel="Show Team Conversations" offLabel="Show Team Conversations"
                                              checked={showTeamConversations}
                                              onChange={() => toggleDisplayedConversations()}/>
                            }
                            <span className="ml-3 p-input-icon-left">
                                <i className="pi pi-search" />
                                <InputText value={filterValue} onChange={onFilterValueChange} placeholder="Keyword Search" />
                            </span> 
                        </div>
                    </div>
                </>
            ) 
    }
    
    function rowExpansionTemplate(conversation: SkillConversation): ReactNode {
        return <SkillConversationViewer conversation={conversation} updateConversationsFn={props.updateConversationsFn} user={props.user}/>
    }
    
    function nameTemplate(conversation: SkillConversation) {
        return `${conversation.employeeDetails?.preferredName} ${conversation.employeeDetails?.surname}`
    }
    
    return (
        <div className="conversation-log-container">
            <DataTable value={displayedConversations} expandedRows={expandedRows} 
                       onRowToggle={(e) => setExpandedRows(e.data)}
                       responsiveLayout="scroll"
                       filters={filters}
                       globalFilterFields={['strengths', 'strengthsExplained', 'opportunities', 
                           'opportunitiesExplained', 'smartGoal.smartGoalText', 'coachingTopic',
                           'coachingTopicSkill', 'employeeDetails.preferredName', 'employeeDetails.surname', 'employeeDetails.fullTitle']}
                       rowExpansionTemplate={rowExpansionTemplate} dataKey="id" header={header}>
                <Column expander style={{ width: '3em' }} />
                <Column field="dateAdded" body={(conversation) => format(new Date(conversation.dateAdded), 'dd/MM/yyyy')} header="Conversation Date" sortable />
                <Column field="conversation" header="Team Member" body={nameTemplate} sortable hidden={!showTeamConversations}/>
                <Column field="employeeDetails.fullTitle" header="Position" sortable hidden={!showTeamConversations}/> 
                <Column field="coachingTopic" header="Topic" sortable />
                <Column field="coachingTopicSkill" header="Skill" sortable />
                <Column field="smartGoal.dueDate" header="SMART Goal Due Date" body={(conversation) => format(new Date(conversation.smartGoal.dueDate), 'EEEE do MMMM yyyy')} sortable />
                <Column field="smartGoal.status" header="SMART Goal Status" body={(x) => <Chip label={x.smartGoal.status} className={`status-chip ${x.smartGoal.status}`} />} sortable />
            </DataTable>
        </div>

    )
}

export default CoachingConversationLog;