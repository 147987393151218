import React, {useEffect, useState} from 'react';
import './Observations.css';
import {Employee, User} from "../Shared/UserModel";
import HierarchyService from "../Shared/HierarchyService";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {ObservationService} from "./ObservationService";
import UserHelper from "../Shared/UserHelper";
import {Permission} from "../Admin/Permissions/PermissionModel";
import {ObservationResult, ObservationTemplate} from "./ObservationsModel";
import {Skeleton} from "primereact/skeleton";
import {Card} from "primereact/card";
import ObservationResultSummary from "./ObservationResultSummary";
import EmptyMessage from "../Shared/EmptyMessage/EmptyMessage";
import ObservationDatatable from "./ObservationDatatable";

interface ObservationDashboardProps {
    user: User;
}

function ObservationDashboard(props: ObservationDashboardProps) {

    const [reportingUsers, setReportingUsers] = useState<Employee[]>([]);
    const [templates, setTemplates] = useState<ObservationTemplate[]>([]);
    const [employeesLoading, setEmployeesLoading] = useState<boolean>(true);
    const [templatesLoading, setTemplatesLoading] = useState<boolean>();
    const [performedObservationsLoading, setPerformedObservationsLoading] = useState<boolean>();
    const [myObservationsLoading, setMyObservationsLoading] = useState<boolean>();
    const [myObservationsLoadingLazy, setMyObservationsLoadingLazy] = useState<boolean>();
    const [observationsPerformed, setObservationsPerformed] = useState<ObservationResult[]>([]);
    const [myObservationResults, setMyObservationResults] = useState<ObservationResult[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        let isMounted = true;

        setEmployeesLoading(true);
        setTemplatesLoading(true);
        setMyObservationsLoading(true);
        setMyObservationsLoadingLazy(true);

        HierarchyService.getInstance().getAllClinicEmployees().then(response => {
            if (isMounted) {
                setEmployeesLoading(false);
                setReportingUsers(response.data); 
            }
        })

        ObservationService.getInstance().getAllTemplates().then(response => {
            if (isMounted) {
                setTemplatesLoading(false);
                setTemplates(response.data); 
            }
        });

        if (canPerformObservations()) {
            setPerformedObservationsLoading(true);
            ObservationService.getInstance().getObservationsPerformed(10, 0).then(response => {
                if (isMounted) {
                    setObservationsPerformed(response.data);
                    setPerformedObservationsLoading(false); 
                }
            });
        }

        ObservationService.getInstance().getObservationsDoneOnMe(10, 0).then(response => {
            if (isMounted) {
                setMyObservationsLoading(false);
                setMyObservationsLoadingLazy(false);
                setMyObservationResults(response.data); 
            }
        });

        return () => {
            isMounted = false;
        };

    }, [props.user]);

    function navigateToNewObservation() {
        navigate('/observation/prepare', {state: {candidates: reportingUsers, templates: templates}})
    }

    function canPerformObservations() {
        return UserHelper.hasPermission(props.user, Permission.CONSULTING_OBSERVATION_PERFORM);
    }

    function loadMoreObservationsPerformed() {
        setPerformedObservationsLoading(true);
        ObservationService.getInstance().getObservationsPerformed(10, observationsPerformed.length).then(response => {
            setObservationsPerformed(prevState => [...prevState, ...response.data]);
            setPerformedObservationsLoading(false);
        });
    }

    function loadMoreMyObservations() {
        setMyObservationsLoadingLazy(true);
        ObservationService.getInstance().getObservationsDoneOnMe(10, myObservationResults.length).then(response => {
            setMyObservationResults(prevState => [...prevState, ...response.data]);
            setMyObservationsLoadingLazy(false);
        });
    }

    function viewObservationWithResult(result: ObservationResult) {
        navigate('/observation/view', {state: {result: result, returnPath: '/observation' }});
    }
    

    return (
        <div className="consulting-dashboard-container">
            <div className="header-button-group">
                <h2>Observations</h2>
                <div className="header-buttons flex">
                    {/* JA-159 - This code was hidden on purpose */}
                    {/*{canPerformObservations() && <UnpublishedObservations overdueThresholdDays={7} />}*/}
                    {canPerformObservations() && <Button loading={employeesLoading || templatesLoading} className="app-button ml-3"
                                                         onClick={() => navigateToNewObservation()} label="Start an Observation"/>} 
                </div>
            </div>

            {myObservationsLoading &&
                <Card title="My Most Recent Observation">
                    <div className="grid result-summary-container">
                        <div className="col-8">
                            <div className="details-container">
                                <h2 className="result-header"><Skeleton width="80%" height={`30px`}
                                                                        className={`m-auto mb-2`}/></h2>
                                <div className="candidate-details">
                                    <Skeleton width="78%" className={`m-auto`} height={`10px`}/>
                                </div>
                                <div className="observation-details">
                                    <Skeleton width="100%" className={`m-auto`}/>
                                </div>
                                <div className="published-status">
                                    <div className="m-auto" style={{width: '175px'}}>
                                        <div className="flex align-items-end">
                                            <Skeleton shape="circle" size="1rem" className="mr-2"/>
                                            <Skeleton width={`150px`}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="score-container">
                                <Skeleton shape="circle" size="200px" className="mb-4 m-auto"/>
                                <div className="score-details-container">
                                    <Skeleton width={`150px`} className="m-auto"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            }

            {(myObservationResults.length > 0 && !myObservationsLoading) &&
                <Card title={`Most Recent Observation`} className="mb-5">
                    <div onClick={() => viewObservationWithResult(myObservationResults[0])}>
                        <ObservationResultSummary observationResults={myObservationResults[0]}/>
                    </div>
                </Card>
            }

            {(myObservationResults.length == 0 && !myObservationsLoading) &&
                <Card title={`Most Recent Observation`}>
                    <EmptyMessage message={`No observations have been performed on you as yet`}/>
                </Card>
            }
                        <div className="field">
                            <ObservationDatatable 
                                observations={myObservationResults}
                                returnPath='/observation'
                                highlightOverdue={false}
                                loading={myObservationsLoadingLazy!}
                                columns={['candidateDetails', 'score', 'templateName', 'candidateDetails.fullTitle', 'dateAdded']} 
                                title='My Observations' />
                            <Button className={`load-more-observations-button app-button`} loading={myObservationsLoadingLazy}
                                    label={`Load More`}
                                    onClick={() => loadMoreMyObservations()}/>
                            <div className="clearfix" />
                        </div>


                {canPerformObservations() &&
                        <div className="field">
                            <ObservationDatatable
                                returnPath='/observation'    
                                observations={observationsPerformed}
                                highlightOverdue={false}
                                loading={performedObservationsLoading!} 
                                columns={['candidateDetails', 'score', 'templateName', 'candidateDetails.fullTitle', 'dateAdded']} 
                                title='Observations Performed By Me' />
                            <Button className={`load-more-observations-button app-button`} loading={performedObservationsLoading}
                                    label={`Load More`}
                                    onClick={() => loadMoreObservationsPerformed()}/> 
                            <div className="clearfix" />
                        </div>
                }
        </div>
    )
}

export default ObservationDashboard;