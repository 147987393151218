const CoachingHelper = {

    skillFormModel: {
        formId: 'skillForm',
        formField: {
            strengths: {
                name: 'strengths',
                label: 'What is going well?',
                requiredErrorMsg: 'This field is required'
            },
            strengthsExplained: {
                name: 'strengthsExplained',
                label: 'Why / how is it going well?',
                requiredErrorMsg: 'This field is required'
            },
            opportunities: {
                name: 'opportunities',
                label: 'What are some opportunities for improvement?',
                requiredErrorMsg: 'This field is required'
            },
            opportunitiesExplained: {
                name: 'opportunitiesExplained',
                label: 'Why / how are they opportunities for improvement?',
                requiredErrorMsg: 'This field is required'
            },
            coachingTopic: {
                name: 'coachingTopic',
                label: 'What topic were you coached on today?',
                requiredErrorMsg: 'Coaching topic required'
            },
            coachingTopicSkill: {
                name: 'coachingTopicSkill',
                label: 'What specific skill are you focused on?',
            },
            smartGoal: {
                name: 'smartGoal',
                label: 'What are you going to start / stop / continue doing?',
                requiredErrorMsg: 'This field is required',
            },
            smartGoalDueDate: {
                name: 'smartGoalDueDate',
                label: 'Timeframe / Due Date',
                requiredErrorMsg: 'Due date field is required'
            }
        }
    }
    
}

export default CoachingHelper;