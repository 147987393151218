import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './theme.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {EventType, InteractionRequiredAuthError} from "@azure/msal-browser";
import {MsalProvider} from "@azure/msal-react";
import {msalApiScopes} from "./Shared/AuthConfig";
import LoadingIndicator from "./Shared/LoadingIndicator";
import {httpClient, msalInstance} from "./AuthClient";


// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
    // @ts-ignore
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        console.log(event);
        // @ts-ignore
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

httpClient.interceptors.request.use(async config => {
    const account = msalInstance.getActiveAccount();
    const request = {
        scopes: msalApiScopes,
        account: account!,
    }
    const response = await msalInstance.acquireTokenSilent(request).catch(async (error) => {
        console.log(error);
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return await msalInstance.acquireTokenPopup(request).catch(error => {
                console.log(error);
            });
        }
    });
    
    config.headers = {
        'Authorization': `Bearer ${response?.accessToken}`,
        'Accept': 'application/json',
    }
    return config;
});

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <App/>
            <LoadingIndicator/>
        </MsalProvider>,
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
