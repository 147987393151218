import React from "react";
import {Link} from "react-router-dom";

interface ConfigTileProps {
    path: string;
    icon: string;
    name: string;
}

function ConfigTile(props: ConfigTileProps) {

    return (
        <>
            <Link to={props.path}>
                <div className="config-tile">
                    <div className="config-tile-content">
                        <i className={`tile-icon pi ${props.icon}`} />
                        <div className="tile-name">{props.name}</div> 
                    </div>
                </div>
            </Link>
        </>
    )
}

export default ConfigTile;