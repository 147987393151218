export const msalConfig = {
    auth: {
        clientId: "6211d3bd-574e-4424-9911-ea1555bf23fa",
        authority: 'https://login.microsoftonline.com/18adc265-ec41-40a3-a5e2-5b1dc2246624/',
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "sessionStorage"
    }
};

export const msalApiScopes = [
    "api://6211d3bd-574e-4424-9911-ea1555bf23fa/Canvas",
    "User.Read.All",
    "Group.Read.All"
];