import React, {useContext, useState} from 'react';
import {BusinessPlanInsight, BusinessPlanItemStatusType, BusinessUnitPlan} from "./BusinessPlanModel";
import {format} from 'date-fns';
import DotMenu from "../Shared/DotMenu/DotMenu";
import {BusinessPlanService} from "./BusinessPlanService";
import {confirmDialog} from "primereact/confirmdialog";
import {Inplace, InplaceContent, InplaceDisplay} from "primereact/inplace";
import {userContext} from "../App";
import {v4} from "uuid";
import BusinessPlanItemStatusIndicator from "./BusinessPlanItemStatusIndicator";
import BusinessPlanHelper from "./BusinessPlanHelper";
import {insightValidation} from "./BusinessPlanInsightsForm";
import ReactQuill from "react-quill";

interface BusinessPlanInsightItemProps {
    insight: BusinessPlanInsight;
    updateInsightsFn: any;
    planDetail: BusinessUnitPlan;
    toast: any;
    readOnly: boolean;
    refreshPlanFn: any;
}

function BusinessPlanInsightItem(props: BusinessPlanInsightItemProps) {
    
    const [editActive, setEditActive] = useState<boolean>(false);
    const [editText, setEditText] = useState<string>(props.insight.insightText);
    const user = useContext(userContext);

    const editInsight = () => {
        const currentState: BusinessPlanInsight = {...props.insight};
        currentState.itemHistory = [];
        
        props.insight.insightText = editText;
        props.insight.dateModified = new Date();
        props.insight.modifiedBy = user.precedaNumber;
        
        insightValidation.validate(props.insight).then(_ => {
            if (props.insight.itemStatusHistory === null) props.insight.itemStatusHistory = [];
            if (props.insight.itemHistory === null) props.insight.itemHistory = [];

            if (BusinessPlanHelper.shouldPersistItemChange(props.insight.itemStatusHistory)) {
                props.insight.itemStatusHistory.push(                    {
                    id: v4(), status: BusinessPlanItemStatusType[BusinessPlanItemStatusType.CHANGED], addedBy: user.precedaNumber, dateAdded: new Date()}
                )
                props.insight.itemHistory.push(
                    {id: v4(), addedBy: user.precedaNumber, dateAdded: new Date(), stateSnapshot: currentState}
                )
            }
            
            const amendedInsights = props.planDetail.currentPlan!.insights.filter(item => item.id !== props.insight.id);
            amendedInsights.push(props.insight);

            props.planDetail.currentPlan!.insights = amendedInsights;

            BusinessPlanService.getInstance().upsertBusinessPlan(props.planDetail.currentPlan!).then(response => {
                props.updateInsightsFn(response.data);
                setEditActive(false);
                props.toast.current.show({
                    severity: 'success',
                    summary: 'Success!',
                    detail: `Your insight was edited successfully`,
                    life: 3000
                });
                props.refreshPlanFn(response.data);
            })
        }).catch(x => {
            props.toast.current.show({
                severity: 'warn',
                summary: 'Invalid!',
                detail: x.message,
                life: 3000
            });
        })


    }
    
    const showEditField = () => {
        setEditActive(true);
    }
    
    const cancelInsight = () => {

        if (BusinessPlanHelper.canCancelItem(props.insight.itemStatusHistory)) {
            props.insight.itemStatusHistory.push(                    {
                id: v4(), status: BusinessPlanItemStatusType[BusinessPlanItemStatusType.CANCELLED], addedBy: user.precedaNumber, dateAdded: new Date()}
            )

            const amendedInsights = props.planDetail.currentPlan!.insights.filter(item => item.id !== props.insight.id);
            amendedInsights.push(props.insight);
            props.planDetail.currentPlan!.insights = amendedInsights;
            
        } else {
            props.planDetail.currentPlan!.insights = props.planDetail.currentPlan!.insights.filter(item => item.id !== props.insight.id);
        }
        
        BusinessPlanService.getInstance().upsertBusinessPlan(props.planDetail.currentPlan!).then(response => {
            props.updateInsightsFn(response.data);
            props.toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: `Your insight was successfully marked for deletion`,
                life: 3000
            });
        });
    }

    const confirmCancel = () => {
        const message = BusinessPlanHelper.canCancelItem(props.insight.itemStatusHistory) 
            ? 'Are you sure you want to mark this insight as cancelled?'
            : 'Are you sure you want to delete this insight?'
        confirmDialog({
            message: message,
            icon: 'pi pi-exclamation-triangle',
            accept: () => cancelInsight(),
        });
    }
    
    const contextMenu = [
        {
            label: 'Edit',
            command: showEditField
        },
        {
            label: BusinessPlanHelper.canCancelItem(props.insight.itemStatusHistory) ? 'Cancel' : 'Delete',
            command: confirmCancel
        }
    ]

    return (
        <>
            <div className="insight-item">
                {!props.readOnly && <DotMenu items={contextMenu} />}
                <div className="insight-text">
                    <Inplace closable active={editActive} onToggle={() => setEditActive(false)}>
                        <InplaceDisplay>
                            <div className="pre-line-format" dangerouslySetInnerHTML={{__html: props.insight.insightText}} />
                        </InplaceDisplay>
                        <InplaceContent>
                            <ReactQuill className="bp-editor"
                                        modules={BusinessPlanHelper.EDITOR_MODULES}
                                        theme="snow"
                                        value={editText}
                                        onChange={setEditText}/>
                            
                            <button className="p-button p-button-icon-only edit-icon-button" onClick={() => editInsight()}>
                                <span className="p-button-icon p-c pi pi-check" />
                                <span className="p-button-label p-c">&nbsp;</span>
                            </button>
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="insight-metadata">
                    <BusinessPlanItemStatusIndicator statusHistory={props.insight.itemStatusHistory} />
                    <div className="insight-date-added">
                        <div>
                            Added on {format(new Date(props.insight.dateAdded), 'EEEE do MMMM yyyy')}
                        </div>

                        {props.insight.dateModified != null && 
                            <div>
                                Edited on {format(new Date(props.insight.dateModified), 'EEEE do MMMM yyyy')}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessPlanInsightItem;