import React, {useEffect, useState} from "react";
import {DataTable, DataTableSelectParams} from "primereact/datatable";
import {Column} from "primereact/column";
import {ObservationResult} from "./ObservationsModel";
import {useNavigate} from "react-router-dom";
import {InputText} from "primereact/inputtext";
import {FilterMatchMode} from "primereact/api";
import {ObservationHelper} from "./ObservationHelper";
import {format} from "date-fns";
import ObservationStageResultGraph from "./ObservationStageResultGraph";
import DateHelper from "../Shared/DateHelper";

interface ObservationDatatableProps {
    observations: ObservationResult[];
    loading: boolean;
    columns: string[];
    title: string;
    scrollHeight?: number;
    highlightOverdue: boolean;
    returnPath: string
}

function ObservationDatatable(props: ObservationDatatableProps) {
   
    const navigate = useNavigate();
    const [filterValue, setFilterValue] = useState<string>('');
    const [selectedColumns, setSelectedColumns] = useState<any[]>([]);
    const [filters, setFilters] = useState({
        'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    
    useEffect(() => {
        setSelectedColumns(columns.filter(x => props.columns.includes(x.field)));
    }, [props.columns])

    function scoreStageTemplate(result: ObservationResult) {
        return <ObservationStageResultGraph result={result} />
    }

    const columns = [
        {field: 'published', header: 'Status', sortFn: sortField, template: publishedTemplate, sortable: true, style: {'width': '20px'}},
        {field: 'candidateDetails', header: 'Candidate', sortFn: sortName, template: candidateNameTemplate, sortable: true},
        {field: 'candidateDetails.businessUnitName', header: 'Business Unit', sortFn: sortName, sortable: true},
        {field: 'candidateDetails.fullTitle', header: 'Position', sortFn: sortField, sortable: true},
        {field: 'observerDetails', header: 'Observer', sortFn: sortName, template: observerNameTemplate, sortable: true},
        {field: 'score', header: 'Score', sortFn: sortScore, template: scoreTemplate, sortable: true},
        {field: 'scoreByStage', header: 'Score By Stage',  template: scoreStageTemplate, sortable: false},
        {field: 'templateName', header: 'Observation', sortable: true, sortFn: sortField},
        {field: 'dateAdded', header: 'Date', sortable: true, sortFn: sortField, template: dateTemplate, style: {'width': '30px'}},
    ];

    function viewObservation(e: DataTableSelectParams) {
        navigate('/observation/view', {state: {result: e.data, returnPath: props.returnPath}});
    }

    function onFilterValueChange(e: any) {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setFilterValue(value);
    }

    function candidateNameTemplate(result: ObservationResult) {
        return (
            <>
                {result.candidateDetails?.preferredName} {result.candidateDetails?.surname}
            </>
        )
    }

    function publishedTemplate(result: ObservationResult) {
        return result.published ? <div className="published-icon"><i title="Published" className='pi pi-check-circle' /> Published</div> : <div className="not-published-icon"><i title="Not Published" className='pi pi-circle-fill' /> Not Published</div>; 
    }

    function dateTemplate(result: ObservationResult) {
        return format(DateHelper.toLocalTime(result.dateAdded), 'dd/MM/yyyy');
    }

    function observerNameTemplate(result: ObservationResult) {
        return (
            <>
                {result.observerDetails?.preferredName} {result.observerDetails?.surname}
            </>
        )
    }

    function scoreTemplate(result: ObservationResult) {
        const scoreObject = ObservationHelper.calculateOverallScore(result);
        return `${scoreObject.percentageScore}%`;
    }

    function renderTableHeader() {
        return (
            <div className="flex justify-content-between align-items-baseline">
                <h3 className="m-0">{props.title}</h3>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={filterValue} onChange={onFilterValueChange} placeholder="Keyword Search" />
                </span>
            </div>
        )
    }

    function sortName(event: any, results: ObservationResult[]) {
        let data = [...results];
        data.sort((x1, x2) => {
            const value1 = `${x1.candidateDetails?.preferredName} ${x1.candidateDetails?.surname}`;
            const value2 = `${x2.candidateDetails?.preferredName} ${x2.candidateDetails?.surname}`;
            return sort(value1, value2, event);
        });

        return data;
    }

    function sortScore(event: any, results: ObservationResult[]) {
        let data = [...results];
        data.sort((x1, x2) => {
            const score1 = ObservationHelper.calculateOverallScore(x1).percentageScore;
            const score2 = ObservationHelper.calculateOverallScore(x2).percentageScore;
            return sort(score1, score2, event);
        });

        return data;
    }

    function sortField(event: any, results: ObservationResult[], field: string) {
        let data = [...results];
        
        data.sort((x1, x2) => {
            return sort(x1[field], x2[field], event);
        });

        return data;
    }

    function sort(value1: any, value2: any, event: any): number {
        let result: number;

        if (value1 == null && value2 != null)
            result = -1;
        else if (value1 != null && value2 == null)
            result = 1;
        else if (value1 == null && value2 == null)
            result = 0;
        else if (typeof value1 === 'string' && typeof value2 === 'string')
            result = value1.localeCompare(value2, undefined, {numeric: true});
        else
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

        return (event.order * result);
    }

    const dynamicColumns = selectedColumns.map((col) => {
        return <Column 
            sortable={col.sortable} 
            key={col.field} 
            field={col.field} 
            header={col.header} 
            sortFunction={(e) => col.sortFn ? col.sortFn(e, props.observations, col.field) : null} 
            body={col.template} />;
    });

    function checkOverdue(result: ObservationResult) {
        return (props.highlightOverdue && !result.published && ObservationHelper.isOverdueForPublishing(result, 7)) ? 'overdue' : '';
    }

    return (
        <DataTable selectionMode="single" value={props.observations} scrollable
                   scrollHeight={props.scrollHeight ? `${props.scrollHeight}px` : '300px' } responsiveLayout="scroll"
                   className={`observation-table`}
                   loading={props.loading}
                   header={renderTableHeader}
                   filters={filters}
                   rowClassName={checkOverdue}
                   globalFilterFields={[...['candidateDetails.preferredName', 'candidateDetails.surname', 'observerDetails.preferredName', 'observerDetails.surname'], ...selectedColumns.map(x => x.field)]}
                   onRowSelect={(e) => viewObservation(e)}>
            {dynamicColumns}
        </DataTable>
    )
}

export default ObservationDatatable