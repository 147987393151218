import {BusinessUnit} from "../Shared/UserModel";

export interface BusinessPlanType {
    id: string;
    fiscalYear: number;
    fiscalQuarter: number;
    dateAdded: Date;
    active: boolean;
    businessUnit: string;
    directionalStatements: DirectionalStatement[];
    insights: BusinessPlanInsight[];
    actions: BusinessPlanAction[];
}

export interface BusinessPlanAction extends BusinessPlanItem {
    id: string;
    topicId: string;
    businessPlanId: string;
    action: string;
    outcome: string;
    dueDate: string;
    status: string;
    statusUpdatedDate?: Date;
    active: boolean;
    isCopied?: boolean;
    copyCount?: number;
    itemHistory: ActionItemHistory[];
}

export interface BusinessUnitAction {
   action: BusinessPlanAction;
   businessUnit: BusinessUnit;
   plan: BusinessPlanType
}

export interface BusinessPlanItemHistory {
   id: string;
   addedBy: string;
   dateAdded: Date;
}

export interface InsightItemHistory extends BusinessPlanItemHistory {
    stateSnapshot: BusinessPlanInsight;
}

export interface ActionItemHistory extends BusinessPlanItemHistory {
    stateSnapshot: BusinessPlanAction;
}

export interface DirectionalStatementItemHistory extends BusinessPlanItemHistory {
   stateSnapshot: DirectionalStatement; 
}

export interface BusinessPlanItem {
    itemStatusHistory: BusinessPlanItemStatus[];
    addedBy: string;
    dateAdded: Date;
    deleted: boolean;
    modifiedBy?: string;
    dateModified?: Date;
}

export enum BusinessPlanItemStatusType {
    NEW,
    CHANGED,
    APPROVED,
    CANCELLED,
    DECLINED
}

export interface BusinessPlanItemStatus {
    id: string;
    status: string;
    addedBy: string;
    dateAdded: Date;
    declineReason?: string | null;
    modifiedBy?: string;
    dateModified?: Date; 
}

export enum BusinessPlanActionStatus {
    OPEN,
    COMPLETED,
    TRANSFERRED
}

export interface BusinessPlanInsight extends BusinessPlanItem {
    id: string;
    topicId: string;
    insightText: string;
    active: boolean;
    itemHistory: InsightItemHistory[];
}
    
export interface DirectionalStatement extends BusinessPlanItem {
    id: string;
    employeeId: string;
    statement: string;
    reviewedWithTeamMember: boolean;
    itemHistory: DirectionalStatementItemHistory[];
}

export interface BusinessUnitPlan {
    businessUnit: BusinessUnit;
    currentPlan: BusinessPlanType | null;
    pendingChanges?: number;
    parent?: BusinessUnit | null;
}

export interface BusinessPlanBusinessDriver {
    id: string;
    name: string;
    active: boolean;
    icon?: string;
    order?: number;
    dateAdded: Date;
    addedBy: string;
    dateModified?: Date;
    modifiedBy?: string;
    topics: BusinessPlanTopic[];
}

export interface BusinessPlanTopic {
    id: string;
    name: string;
    description?: string;
    helpText?: string | undefined;
    active: boolean;
    driverId: string;
    icon?: string;
    order: number;
    dateAdded: Date;
    addedBy: string;
    hasInsights: boolean;
    hasActions: boolean;
    hasStatements: boolean;
    dateModified?: Date;
    modifiedBy?: string;
    insightTrendReasons: BusinessPlanMetricTrendReason[];
}

export interface BusinessPlanMetricTrendReason {
    id: string;
    name: string;
    active: boolean;
    order: number;
    dateAdded: Date;
    addedBy: string;
    dateModified?: Date;
    modifiedBy?: string;
}