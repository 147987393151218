import React, {useState} from 'react';
import './Observations.css';
import {Link} from "react-router-dom";
import {format} from "date-fns";
import {ObservationTemplate} from "./ObservationsModel";
import {Chip} from "primereact/chip";
import DotMenu from "../Shared/DotMenu/DotMenu";
import {MenuItem} from "primereact/menuitem";
import {v4} from "uuid";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {User} from "../Shared/UserModel";
import {InputText} from "primereact/inputtext";
import {ObservationService} from "./ObservationService";

interface TemplateTileProps {
    template: ObservationTemplate;
    tags: string[];
    user: User;
    updateTemplatesFn: any;
    isTemplateNameTaken: any;
    toast: any;
}

function TemplateTile(props: TemplateTileProps) {

    const [displayCopyDialog, setDisplayCopyDialog] = useState<boolean>();
    const [copiedTemplateName, setCopiedTemplateName] = useState<string>('');
    const dotMenuItems: MenuItem[] = [
        {
            label: 'Copy',
            icon: 'pi pi-clone',
            command: () => showCopyTemplateDialog()
        }
    ]
    
    function showCopyTemplateDialog() {
        setDisplayCopyDialog(true);
    }
    
    function renderCopyDialogFooter() {
        return (
            <Button className="app-button" label="Copy Template" onClick={() => copyTemplate()} />
        )
    }
    
    
    function copyTemplate() {
        if (copiedTemplateName == '' || props.isTemplateNameTaken(copiedTemplateName)) {
            // @ts-ignore
            props.toast.current.show({
                severity: 'warn',
                summary: 'Warning',
                detail: `Template name is mandatory and must be unique`,
                life: 3000
            });
            return;
        }
        
        const copy = deep(props.template);
        
        copy.id = v4();
        copy.name = copiedTemplateName!;
        copy.active = false;
        copy.addedBy = props.user.precedaNumber;
        copy.dateAdded = new Date();
        copy.steps.forEach(step => {
            step.id = v4();
            step.responses.forEach(response => {
                response.id = v4();
            })
        });
        
        ObservationService.getInstance().upsertTemplate(copy).then(response => {
            setDisplayCopyDialog(false);
            props.updateTemplatesFn(response.data);
        })
    }

    function deep<T extends object>(source: T): T {
        return JSON.parse(JSON.stringify(source))
    }
    
    return (

        
        <>
            <Link to={`/observation/templates/edit`} state={{template: props.template, tags: props.tags}}>
                <div className={`template-tile ${props.template.active ? 'active' : 'inactive'}`} data-testid="template-tile">
                    <div className="dot-menu-container">
                        <DotMenu items={dotMenuItems} />
                    </div>
                    <div className="template-tile-name border-gradient-purple">
                        <h3>{props.template.name}</h3>
                    </div>
                    <div className="template-tile-description line-clamp">
                        {props.template.description}
                    </div>
                    <div className="template-tile-metadata">
                        <div className="tag">
                            {props.template.tag !== null &&
                                <Chip label={props.template.tag} icon={`pi pi-tag`} /> }
                        </div>
                        <div className="added-by">
                            {`Added By ${props.template.addedByUser?.preferredName} ${props.template.addedByUser?.surname} on ${format(new Date(props.template.dateAdded), 'EEEE do MMMM yyyy')}`}
                        </div>
                    </div>
                </div>
            </Link>

            <Dialog className="copy-template-dialog" header={`Copy Template: ${props.template.name}`} visible={displayCopyDialog} style={{ width: '30vw' }} footer={renderCopyDialogFooter} onHide={() => setDisplayCopyDialog(false)}>
                <p>Please enter a new name for your template</p>
                <InputText placeholder="Template name" value={copiedTemplateName} onChange={(e) => setCopiedTemplateName(e.target.value)} required />
            </Dialog>
        </>
    )
}

export default TemplateTile;