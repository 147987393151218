import React, {Dispatch, SetStateAction} from 'react';
import {useFormik} from "formik";
import {StatementToUserMap} from "./DirectionalStatements";
import {confirmPopup} from "primereact/confirmpopup";
import BusinessPlanHelper from "./BusinessPlanHelper";
import * as Yup from "yup";
import {Message} from "primereact/message";
import ReactQuill from "react-quill";
import {Button} from "primereact/button";

const MIN_STATEMENT_CHARACTERS = 10
const MAX_STATEMENT_CHARACTERS = 1000
export const statementValidation = Yup.object().shape({
    statement: Yup.string()
        .min(MIN_STATEMENT_CHARACTERS, `Too short! The Directional Statement field has a ${MIN_STATEMENT_CHARACTERS} character minimum`)
        .max(MAX_STATEMENT_CHARACTERS, `Too long! The Directional Statement field has a ${MAX_STATEMENT_CHARACTERS} character maximum`)
        .required('Directional Statement is a required field'),
});

interface DirectionalStatementFormProps {
    toggleDialogFn: Dispatch<SetStateAction<boolean>>;
    userStatementData: StatementToUserMap;
    handleSubmit: any;
    loading: boolean;
}

function DirectionalStatementForm(props: DirectionalStatementFormProps) {
    const form = useFormik({
        initialValues: {
            statement: props.userStatementData.statement ? props.userStatementData.statement.statement : ''
        },
        validationSchema: statementValidation,
        onSubmit: (data) => {
            props.handleSubmit(data);
            form.resetForm();
        }
    });

    const confirmClose = () => {
        if (form.values.statement.length === 0) {
            props.toggleDialogFn(false);
            return;
        }
        
        confirmPopup({
            message: 'Are you sure you want to close this window? Your input will not be retained',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                form.resetForm();
                props.toggleDialogFn(false);
            },

        });
    }

    return (
        <>
            <form onSubmit={form.handleSubmit} className="directional-statement-form">
                <ReactQuill className="bp-editor"
                            modules={BusinessPlanHelper.EDITOR_MODULES}
                            theme="snow"
                            value={form.values.statement}
                            onChange={(e) => form.handleChange({target: {name: 'statement', value: e}})}/>
                
                {form.errors.statement && form.touched.statement ? (
                    <>
                        <Message severity="warn" text={form.errors.statement} />
                    </>
                ) : null}
                <div className="form-buttons">
                    <button type="button" className="app-button" onClick={() => confirmClose()}>Close</button>
                    <Button type="submit" disabled={props.loading} className="app-button" loading={props.loading} label="Save" />
                </div>
            </form>
        </>
    )
}

export default DirectionalStatementForm;