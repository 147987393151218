import React, {useContext, useEffect, useState} from "react";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {Dropdown, DropdownChangeParams} from "primereact/dropdown";
import {DataView, DataViewLayoutOptions, DataViewSortOrderType} from "primereact/dataview";
import '../ActionItem/ActionItem.css';
import {BusinessPlanService} from "../BusinessPlanService";
import {HierarchyBusinessUnit} from "../../Shared/UserModel";
import {BusinessPlanAction, BusinessPlanActionStatus, BusinessUnitAction} from "../BusinessPlanModel";
import BusinessPlanActionItem from "../ActionItem/BusinessPlanActionItem";
import HierarchyHelper from "../../Shared/HierarchyHelper";
import './ActionHub.css';
import {format} from "date-fns";
import BusinessPlanHelper from "../BusinessPlanHelper";
import {MultiSelect} from "primereact/multiselect";
import {userContext} from "../../App";
import {SelectItem} from "primereact/selectitem";
import {InputSwitch} from "primereact/inputswitch";
import DateHelper from "../../Shared/DateHelper";

interface ActionHubProps {
    businessUnitHierarchy: HierarchyBusinessUnit[];
    toast: any;
}

function ActionHub(props: ActionHubProps) {
    const {promiseInProgress} = usePromiseTracker();

    const [layout, setLayout] = useState('grid');
    const [selectedClinics, setSelectedClinics] = useState<string[]>([]);
    const [clinicSelectItems, setClinicSelectItems] = useState<SelectItem[]>([]);
    const [sortOrder, setSortOrder] = useState<DataViewSortOrderType>(-1);
    const [sortField, setSortField] = useState(null);
    const [sortKey, setSortKey] = useState(null);
    const [openActions, setOpenActions] = useState<BusinessUnitAction[]>();
    const [allActions, setAllActions] = useState<BusinessUnitAction[]>();
    const [visibleActions, setVisibleActions] = useState<BusinessUnitAction[]>();
    const [showCompleted, setShowCompleted] = useState<boolean>(false);
    const sortOptions = [
        {label: 'Target Date: Soonest', value: 'action.dueDate'},
        {label: 'Target Date: Latest', value: '!action.dueDate'},
        {label: 'Clinic: Alphabetical', value: 'businessUnit.businessUnitName'},
    ];
    const user = useContext(userContext);

    const actionStatuses = Object.keys(BusinessPlanActionStatus).filter(key => isNaN(Number(key)));

    function getAndParseActions() {
        return BusinessPlanService.getInstance().getPlansForCurrentUser().then(response => {
            const flatActions: BusinessUnitAction[] = [];
            response.data.forEach(plan => {
                const businessUnit = HierarchyHelper.searchTree(props.businessUnitHierarchy, plan.businessUnit);
                if (businessUnit) {
                    plan.actions
                        .filter(x => !x.deleted)
                        .forEach((x: BusinessPlanAction) => {
                            flatActions.push({businessUnit: businessUnit.businessUnit, action: x, plan: plan})
                        })
                }
            });

            setAllActions(flatActions);

            const openActions = flatActions.filter(x => x.action.status === BusinessPlanActionStatus[BusinessPlanActionStatus.OPEN])                        
                .sort((a1, a2) => DateHelper.sortByDate(new Date(a1.action.dueDate), new Date(a2.action.dueDate)))

            setOpenActions(openActions);
            setVisibleActions(showCompleted ? allActions : openActions);
        });
    }

    useEffect(() => {

        trackPromise(
            getAndParseActions()
        )

        generateClinicSelectItems();

    }, [props.businessUnitHierarchy]); // eslint-disable-line react-hooks/exhaustive-deps
    
    const generateClinicSelectItems = () => {
        const myBusinessUnit = HierarchyHelper.searchTree(props.businessUnitHierarchy, user?.assignedBusinessUnit?.businessUnitNumber);
        
        if (myBusinessUnit) {
            const selectItems = myBusinessUnit.children.map(x => {
                return {label: x.businessUnit.businessUnitName, value: x.businessUnit.businessUnitNumber}
            });
            
            setClinicSelectItems(selectItems);
        }
    }

    const itemTemplate = (data: BusinessUnitAction, layout: string) => {
        if (layout === 'list') {
            return renderListItem(data);
        }

        if (layout === 'grid') {
            return renderGridItem(data);
        }
    }

    const updateActions = () => {
        getAndParseActions();
    }

    const renderListItem = (data: BusinessUnitAction) => {
        return (
            <div className="col-12">
                <div className="grid action-hub-table">
                    <div className="col-2">{data.businessUnit.businessUnitName}</div>
                    <div className="col-3">
                        <span dangerouslySetInnerHTML={{__html: data.action.outcome}} />
                    </div>
                    <div className="col-3">
                        <span dangerouslySetInnerHTML={{__html: data.action.outcome}} />
                    </div>
                    <div className="col-2">
                        {format(new Date(data.action.dueDate), 'dd/MM/yyyy')}
                    </div>
                    <div className="col-1">
                        <Dropdown options={actionStatuses} value={data.action.status}
                                   onChange={(e) => updateStatus(e, data)}/>
                    </div>
                    <div className="col-1">
                        {(data.action.status === BusinessPlanActionStatus[BusinessPlanActionStatus.COMPLETED] && data.action.statusUpdatedDate)
                            && <span>{format(new Date(data.action.statusUpdatedDate), 'dd/MM/yyyy')}</span> }
                    </div>
                </div>
            </div>

        )
    }
    
    const updateStatus = (event: DropdownChangeParams, data: BusinessUnitAction) => {
        data.action.status = event.value;
        data.plan.actions = BusinessPlanHelper.editListObject(data.plan.actions, data.action);

        BusinessPlanService.getInstance().upsertBusinessPlan(data.plan).then(response => {
            props.toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: 'Action status updated successfully.',
                life: 3000
            });
            
            updateActions();

        });
    }

    const renderGridItem = (data: BusinessUnitAction) => {
        return (
            <div className="col-12 lg:col-4 md:col-6 sm:col-12">
                <BusinessPlanActionItem showPlanInformation={true}
                                        action={data.action}
                                        planDetail={{businessUnit: data.businessUnit, currentPlan: data.plan}}
                                        updateActionsFn={updateActions}
                                        toast={props.toast}
                                        readOnly={false}
                                        refreshPlanFn={null}/>
            </div>
        );
    }

    const onSortChange = (event: any) => {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            setSortOrder(-1);
            setSortField(value.substring(1, value.length));
            setSortKey(value);
        } else {
            setSortOrder(1);
            setSortField(value);
            setSortKey(value);
        }
    }
    
    const filterByClinic = (value: string[], showCompleted: boolean) => {
        setSelectedClinics(value);
        let actionsToFilter = showCompleted ? allActions : openActions;
        
        if (value.length === 0) {
            setVisibleActions(actionsToFilter);
        } else {
            setVisibleActions(actionsToFilter!.filter(x => value.includes(x.businessUnit.businessUnitNumber)));
        }
    }

    const showCompletedActions = (value: any) => {
        setShowCompleted(value);
        filterByClinic(selectedClinics, value);
    }
    
    const getTitle = () => {
        return clinicSelectItems.length === 0 ? 'My Actions' : 'My Team\'s Actions';
    }

    const renderHeader = () => {
        return (
            <>
                <div className="grid grid-nogutter">
                    <div className="col-2" style={{textAlign: 'left'}}>
                        <h3 className="hub-header">{getTitle()}</h3>
                    </div>
                    <div className="col-8 filter-container" style={{textAlign: 'left'}}>
                        <Dropdown options={sortOptions} value={sortKey} optionLabel="label"
                                  placeholder="Sort By" onChange={onSortChange}/>
                        {clinicSelectItems.length > 0 &&
                            <MultiSelect showSelectAll={false}
                                         placeholder="Filter by Clinic"
                                         value={selectedClinics}
                                         options={clinicSelectItems}
                                         onChange={(e) => filterByClinic(e.value, showCompleted)} display='chip'/>
                        }
                            <div className="show-completed-container">
                                <span>Show Completed</span>
                                <InputSwitch checked={showCompleted} onChange={(e) => showCompletedActions(e.value)}/>
                            </div>
                    </div>
                    <div className="col-2" style={{textAlign: 'right'}}>
                        <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)}/>
                    </div>
                </div>
                {layout === 'list' &&
                    <div className="grid list-header">
                        <div className="col-2">Clinic</div>
                        <div className="col-3">Action</div>
                        <div className="col-3">Outcome</div>
                        <div className="col-2">Target Date</div>
                        <div className="col-1">Status</div>
                        <div className="col-1">Completed Date</div>
                    </div>
                }

            </>

        );
    }

    const header = renderHeader();

    return (
        <>
            {!promiseInProgress &&
                <div className="action-hub">
                    <DataView paginator rows={6} value={visibleActions} layout={layout} header={header}
                              itemTemplate={itemTemplate}
                              sortOrder={sortOrder!}
                              sortField={sortField!}
                              emptyMessage="You currently have no open actions"/>
                </div> 
            }

        </>

    );
}

export default ActionHub;