import React, {useContext, useEffect, useRef, useState} from 'react';
import {useLocation} from "react-router";
import {BusinessPlanType, BusinessPlanBusinessDriver, BusinessPlanTopic, BusinessUnitPlan} from "./BusinessPlanModel";
import BackButton from "../Shared/BackButton";
import {BusinessPlanService} from "./BusinessPlanService";
import {trackPromise} from "react-promise-tracker";
import {Menu} from "primereact/menu";
import {Route, Routes, useNavigate} from "react-router-dom";
import BusinessPlanContent from "./BusinessPlanContent";
import AlertMessage, {AlertMessageType} from "../Shared/AlertMessage/AlertMessage";
import BusinessPlanSummary from "./BusinessPlanSummary";
import {MenuItem} from "primereact/menuitem";
import BusinessPlanHelper from "./BusinessPlanHelper";
import {FiscalDateHelper} from "../Shared/FiscalDateHelper";
import {userContext} from "../App";
import {Toast} from "primereact/toast";

interface props {
    planDetail: BusinessUnitPlan
    readOnly: boolean;
    reference: boolean;
}

function BusinessPlan() {
    const location = useLocation();
    const state = location.state as props
    const [planDetail, setPlanDetail] = useState<BusinessUnitPlan>(state.planDetail);
    const [pageLoading, setPageLoading] = useState(true);
    const [readOnly, setReadOnly] = useState<boolean>(state.readOnly);
    const [isReference, setIsReference] = useState<boolean>(state.reference);
    const [menuStructure, setMenuStructure] = useState<MenuItem[]>([]);
    const [structure, setStructure] = useState<BusinessPlanBusinessDriver[]>();
    const [currentQuarter, setCurrentQuarter] = useState<number>();
    const [currentYear, setCurrentYear] = useState<number>();
    const menu = useRef(null);
    const navigate = useNavigate();
    let activeId: string;
    let isSubscribed = true;
    const user = useContext(userContext);
    const toast = useRef(null);

    const navigateToPage = (topic: BusinessPlanTopic, driver: BusinessPlanBusinessDriver) => {
        activeId = topic.id;
        navigate('topic', {state: {planDetail: planDetail, structure: {topic: topic, driver: driver}}});
    }

    const isActive = (id: string) => {
        return id === activeId;
    }
    
    const navigateToSummary = () => {
        activeId = '9999'; // This number is arbitrary. Only used for active row highlighting in nav menu
        navigate('summary', {state: {planDetail: planDetail}});
    }
    
    const refreshPlanDetail = (currentPlan: BusinessPlanType) => {
        planDetail.currentPlan = currentPlan;
        setPlanDetail(planDetail);
        buildMenu(structure!);
    }

    // @ts-ignore
    useEffect(() => {
        const fiscalDate = new FiscalDateHelper(new Date());
        setCurrentQuarter(fiscalDate.getQuarter());
        setCurrentYear(fiscalDate.getYear());
        trackPromise(
            BusinessPlanService.getInstance().getStructure().then(response => {
                if (isSubscribed) {
                    buildMenu(response.data);
                    setStructure(response.data);
                    const defaultTopic = response.data[0].topics[0];
                    activeId = defaultTopic.id;
                    navigate('topic', {
                        state: {
                            planDetail: planDetail,
                            structure: {topic: defaultTopic, driver: response.data[0]}
                        }
                    })
                }
            }).finally(() => {
                setPageLoading(false);
            }));
        
        return () => isSubscribed = false;
    }, [planDetail]);

    const buildMenu = (data: BusinessPlanBusinessDriver[]) => {
        const items = data.filter(x => x.active).map(driver => {
            return {
                label: driver.name,
                items: driver.topics?.sort((x,y) => x.order - y.order).filter(x => x.active).map(topic => {
                    const declinedInsights = planDetail.currentPlan!.insights
                        .filter(x => x.topicId === topic.id)
                        .filter(x => BusinessPlanHelper.isItemStatusDeclined(x.itemStatusHistory));

                    const declinedActions = planDetail.currentPlan!.actions
                        .filter(x => x.topicId === topic.id)
                        .filter(x => BusinessPlanHelper.isItemStatusDeclined(x.itemStatusHistory));
                    let declinedStatements = [];

                    if (topic.hasStatements) {
                        declinedStatements = planDetail.currentPlan!.directionalStatements
                            .filter(x => BusinessPlanHelper.isItemStatusDeclined(x.itemStatusHistory));

                    }
                    
                    const menuAlert = (declinedInsights.length + declinedActions.length + declinedStatements.length) > 0 ? <span className="declined-badge" /> : null;

                    return {
                        command: () => navigateToPage(topic, driver),
                        label: `${topic.name}`,
                        template: (item: any, options: any) => {
                            return (
                                <a href="/#" className={options.className + (isActive(topic.id) ? ' active' : ' inactive')}
                                   target={item.target} onClick={options.onClick}>
                                    <span className={options.labelClassName}>{item.label}</span>
                                    {menuAlert}
                                </a>
                            );
                        }
                    }
                })
            }
        });

        const summaryComponent = {
            label: 'Overall',
            items: [
                {
                    label: 'Summary',
                    className: 'summary-component',
                    command: () => navigateToSummary(),
                    template: (item: any, options: any) => {
                        return (
                            <>
                                <a href="/#" className={options.className + (isActive('9999') ? ' active' : ' inactive')}
                                   target={item.target} onClick={options.onClick}>
                                    <span className={'summary-label ' + options.labelClassName}>{item.label}</span>
                                </a>
                            </>
                        );
                    }
                }
            ]
        }
        items.push(summaryComponent);
        setMenuStructure(items);
    }

    if (pageLoading) {
        return(<></>)
    }

    if (menuStructure.length === 0) {
        return (
            <AlertMessage message="We were unable to load the structure for your Business Plan"
                          type={AlertMessageType.ERROR}/>
        )
    }

    return (
        <>
            <Toast ref={toast}/>

                <div className="business-plan-container" data-testid="business-plan-container">
                    <h2>
                        <BackButton
                            path="/business-plan"/> { isReference ? `${planDetail?.businessUnit.businessUnitName}: FY${currentYear} Q${currentQuarter}` : `${planDetail?.businessUnit.businessUnitName}: FY${planDetail?.currentPlan?.fiscalYear} Q${planDetail?.currentPlan?.fiscalQuarter}`}
                    </h2>

                    <div className="plan-body">
                        <div className="plan-menu-container">
                            <Menu model={menuStructure} ref={menu}/>
                        </div>

                        <div className="plan-content-container">
                            <Routes>
                                <Route path='topic' element={<BusinessPlanContent isReference={isReference} refreshPlanFn={refreshPlanDetail} readOnly={readOnly} planDetail={planDetail} updatePlanDetailFn={setPlanDetail}/>}/>
                                <Route path='summary' element={<BusinessPlanSummary toast={toast} user={user} isReference={isReference} refreshPlanFn={refreshPlanDetail} structure={structure!} planDetail={planDetail} updatePlanDetailFn={setPlanDetail} />} />
                            </Routes>
                        </div>
                    </div>
                </div>

        </>
    )
}

export default BusinessPlan;