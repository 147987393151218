import React, {Dispatch, SetStateAction} from 'react';
import DirectionalStatementForm from "./DirectionalStatementForm";
import {StatementToUserMap} from "./DirectionalStatements";

interface DirectionalStatementDialogProps {
    toggleDialogFn: Dispatch<SetStateAction<boolean>>;
    handleSubmit: any;
    userStatementData: StatementToUserMap;
    loading: boolean;
}

function DirectionalStatementDialog(props: DirectionalStatementDialogProps) {

    return (
        <>
            <DirectionalStatementForm loading={props.loading} handleSubmit={props.handleSubmit} toggleDialogFn={props.toggleDialogFn} userStatementData={props.userStatementData} />
        </>
    )
}

export default DirectionalStatementDialog;