import {AxiosResponse} from "axios";
import {
    BusinessPlanType,
    BusinessPlanBusinessDriver,
    BusinessPlanTopic, BusinessPlanAction
} from "./BusinessPlanModel";
import {httpClient} from "../AuthClient";

export class BusinessPlanService {
    static myInstance = null;

    static getInstance() {
        return new BusinessPlanService();
    }

    baseUrl = 'api/businessPlan';


    public getPlanById(businessPlanId: string): Promise<AxiosResponse<BusinessPlanType>> {
        return httpClient.get<BusinessPlanType>(`/${this.baseUrl}/${businessPlanId}`);
    }
    
    public getPlansForCurrentUser(): Promise<AxiosResponse<BusinessPlanType[]>> {
        return httpClient.get<BusinessPlanType[]>(this.baseUrl);
    }

    public getActionsForCurrentUser(): Promise<AxiosResponse<any[]>> {
        return httpClient.get<BusinessPlanAction[]>(`${this.baseUrl}/actions`);
    }

    public getActionsBySearch(): Promise<AxiosResponse<any[]>> {
        return httpClient.get<BusinessPlanAction[]>(`${this.baseUrl}/actionssearch`);
    }

    upsertBusinessPlan(plan: BusinessPlanType) {
        return httpClient.post(`/${this.baseUrl}`, plan)
    }

    upsertDriver(driver: BusinessPlanBusinessDriver): Promise<AxiosResponse<BusinessPlanBusinessDriver>> {
        return httpClient.post(`/${this.baseUrl}/driver`, driver)
    }

    deleteDriver(driver: BusinessPlanBusinessDriver): Promise<AxiosResponse<number>> {
        return httpClient.delete<number>(`/${this.baseUrl}/driver/${driver.id}`);
    }

    getDrivers(): Promise<AxiosResponse<BusinessPlanBusinessDriver[]>> {
        return httpClient.get<BusinessPlanBusinessDriver[]>(`/${this.baseUrl}/driver`)
    }

    upsertTopic(topic: BusinessPlanTopic): Promise<AxiosResponse<BusinessPlanTopic>> {
        return httpClient.post(`/${this.baseUrl}/topic`, topic);
    }

    deleteTopic(topic: BusinessPlanTopic): Promise<AxiosResponse<number>> {
        return httpClient.delete<number>(`/${this.baseUrl}/topic/${topic.id}/${topic.driverId}`);
    }

    getTopics(topicId: string): Promise<AxiosResponse<BusinessPlanTopic[]>> {
        return httpClient.get<BusinessPlanTopic[]>(`/${this.baseUrl}/topic?topicId=${topicId}`);
    }

    getStructure(skipCache: boolean = false): Promise<AxiosResponse<BusinessPlanBusinessDriver[]>> {
        return httpClient.get<BusinessPlanBusinessDriver[]>(`/${this.baseUrl}/structure?skipCache=${skipCache}`);
    }

    getPlansByBusinessUnitsAndPeriod(businessUnits: string[] | null | undefined, quarter: number | null | undefined, year: number | null | undefined): Promise<AxiosResponse<BusinessPlanType[]>> {
        
        let queryParams = [];

        if (businessUnits != null){
            businessUnits.forEach(i => {
                queryParams.push(`businessUnits=${i}`)
            })
        }

        if(quarter != null){
            queryParams.push(`quarter=${quarter}`);
        }

        if(year != null){
            queryParams.push(`year=${year}`);
        }

        return httpClient.get<BusinessPlanType[]>(`/${this.baseUrl}/search${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`);
    }

    upsertTrendReason(kpi: BusinessPlanTopic): Promise<AxiosResponse<BusinessPlanTopic>> {
        return httpClient.post(`/${this.baseUrl}/kpi`, kpi)
    }
}