import {AxiosResponse} from "axios";
import {httpClient} from "../AuthClient";
import {SkillConversation} from "./CoachingModel";

export class CoachingService {
    static myInstance = null;

    static getInstance() {
        return new CoachingService();
    }

    baseUrl = 'api/coaching';

    upsertConversation(conversation: SkillConversation): Promise<AxiosResponse<SkillConversation>> {
        return httpClient.post(`/${this.baseUrl}/skill`, conversation)
    }

    getMyConversations(): Promise<AxiosResponse<SkillConversation[]>> {
        return httpClient.get(`/${this.baseUrl}`);
    }

}