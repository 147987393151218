import React from 'react';
import './SignIn.css';

function SignIn(props: any) {
    const signIn = () => {
        props.fnSignIn();
    }

    return (
        <div className="sign-in-container">
            <h2 className="animate__animated animate__backInDown">Canvas</h2>
            <button className="app-button animate__animated animate__fadeInUp animate__delay-1s"
                    onClick={signIn}>Login
            </button>
        </div>
    );
}

export default SignIn;