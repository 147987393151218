import React, {useRef} from 'react';
import {useLocation} from "react-router";
import {BusinessPlanBusinessDriver, BusinessPlanTopic, BusinessUnitPlan} from "./BusinessPlanModel";
import {PlanContentHeader} from "./PlanContentHeader";
import TopicContent from "./TopicContent";
import {Toast} from "primereact/toast";

interface BusinessPlanContentProps {
    planDetail: BusinessUnitPlan;
    updatePlanDetailFn: any;
    readOnly: boolean;
    refreshPlanFn: any;
    isReference: boolean;
}

interface StateProps {
    structure: any;
}

function BusinessPlanContent(props: BusinessPlanContentProps) {
    const location = useLocation();
    const state = location.state as StateProps;
    const topic: BusinessPlanTopic = state.structure.topic;
    const currentDriver: BusinessPlanBusinessDriver = state.structure.driver;
    const toast = useRef(null);

    return (
            <>
                <Toast ref={toast}/>
                <PlanContentHeader title={topic.name} subtitle={currentDriver.name}/>
                <TopicContent isReference={props.isReference} refreshPlanFn={props.refreshPlanFn} readOnly={props.readOnly} planDetail={props.planDetail} topic={topic} toast={toast} updatePlanDetailFn={props.updatePlanDetailFn}/>
            </>
    )
}

export default BusinessPlanContent;