import React, {useEffect, useState} from "react";
import { OrganizationChart } from 'primereact/organizationchart';
import UserService from "../../Shared/UserService";
import { HierarchyBusinessUnit } from "../../Shared/UserModel";
import {Employee} from "../../Shared/UserModel";
import HierarchyService from "../../Shared/HierarchyService";
import { ScrollPanel } from 'primereact/scrollpanel';
import { DataViewLayoutOptions } from "primereact/dataview";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';

function PermissionManager() {

    const [layout, setLayout] = useState('grid');
    const [areas, setAreas] = useState<HierarchyBusinessUnit[]>([]);
    const [employees, setEmployees] = useState<Employee[]>();

    useEffect(() => {
        UserService.getInstance().fetchAreas().then(i => {
            setAreas(i.data);
        });
        // @ts-ignore
        HierarchyService.getInstance().fetchUsers().then(x => setEmployees(x.data.result));
    }, []);

    function mapBusinessUnitToOrgChartData(bu: HierarchyBusinessUnit) : any {

        let hasBuChildren = bu.children !== null && bu.children.length > 0;
        let buChildren = hasBuChildren ? bu.children.map(i => mapBusinessUnitToOrgChartData(i)) : [];
        let associatedEmployees = employees?.filter(i => i.businessUnit === bu.businessUnit.businessUnitNumber).map(i => {

            let shortTitle = i.fullTitle.trim().split(" ").map(j => j.charAt(0)).join("").toUpperCase();

            return {
                label: `${shortTitle} ${i.preferredName} ${i.surname}`,
                expanded: true
            }
        }) ?? [];
    
        return {
            label: bu.businessUnit.businessUnitName,
            expanded: true,
            children: [...buChildren, ...associatedEmployees]
        }
    }

    let listViewData = areas.map(i => {

        return {
            key: i.businessUnit.businessUnitName,
            data: {
                businessunit: `${i.businessUnit.businessUnitName}`,
                title: 'Area Manager',
                employee: employees?.filter(j => j.businessUnit === i.businessUnit.businessUnitNumber && j.fullTitle === "Area Manager")?.map(j => `${j.preferredName} ${j.surname}`).join(", ")
            },
            children: i.children.map(j => {
                return {
                    key: j.businessUnit.businessUnitName,
                    data: {
                        businessunit: j.businessUnit.businessUnitName,
                        title: "Clinic Development Manager",
                        employee: employees?.filter(k => k.businessUnit === j.businessUnit.businessUnitNumber && k.fullTitle === "Clinic Development Manager")?.map(j => `${j.preferredName} ${j.surname}`).join(", ")
                    },
                    children: employees?.filter(k => k.businessUnit === j.businessUnit.businessUnitNumber && k.fullTitle !== "Clinic Development Manager" && k.fullTitle !== "Area Manager").sort((a,b) => a.fullTitle.localeCompare(b.fullTitle)).map(k => {
                        return {
                            key: k.positionId,
                            data: {
                                title: k.fullTitle,
                                employee: `${k.preferredName} ${k.surname}`
                            }
                        }
                    })
                }
            })
        }
    })

    return (
        <>
            <div style={{
                textAlign: 'right',
                marginBottom: 10
            }}>
                <DataViewLayoutOptions layout={layout} onChange={(e) => setLayout(e.value)}/>
            </div>
            {
                layout === 'list'
                    ?
                        <div>
                            <TreeTable value={listViewData}>
                                <Column field="businessunit" header="Business Unit" expander/>
                                <Column field="title" header="Title"/>
                                <Column field="employee" header="Employee"/>
                            </TreeTable>
                        </div>
                    :
                        <div>
                            <ScrollPanel style={{
                                height: 1000
                            }}>
                                {
                                    areas.map(i => 
                                        <OrganizationChart key={i.businessUnit.businessUnitNumber} value={[mapBusinessUnitToOrgChartData(i)]} />
                                    )
                                }
                            </ScrollPanel>
                        </div>
            }
        </>
    )
}

export default PermissionManager;