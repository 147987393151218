import React from "react";

interface Props {
    title: string;
    subtitle: string;
}

export const PlanContentHeader = (props: Props) => {
    return (
        <>
            <div className="header-subtitle-group">
                <div className="group">
                    <div className="header">{props.title}</div>
                </div>
                <div className="right-title">{props.subtitle}</div>
            </div>
        </>

    )
};