import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Dialog} from "primereact/dialog";
import {v4} from "uuid";
import './Observations.css';
import {ObservationService} from "./ObservationService";
import {Toast} from "primereact/toast";
import TemplateTile from "./TemplateTile";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {Card} from "primereact/card";
import EmptyMessage from "../Shared/EmptyMessage/EmptyMessage";
import {userContext} from "../App";
import ObservationTemplateForm from "./ObservationTemplateForm";
import {ObservationTemplate} from "./ObservationsModel";
import {InputText} from "primereact/inputtext";

interface ObservationTemplateManagerProps {
}

function ObservationTemplateManager(props: ObservationTemplateManagerProps) {
    const user = useContext(userContext);
    const toast = useRef(null);
    let {promiseInProgress} = usePromiseTracker();
    const [displayNewTemplateDialog, setDisplayNewTemplateDialog] = useState<boolean>();
    const [addTemplateLoading, setAddTemplateLoading] = useState<boolean>();
    const [activeTemplates, setActiveTemplates] = useState<ObservationTemplate[]>([]);
    const [inactiveTemplates, setInactiveTemplates] = useState<ObservationTemplate[]>([]);
    const [tagSuggestions, setTagSuggestions] = useState<any[]>([]);

    const [activeSearch, setActiveSearch] = useState("");
    const filteredActive = useMemo(() => {
        if (activeSearch) {
            return activeTemplates.filter(
                (item) =>
                    item.name?.toLowerCase()
                        .indexOf(activeSearch?.toLocaleLowerCase()) > -1
                    ||
                    item.tag?.toLowerCase()
                        .indexOf(activeSearch?.toLocaleLowerCase()) > -1
            );
        }
        return activeTemplates;
    }, [activeSearch, activeTemplates]);

    const [inactiveSearch, setInactiveSearch] = useState("");
    const filteredInactive = useMemo(() => {
        if (inactiveSearch) {
            return inactiveTemplates.filter(
                (item) =>
                    item.name?.toLowerCase()
                        .indexOf(inactiveSearch?.toLocaleLowerCase()) > -1
                    ||
                    item.tag?.toLowerCase()
                        .indexOf(inactiveSearch?.toLocaleLowerCase()) > -1
            );
        }
        return inactiveTemplates;
    }, [inactiveSearch, inactiveTemplates]);
    let isSubscribed = true;

    // @ts-ignore
    useEffect(() => {

        trackPromise(
            ObservationService.getInstance().getAllTemplates().then(response => {
                if (isSubscribed) {
                    setInactiveTemplates(response.data.filter(x => !x.active));
                    setActiveTemplates(response.data.filter(x => x.active));
                    setTagSuggestions(Array.from(new Set(response.data.filter(x => !x.deleted && x.tag !== null).map(x => x.tag))))
                }
            })
        )

        return () => isSubscribed = false;
    }, []);

    const showTemplateDialog = () => {
        setDisplayNewTemplateDialog(true);
    }

    const isTemplateNameTaken = (name: string) => {
        return [...inactiveTemplates, ...activeTemplates].map(x => x.name.toLowerCase()).includes(name.toLowerCase());
    }

    const handleNewTemplateFormSubmit = async (data: any) => {
        setAddTemplateLoading(true);
        const additionalFields = {
            id: v4(),
            active: false,
            deleted: false,
            addedBy: user.precedaNumber,
            dateAdded: new Date(),
            steps: []
        }

        const newTemplate: ObservationTemplate = {...data, ...additionalFields};

        ObservationService.getInstance().upsertTemplate(newTemplate).then(result => {
            // @ts-ignore
            toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: `Your template was added successfully`,
                life: 3000
            });

            setInactiveTemplates([...inactiveTemplates, result.data]);
            setAddTemplateLoading(false);
            setDisplayNewTemplateDialog(false);
        });
    }

    function addCopiedTemplate(template: ObservationTemplate) {
        setInactiveTemplates([...inactiveTemplates, template])
        // @ts-ignore
        toast.current.show({
            severity: 'success',
            summary: 'Success!',
            detail: `Your template was copied and added to the inactive list`,
            life: 3000
        });
    }

    function activeCardTitle() {
        return (
            <div className="flex justify-content-between">
                <div className="p-card-title">Active</div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText value={activeSearch} placeholder={`Filter templates`}
                               onChange={(e) => setActiveSearch(e.target.value)}/>
                </span>
            </div>
        )
    }

    function inactiveCardTitle() {
        return (
            <div className="flex justify-content-between">
                <div className="p-card-title">Inactive</div>
                <span className="p-input-icon-left">
                    <i className="pi pi-search"/>
                    <InputText value={inactiveSearch} placeholder={`Filter templates`}
                               onChange={(e) => setInactiveSearch(e.target.value)}/>
                </span>
            </div>
        )
    }

    return (
        <div className="consulting-template-container">
            <Toast ref={toast}/>
            <div className="header-group">
                <h2>Observation Template Manager</h2>
                <button id="add-new-template-button" data-testid="add-new-template-button" className="app-button"
                        onClick={() => showTemplateDialog()}>Add New Template
                </button>
            </div>
            {!promiseInProgress &&
                <>
                    <div className="grid">
                        <div className="col-6">
                            <Card title={activeCardTitle} subTitle="These templates are visible to users">
                                {filteredActive.length > 0 ?
                                    <div className="active-template-list">
                                        <ul className="template-list" data-testid="active-template-list">
                                            {filteredActive
                                                .map(x => {
                                                    return <li key={x.id}><TemplateTile toast={toast}
                                                                                        isTemplateNameTaken={isTemplateNameTaken}
                                                                                        updateTemplatesFn={addCopiedTemplate}
                                                                                        user={user}
                                                                                        tags={tagSuggestions}
                                                                                        template={x}/></li>
                                                })}
                                        </ul>
                                    </div> : <EmptyMessage message="No active templates to display"/>}
                            </Card>

                        </div>
                        <div className="col-6">
                            <Card title={inactiveCardTitle}
                                  subTitle="These templates are still in development or retired">
                                {filteredInactive.filter(x => !x.active).length > 0 ?

                                    <div className="inactive-template-list">
                                        <ul className="template-list" data-testid="inactive-template-list">
                                            {filteredInactive
                                                .map(x => {
                                                    return <li key={x.id}><TemplateTile toast={toast}
                                                                                        isTemplateNameTaken={isTemplateNameTaken}
                                                                                        updateTemplatesFn={addCopiedTemplate}
                                                                                        user={user}
                                                                                        tags={tagSuggestions}
                                                                                        template={x}/></li>
                                                })}
                                        </ul>
                                    </div> : <EmptyMessage message="No inactive templates to display"/>}
                            </Card>
                        </div>
                    </div>
                </>
            }

            <Dialog header="Add New Template" visible={displayNewTemplateDialog} style={{width: '400px'}}
                    onHide={() => setDisplayNewTemplateDialog(false)}>
                <ObservationTemplateForm handleSubmit={handleNewTemplateFormSubmit} loading={addTemplateLoading!}
                                         tags={tagSuggestions}/>
            </Dialog>
        </div>
    )


}

export default ObservationTemplateManager;