import React, {useContext} from 'react';
import {useFormik} from 'formik';
import {
    BusinessPlanActionStatus,
    BusinessPlanItemStatusType,
    BusinessPlanTopic,
    BusinessUnitPlan
} from "./BusinessPlanModel";
import {userContext} from "../App";
import {Message} from "primereact/message";
import {Calendar} from "primereact/calendar";
import {v4} from "uuid";
import {BusinessPlanService} from "./BusinessPlanService";
import {confirmDialog} from "primereact/confirmdialog";
import * as Yup from 'yup';
import ReactQuill from "react-quill";
import BusinessPlanHelper from "./BusinessPlanHelper";

interface BusinessPlanActionFormProps {
    topic: BusinessPlanTopic;
    planDetail: BusinessUnitPlan;
    updateActionsFn: any;
    showDialogFn: any;
    toast: any;
}

const MAX_ACTION_CHARACTERS = 10000;
const MIN_ACTION_CHARACTERS = 10;

export const actionValidation = Yup.object().shape({
    action: Yup.string()
        .min(MIN_ACTION_CHARACTERS, `Too short! The Action field has a ${MIN_ACTION_CHARACTERS} character minimum`)
        .max(MAX_ACTION_CHARACTERS, `Too long! The Action field has a ${MAX_ACTION_CHARACTERS} character maximum`)
        .required('Action is a required field'),
    outcome: Yup.string()
        .min(MIN_ACTION_CHARACTERS, `Too short! The Outcome field has a ${MIN_ACTION_CHARACTERS} character minimum`)
        .max(MAX_ACTION_CHARACTERS, `Too long! The Outcome field has a ${MAX_ACTION_CHARACTERS} character maximum`)
        .required("Outcome is a required field"),
    dueDate: Yup.date().min(new Date()).required("Target Date is a required field")
});

function BusinessPlanActionForm(props: BusinessPlanActionFormProps) {

    const user = useContext(userContext);

    const formik = useFormik({
        initialValues: {
            action: '',
            outcome: '',
            dueDate: ''
        },
        validationSchema: actionValidation,
        onSubmit: (data) => {
            const additionalFields = {
                id: v4(),
                addedBy: user.precedaNumber,
                dateAdded: new Date(),
                topicId: props.topic.id,
                businessPlanId: props.planDetail.currentPlan!.id,
                active: true,
                status: BusinessPlanActionStatus[BusinessPlanActionStatus.OPEN],
                itemStatusHistory: [
                    {id: v4(), status: BusinessPlanItemStatusType[BusinessPlanItemStatusType.NEW], addedBy: user.precedaNumber, dateAdded: new Date()}
                ],
                itemHistory: [],
                deleted: false
            };
            
            const action = {...additionalFields, ...data};
            
            if (props.planDetail.currentPlan!.actions == null) props.planDetail.currentPlan!.actions = [];
            
            props.planDetail.currentPlan!.actions.push(action);

            BusinessPlanService.getInstance().upsertBusinessPlan(props.planDetail.currentPlan!).then(result => {
                props.toast.current.show({
                    severity: 'success',
                    summary: 'Success!',
                    detail: `Your action was added successfully.`,
                    life: 3000
                });
                props.updateActionsFn(result.data.actions);
                props.showDialogFn(false);
            });
        }
    });
    

    
    const confirm = () => {
        confirmDialog({
            message: 'This record has not been saved. Are you sure you want to close the record without saving?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {props.showDialogFn(false); formik.resetForm()},
        });
    }

    return (
        
        <form onSubmit={formik.handleSubmit} className="action-form">
            <div className="">
                <div className="field">
                    <label htmlFor="actionInput">Action</label>

                    <ReactQuill className="bp-editor"
                                modules={BusinessPlanHelper.EDITOR_MODULES}
                                theme="snow"
                                value={formik.values.action}
                                onChange={(e) => formik.handleChange({target: {name: 'action', value: e}})}/>
                    
                    <span className="validation-rule">Min {MIN_ACTION_CHARACTERS} characters | Max {MAX_ACTION_CHARACTERS} characters</span>

                    {formik.errors.action && formik.touched.action ? (
                        <>
                            <Message severity="warn" text={formik.errors.action} />
                        </>
                    ) : null}
                </div>

                <div className="field">
                    <label htmlFor="outcomeInput">Expected Outcome</label>

                    <ReactQuill className="bp-editor"
                                modules={BusinessPlanHelper.EDITOR_MODULES}
                                theme="snow"
                                value={formik.values.outcome}
                                onChange={(e) => formik.handleChange({target: {name: 'outcome', value: e}})}/>
                    <span className="validation-rule">Min {MIN_ACTION_CHARACTERS} characters | Max {MAX_ACTION_CHARACTERS} characters</span>

                    {formik.errors.outcome && formik.touched.outcome ? (
                        <>
                            <Message severity="warn" text={formik.errors.outcome} />
                        </>
                    ) : null}
                    
                </div>

                <div className="field">
                    <label htmlFor="dueDateInput">Target Date</label>
                    <Calendar dateFormat="dd/mm/yy" name="dueDate" readOnlyInput minDate={new Date()} id="dueDateInput"
                              value={new Date(formik.values.dueDate)}
                              onChange={formik.handleChange} showIcon />

                    {formik.errors.dueDate && formik.touched.dueDate ? (
                        <>
                            <Message severity="warn" text={formik.errors.dueDate} />
                        </>
                    ) : null}
                </div>

            </div>

            <div className="form-buttons">
                <button type="button" className="app-button" onClick={() => confirm()}>Close</button>
                <button type="button" className="app-button" onClick={() => formik.resetForm()}>Clear</button>
                <button type="submit" className="app-button">Add</button>
            </div>

        </form>

    );
}

export default BusinessPlanActionForm;
