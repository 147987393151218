import {AxiosResponse} from "axios";
import {httpClient} from "../AuthClient";
import {ObservationResult, ObservationTemplate} from "./ObservationsModel";

export class ObservationService {
    static myInstance = null;

    static getInstance() {
        return new ObservationService();
    }

    baseUrl = 'api/observation';


    public getAllTemplates(): Promise<AxiosResponse<ObservationTemplate[]>> {
        return httpClient.get<ObservationTemplate[]>(`/${this.baseUrl}/template`);
    }

    getObservationsByBusinessUnitsAndPeriod(businessUnits: string[], month: number | null, year: number | null):  Promise<AxiosResponse<ObservationResult[]>> {
        const parameterString = this.generateListQueryString('businessUnits', businessUnits);
        const queryString = `${parameterString}${month ? '&month='+month : ''}${year ? '&year=' + year : ''}`;
        return httpClient.get<ObservationResult[]>(`/${this.baseUrl}/search?${queryString}`);
    }
    
    getObservationsPerformed(take: number, skip: number):  Promise<AxiosResponse<ObservationResult[]>> {
        return httpClient.get<ObservationResult[]>(`/${this.baseUrl}/performed?take=${take}&skip=${skip}`);
    }

    getObservationsDoneOnMe(take: number, skip: number):  Promise<AxiosResponse<ObservationResult[]>> {
        return httpClient.get<ObservationResult[]>(`/${this.baseUrl}/?take=${take}&skip=${skip}`);
    }

    getObservationsToPublish():  Promise<AxiosResponse<ObservationResult[]>> {
        return httpClient.get<ObservationResult[]>(`/${this.baseUrl}/unpublished`);
    }

    upsertTemplate(template: ObservationTemplate) {
        return httpClient.post(`/${this.baseUrl}/template`, template);
    }

    upsertObservationResult(result: ObservationResult) {
        return httpClient.post(`/${this.baseUrl}/result`, result);
    }

    generateListQueryString(parameterName: string, parameters: any[]) {
        let returnString = '';
        parameters.forEach(x => {
            returnString += `${parameterName}=${x}&`}
        )
        
        
        return returnString;
    }
}