import React, {useEffect, useState} from "react";
import {BusinessUnitPlan} from "./BusinessPlanModel";
import {Link} from "react-router-dom";
import {Badge} from "primereact/badge";
import {FiscalDateHelper} from "../Shared/FiscalDateHelper";

interface PropsType {
    planDetail: BusinessUnitPlan;
    readOnly: boolean;
    reference: boolean;
}

function PlanTile(props: PropsType) {

    const businessUnitPlan: BusinessUnitPlan = props.planDetail;
    const [currentQuarter, setCurrentQuarter] = useState<number>();
    const [currentYear, setCurrentYear] = useState<number>();
    
    useEffect(() => {
        const fiscalDate = new FiscalDateHelper(new Date());
        setCurrentQuarter(fiscalDate.getQuarter());
        setCurrentYear(fiscalDate.getYear());
    }, [])
    
    return (
        <Link to={`/business-plan/current`} state={{planDetail: props?.planDetail, structure: null, readOnly: props.readOnly, reference: props.reference}} data-testid="plan-tile-href">
            <div className='current-plan'>
                {props.planDetail?.pendingChanges! > 0 && <Badge value={props.planDetail?.pendingChanges} severity="danger" className="pending-changes-badge" />}
                <div className="content">
                    <div className="title">
                        {businessUnitPlan?.businessUnit.businessUnitName}
                    </div>
                    <div className="subtitle">
                        { props.reference ?  `FY${currentYear} Q${currentQuarter}` : `FY${businessUnitPlan?.currentPlan!.fiscalYear} Q${businessUnitPlan?.currentPlan!.fiscalQuarter}`}
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default PlanTile;