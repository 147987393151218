import {AxiosResponse} from "axios";
import {User} from "./UserModel";
import {httpClient} from "../AuthClient";

export class BusinessUnitService {

    public static getBusinessUnitStaff(businessUnit: string): Promise<AxiosResponse<User[]>> {
        return httpClient.get<User[]>(`/api/BusinessUnit/${businessUnit}/staff`);
    }
}

export default BusinessUnitService;