import React, {useContext, useEffect, useState} from 'react';
import {BusinessUnitPlan} from "./BusinessPlanModel";
import {BusinessPlanService} from "./BusinessPlanService";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {useNavigate} from "react-router-dom";
import {v4} from "uuid";
import {userContext} from "../App";
import EmptyPlanTile from "./EmptyPlanTile";
import { Card } from 'primereact/card';
import BusinessPlanHelper from "./BusinessPlanHelper";

function ReferenceBusinessPlan() {

    const [referencePlan, setReferencePlan] = useState<BusinessUnitPlan>()
    const navigate = useNavigate();
    const user = useContext(userContext);
    const {promiseInProgress} = usePromiseTracker();

    // @ts-ignore
    useEffect(() => {

        let isSubscribed = true
        trackPromise(
            BusinessPlanService.getInstance()
                .getPlansByBusinessUnitsAndPeriod([BusinessPlanHelper.REFERENCE_PLAN_BUSINESS_UNIT], BusinessPlanHelper.REFERENCE_PLAN_QUARTER, BusinessPlanHelper.REFERENCE_PLAN_YEAR)
                .then(response => {
                    if (isSubscribed) {

                        if (response.data.length === 1) {
                            const planDetail = {
                                businessUnit: BusinessPlanHelper.createReferenceBusinessUnitObject(),
                                currentPlan: response.data[0]
                            }
                            setReferencePlan(planDetail);
                            navigateToReferencePlan(planDetail);
                        }
                    }
                }));

        return () => isSubscribed = false;
    }, []);

    const navigateToReferencePlan = (planDetail: BusinessUnitPlan) => {
        navigate('/business-plan/current', {
            state: {
                planDetail: planDetail,
                readOnly: false,
                structure: null,
                reference: true
            }
        })
    }



    const createReferencePlan = () => {
        const plan = {
            id: v4(),
            businessUnit: BusinessPlanHelper.REFERENCE_PLAN_BUSINESS_UNIT,
            fiscalYear: BusinessPlanHelper.REFERENCE_PLAN_YEAR,
            fiscalQuarter: BusinessPlanHelper.REFERENCE_PLAN_QUARTER,
            active: true,
            dateAdded: new Date(),
            addedBy: user.precedaNumber,
            directionalStatements: [],
            insights: [],
            actions: [],
        }

        BusinessPlanService.getInstance().upsertBusinessPlan(plan).then((response) => {
            const planDetail = {
                businessUnit: BusinessPlanHelper.createReferenceBusinessUnitObject(),
                currentPlan: response.data
            }
            navigateToReferencePlan(planDetail);
        });
    }

    const emptyPlanObject = () => {
        return {businessUnit: BusinessPlanHelper.createReferenceBusinessUnitObject(), currentPlan: {
                id: v4(),
                businessUnit: BusinessPlanHelper.REFERENCE_PLAN_BUSINESS_UNIT,
                fiscalYear: BusinessPlanHelper.REFERENCE_PLAN_YEAR,
                fiscalQuarter: BusinessPlanHelper.REFERENCE_PLAN_QUARTER,
                active: true,
                dateAdded: new Date(),
                addedBy: user.precedaNumber,
                directionalStatements: [],
                insights: [],
                actions: [],
            }};
    }
    
    // @ts-ignore
    return (
        <>
            {(!referencePlan && !promiseInProgress) &&
                <Card title="Reference Plan" className="reference-plan-container">
                        <div className="sub-text">
                            Click below to create a Business Plan that can be used as a reference for all Business Plan users.
                        </div>
                        <EmptyPlanTile planDetail={emptyPlanObject()} createPlanFn={createReferencePlan}  loadingRefFn={null} />
                </Card>

            }
        </>
    )
}

export default ReferenceBusinessPlan;