import React, {useEffect, useRef, useState} from 'react';
import './BusinessPlan.css';
import '../Shared/AlertMessage/AlertMessage.css';
import {trackPromise} from "react-promise-tracker";
import UserService from "../Shared/UserService";
import {TreeSelect} from 'primereact/treeselect';
import TreeNode from "primereact/treenode";
import {Dropdown} from "primereact/dropdown";
import {BusinessPlanService} from "./BusinessPlanService";
import {BusinessPlanType, BusinessUnitPlan} from "./BusinessPlanModel";
import {Toast} from "primereact/toast";
import PlanTile from "./PlanTile";
import BusinessPlanHelper from "./BusinessPlanHelper";
import {differenceInMinutes} from "date-fns";
import {Fieldset} from "primereact/fieldset";
import {FiscalDateHelper} from "../Shared/FiscalDateHelper";
import HierarchyHelper from "../Shared/HierarchyHelper";
import { TabView, TabPanel } from 'primereact/tabview';
import {BusinessPlanBusinessDriver} from "../BusinessPlan/BusinessPlanModel";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {FilterMatchMode} from "primereact/api";
import {format} from "date-fns";
import {MultiSelect} from "primereact/multiselect";
import {Link} from "react-router-dom";

function BusinessPlanExplorer() {

    const fiscalDateHelper = new FiscalDateHelper(new Date());
    const toast = useRef(null);
    const [nodes, setNodes] = useState<TreeNode[]>([]);
    const [selectedNodeKey, setSelectedNodeKeys] = useState<any[] | null>([]);
    const [selectedQuarter, setSelectedQuarter] = useState<any>(fiscalDateHelper.getQuarter());
    const [selectedYear, setSelectedYear] = useState<any>(fiscalDateHelper.getYear());
    const [years, setYears] = useState<any[]>([]);
    const [searchResults, setSearchResults] = useState<BusinessUnitPlan[]>([]);
    const quarters = ["Any", 1, 2, 3, 4];
    const MISSING_PLAN_WARNING_KEY = 'missingPlanWarning';
    const SAVED_FILTERS_KEY = 'savedFilters';
    let isSubscribed = true;
    const [activeResultsTab, setActiveResultsTab] = useState(0);
    const [actionsSearchResults, setActionsSearchResults] = useState<ActionSearchResultItem[]>([]);
    const [businessPlanStructure, setBusinessPlanStructure] = useState<BusinessPlanBusinessDriver[]>();
    const [statusOptions, setStatusOptions] = useState<string[]>([]);
    const [businessDriverOptions, setBusinessDriverOptions] = useState<string[]>([]);
    const [topicOptions, setTopicOptions] = useState<string[]>([]);

    // @ts-ignore
    useEffect(() => {
        setYears(["Any", ...BusinessPlanHelper.getFiscalYearsArray()]);
        BusinessPlanService.getInstance().getStructure().then(response => {
            setBusinessPlanStructure(response.data);
        });
        return () => {
            isSubscribed = false;
        }
    }, []);

    useEffect(() => {
        fetchAreas();
    }, [businessPlanStructure])

    const fetchAreas = (): any => {
        return trackPromise(
            UserService.getInstance().fetchAreas().then(response => {
                if (isSubscribed) {
                    const treeData = response.data.map(x => HierarchyHelper.generateTreeNode(x))
                    setNodes(treeData);

                    const savedFilters = JSON.parse(localStorage.getItem(SAVED_FILTERS_KEY)!);

                    if (savedFilters != null) {
                        setSelectedNodeKeys(savedFilters.nodes);
                        setSelectedQuarter(savedFilters.quarter);
                        setSelectedYear(savedFilters.year);
                        setActiveResultsTab(savedFilters.tab);
                        getSelectedBusinessPlans(savedFilters.nodes, treeData, savedFilters.quarter, savedFilters.year);
                        return;
                    }

                    const selectedNodes: any = {};
                    treeData.forEach(x => {
                        if (x.data === 'area') {
                            selectedNodes[`${x.key}`] = true;
                        }
                    });
                    setSelectedNodeKeys(selectedNodes);
                    getSelectedBusinessPlans(selectedNodes, treeData, selectedQuarter!, selectedYear!); 
                }

            })
        )
    }



    const getSelectedBusinessPlans = (nodeKeys: any, nodeData: any[], quarter: any, year: any) => {

        if (nodeKeys === null || Object.keys(nodeKeys!).length === 0) {
            // @ts-ignore
            toast.current.show({
                severity: 'warn',
                summary: 'Invalid',
                detail: `Please select at least one Business Unit`,
                life: 3000
            });
            return;
        }

        if (quarter === null) {
            // @ts-ignore
            toast.current.show({
                severity: 'warn',
                summary: 'Invalid',
                detail: `Please select a quarter`,
                life: 3000
            });
            return;
        }

        if (year === null) {
            // @ts-ignore
            toast.current.show({
                severity: 'warn',
                summary: 'Invalid',
                detail: `Please select a year`,
                life: 3000
            });
            return;
        }
        
        localStorage.setItem(SAVED_FILTERS_KEY, JSON.stringify({quarter: quarter, year: year, nodes: nodeKeys, tab: activeResultsTab}));
        const selectedNodes: TreeNode[] = [];

        // @ts-ignore
        Object.keys(nodeKeys).forEach(x => {
            selectedNodes.push(searchTree(nodeData, x));
        });
        
        const selectedBusinessUnits: string[] = selectedNodes.map(x => {
            if (x.data === 'area') {
                return x.children!.map(y => y.key!.toString());
            } else {
                return x.key!.toString();
            }
        }).flat();

        const uniqueBusinessUnits: string[] = selectedBusinessUnits.filter((item, index) => selectedBusinessUnits.indexOf(item) === index);

        trackPromise(
            BusinessPlanService.getInstance().getPlansByBusinessUnitsAndPeriod(uniqueBusinessUnits, quarter === "Any" ? null : quarter, year === "Any" ? null : year).then(response => {

                var actionsCollection: ActionSearchResultItem[] = [];
                var businessUnitPlanMap: BusinessUnitPlan[] = [];

                var allTopicOptions: string[] = [];
                var allBusinessDriverOptions: string[] = [];

                response?.data?.forEach(bp => {
                    const businessUnitDetail = searchTree(nodeData, bp.businessUnit);

                    var thisPlan: BusinessUnitPlan = {
                        currentPlan: bp,
                        businessUnit: {businessUnitNumber: businessUnitDetail.key, businessUnitName: businessUnitDetail.label},
                        pendingChanges: BusinessPlanHelper.countPendingChanges(bp)
                    }
                    
                    businessUnitPlanMap.push(thisPlan);

                    bp.actions?.forEach(ac => {
                        let driver = businessPlanStructure?.find(driver => driver.topics.filter(topic => topic.id === ac.topicId).length > 0);
                        let topic = driver?.topics.find((topic) => topic.id === ac.topicId);

                        if (driver != null){
                            allBusinessDriverOptions.push(driver.name);
                        }

                        if (topic != null){
                            allTopicOptions.push(topic.name);
                        }
    
                        actionsCollection.push({
                            driver: driver?.name,
                            topic: topic?.name,
                            action: ac.action,
                            outcome: ac.outcome,
                            dueDate: ac.dueDate,
                            status: ac.status,
                            plan: thisPlan,
                            bu: businessUnitDetail.label
                        })
                    })
                });

                if (showMissingPlanWarning()) {
                    missingResults(response.data, uniqueBusinessUnits, nodeData);
                }
                setSearchResults(businessUnitPlanMap);
                setActionsSearchResults(actionsCollection);
                setStatusOptions(actionsCollection.map(i => i.status).filter((x, i, a) => a.indexOf(x) == i));
                setBusinessDriverOptions(allBusinessDriverOptions.filter((x, i, a) => a.indexOf(x) == i));
                setTopicOptions(allTopicOptions.filter((x, i, a) => a.indexOf(x) == i));
            }) 
        )
    }
    
    const hideMissingPlanWarning = () => {
        localStorage.setItem(MISSING_PLAN_WARNING_KEY, JSON.stringify({value: 'true', timestamp: new Date().getTime()}));
        // @ts-ignore
        toast.current.clear();
    }
    
    const showMissingPlanWarning = () => {
        const storageItem = JSON.parse(localStorage.getItem(MISSING_PLAN_WARNING_KEY)!);
        
        if (!storageItem) {
            return true;
        }
        
        if (differenceInMinutes(new Date(), new Date(storageItem.timestamp)) >= 120) {
            localStorage.removeItem(MISSING_PLAN_WARNING_KEY)
            return true;
        }
        
        return false;
    }
    
    const missingResults = (searchResults: BusinessPlanType[], requestedBusinessUnits: string[], nodeData: any[]) => {
        const searchResultBusinessUnits = searchResults.map(x => x.businessUnit);
        const missing: string[] = requestedBusinessUnits.filter(x => searchResultBusinessUnits.indexOf(x) < 0);
        
        if (missing.length === 0) return;
        
        const missingLabels = (
            <>
                <div className="p-toast-message-content missing-plan-warning"><span className="p-toast-message-icon pi pi-info-circle"/>
                    <div className="p-toast-message-text"><span className="p-toast-summary">Missing Business Plans</span>
                        <div className="p-toast-detail">
                            <p>The following Business Units were missing from the search results</p>
                            <ul> {missing.map(x => {
                                const buObject: TreeNode = searchTree(nodeData, x);
                                return <li key={x}>{buObject.label}</li>;
                            })} </ul>

                            <a role="button" onClick={() => hideMissingPlanWarning()} className="hide-warning-button">Don't show this for a while</a>
                        </div>
                    </div>
                </div>
            </>
        )

        // @ts-ignore
        toast.current.show({
            severity: 'info',
            content: (missingLabels),
            sticky: true
        });
    }

    const searchTree = (nodes: TreeNode[], matchingKey: string): any => {
        const selectedNode = nodes.find(x => x.key === matchingKey);

        if (!selectedNode) {
            for (let n of nodes) {
                for (let i of n.children!) {
                    if (i.key === matchingKey) return i;
                }
            }
        }

        return selectedNode;
    }

    function selectNodeKeys(e: any) {
        setSelectedNodeKeys(e.value);
    }

    function clearFilters() {
        setSelectedQuarter(null);
        setSelectedYear(null);
        setSelectedNodeKeys(null);
        localStorage.removeItem(SAVED_FILTERS_KEY);
        setSearchResults([]);
        setActionsSearchResults([]);
        setActiveResultsTab(0);
    }

    const [actionsTableFilters] = useState({
        'driver': {value: null, matchMode: FilterMatchMode.IN},
        'topic': {value: null, matchMode: FilterMatchMode.IN},
        'action': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'outcome': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'status': {value: null, matchMode: FilterMatchMode.IN},
        'bu': {value: null, matchMode: FilterMatchMode.CONTAINS},
    });

    const renderActionHtml = (row: ActionSearchResultItem) => {
        return <div dangerouslySetInnerHTML={{__html: row.action}}/>;
    }

    const renderOutcomeHtml = (row: ActionSearchResultItem) => {
        return <div dangerouslySetInnerHTML={{__html: row.outcome}}/>;
    }

    const renderDueDate = (row: ActionSearchResultItem) => {
        return format(new Date(row.dueDate), 'dd/MM/yyyy');
    }

    const renderViewBpButton = (row: ActionSearchResultItem) => {
        return <Link to={`/business-plan/current`} state={{planDetail: row.plan, structure: null, readOnly: true, reference: false}} data-testid="plan-tile-href">
                    <button className="app-button pull-right">Open</button>
                </Link>
    }

    const renderStatusFilter = (options: any) => {
        return <MultiSelect
            value={options.value}
            options={statusOptions}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Status"
            className="p-column-filter"
            maxSelectedLabels={1}/>;
    }

    const renderBusinessDriverFilter = (options: any) => {
        return <MultiSelect
            value={options.value}
            options={businessDriverOptions}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Driver"
            className="p-column-filter"
            maxSelectedLabels={1}/>;
    }

    const renderTopicFilter = (options: any) => {
        return <MultiSelect
            value={options.value}
            options={topicOptions}
            onChange={(e) => options.filterApplyCallback(e.value)}
            placeholder="Topic"
            className="p-column-filter"
            maxSelectedLabels={1}/>;
    }

    return (
        <>
            <Toast ref={toast}/>
            <div className="business-plan-explorer">
                <div className="explorer-form">
                    <h2>Business Plan Explorer</h2>
                    <div className="formgroup-inline">
                        <div className="field">
                            <span className="p-float-label">
                                    <TreeSelect
                                        className="inputfield bp-explorer-business-unit"
                                        selectionMode="multiple"
                                        filter
                                        inputId="bp-explorer-business-unit"
                                        display="chip"
                                        metaKeySelection={false}
                                        value={selectedNodeKey}
                                        options={nodes}
                                        onChange={(e: any) => selectNodeKeys(e)}/>
                                    <label htmlFor="bp-explorer-business-unit">Select Business Unit</label>
                            </span>
                        </div>
                        <div className="field">
                           <span className="p-float-label">
                                <Dropdown className="inputfield bp-explorer-quarter" value={selectedQuarter}
                                      options={quarters}
                                          inputId="bp-explorer-quarter"
                                      onChange={(e) => setSelectedQuarter(e.value)}
                                      />
                                <label htmlFor="bp-explorer-quarter">Quarter</label>
                           </span>
                        </div>
                        <div className="field">
                          <span className="p-float-label">
                            <Dropdown inputId="bp-explorer-year" className="inputfield bp-explorer-year" value={selectedYear} options={years}
                                      onChange={(e) => setSelectedYear(e.value)}/>
                              <label htmlFor="bp-explorer-year">Year</label>
                          </span>
                        </div>
                    </div>
                    <div className="field explorer-form-buttons">
                        <button className="app-button mr-2" onClick={() => clearFilters()}>Clear</button>
                        <button className="app-button" onClick={() => getSelectedBusinessPlans(selectedNodeKey, nodes, selectedQuarter, selectedYear)}>Find</button>
                    </div>
                </div>

                {searchResults.length > 0 &&
                        <Fieldset legend="Search Results">
                                <TabView activeIndex={activeResultsTab} onTabChange={(e) => setActiveResultsTab(e.index)}>
                                    <TabPanel header={`Business Plans (${searchResults.length})`}>
                                        <div className="explorer-results">
                                            {searchResults!.map(plan => 
                                            <div className="animate__animated animate__fadeIn" key={plan.currentPlan!.id}>
                                                <PlanTile readOnly={true} planDetail={plan} reference={false} />
                                            </div>)}
                                        </div>
                                    </TabPanel>
                                    <TabPanel header={`Actions (${actionsSearchResults?.length})`}>
                                    {
                                        actionsSearchResults.length > 0
                                            ?
                                                <DataTable value={actionsSearchResults} filters={actionsTableFilters} filterDisplay="row" paginator rows={10} sortMode="multiple" responsiveLayout="scroll" size="large">
                                                    <Column field="bu" header="Business Unit" filter filterPlaceholder="Search by business unit" filterField="bu" headerStyle={{width: '230px'}} sortable />
                                                    <Column field="driver" header="Business Driver" filterElement={renderBusinessDriverFilter} filterField="driver" filter sortable/>
                                                    <Column field="topic" header="Topic" filterElement={renderTopicFilter} filterField="topic" filter sortable/>
                                                    <Column field="action" header="Action" body={renderActionHtml} filter filterPlaceholder="Search by action"/>
                                                    <Column field="outcome" header="Outcome" body={renderOutcomeHtml} filter filterPlaceholder="Search by outcome"/>
                                                    <Column field="dueDate" header="Target Date" body={renderDueDate} sortable/>
                                                    <Column field="status" header="Status" filterElement={renderStatusFilter} filterField="status" filter sortable/>
                                                    <Column field="viewBusinessPlan" header="" body={renderViewBpButton} />
                                                </DataTable>
                                            :
                                                <p>There are no actions within the business plan search results.</p>
                                    }
                                    </TabPanel>
                                </TabView>
                        </Fieldset>
                }


            </div>
        </>


    );
}

interface ActionSearchResultItem {
    bu: string;
    driver: string | undefined;
    topic: string | undefined;
    action: string;
    outcome: string;
    dueDate: string;
    status: string;
    plan: BusinessUnitPlan;
}

export default BusinessPlanExplorer;

