import React from 'react';
import './EmptyMessage.css';

interface EmptyMessageProps {
    message: string;
}

function EmptyMessage(props: EmptyMessageProps) {

    return (
        <div className="empty-message">
            <div className="empty-message-detail">
                <i className="pi pi-info-circle" /> {props.message}
            </div>
        </div>
    )
}

export default EmptyMessage;