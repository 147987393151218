import * as React from 'react';
import {useField} from "formik";

type Props = {
    formField: any;
    renderErrorTextFn: any;
};

export function StrengthsOpportunitiesForm(props: Props) {
    const {
        formField: {
            strengths,
            strengthsExplained,
            opportunities,
            opportunitiesExplained
        }
    } = props;

    const [strengthsField, strengthMeta] = useField(strengths);
    const [strengthsExplainedField, strengthsExplainedMeta] = useField(strengthsExplained);
    const [opportunitiesField, opportunitiesMeta] = useField(opportunities);
    const [opportunitiesExplainedField, opportunitiesExplainedMeta] = useField(opportunitiesExplained);
    const numRows = 7;

    return (
        <div className="strengths-opportunities-form">
            <div className="formgrid grid mt-5 mb-5">
                <div className="field col">
                    <label htmlFor="strengths">{strengths.label}</label>
                    <textarea rows={numRows} id="strengths" {...strengthsField}
                              className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
                    {props.renderErrorTextFn(strengthMeta)}
                </div>
                <div className="field col">
                    <label htmlFor="strengths-why">{strengthsExplained.label}</label>
                    <textarea rows={numRows} id="strengths-why" {...strengthsExplainedField}
                              className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    />
                    {props.renderErrorTextFn(strengthsExplainedMeta)}
                </div>
            </div>
            <div className="formgrid grid mt-5">
                <div className="field col">
                    <label htmlFor="opportunities">{opportunities.label}</label>
                    <textarea rows={numRows} id="opportunities" {...opportunitiesField}
                              className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
                    {props.renderErrorTextFn(opportunitiesMeta)}
                </div>
                <div className="field col">
                    <label htmlFor="opportunities-why">{opportunitiesExplained.label}</label>
                    <textarea rows={numRows} id="opportunities-why" {...opportunitiesExplainedField}
                              className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"
                    />
                    {props.renderErrorTextFn(opportunitiesExplainedMeta)}
                </div>
            </div>
        </div>
        
        
    );
}