import {AxiosResponse} from "axios";
import {Employee} from "./UserModel";
import {httpClient} from "../AuthClient";

export class HierarchyService {
    static myInstance = null;

    static getInstance() {
        return new HierarchyService();
    }

    public fetchUsers(): Promise<AxiosResponse<Employee[]>> {
        return httpClient.get<Employee[]>('/api/Hierarchy/employees');
    }

    public fetchUser(employeeCode: string): Promise<AxiosResponse<Employee>> {
        return httpClient.get<Employee>(`/api/Hierarchy/employee/${employeeCode}`);
    }

    public getAllReports(): Promise<AxiosResponse<Employee[]>> {
        return httpClient.get<Employee[]>('/api/Hierarchy/reports');
    }

    public getAllClinicEmployees(): Promise<AxiosResponse<Employee[]>> {
        return httpClient.get<Employee[]>('/api/Hierarchy/employees/clinic');
    }
}

export default HierarchyService;