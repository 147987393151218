import React from 'react';
import BackButton from "../BackButton";

export enum AlertMessageType {
    INFO = "info",
    WARNING = "warning",
    ERROR = "error"
}

interface AlertMessageProps {
    title?: string;
    message: string;
    type: AlertMessageType;
}

function AlertMessage(props: AlertMessageProps) {

    const className = 'alert-message ' + props.type;

    let typeIconMap = new Map<string, string>([
        [AlertMessageType.INFO, 'pi pi-info-circle'],
        [AlertMessageType.WARNING, 'pi pi-exclamation-triangle'],
        [AlertMessageType.ERROR, 'pi pi pi-exclamation-circle']
    ]);
    
    const titleDefaults = [
        'Dear me!', 'Oh dear!', 'Good gracious!', 'Gee whiz!', 'Golly!', 'Yikes!', 'Wowsers!', 'Jeepers!'
    ]
    
    const getDefaultTitle = () => {
        return titleDefaults[Math.floor(Math.random()*titleDefaults.length)];
    }
    
    return (
        <div className={className}>
            <div className="alert-message-icon">
                <i className={typeIconMap.get(props.type)}/>
            </div>
            <div className="alert-message-title">
                {props.title ? props.title : getDefaultTitle()} 
            </div>
            
            <div className="alert-message-detail">
                {props.message}
            </div>
            
            <BackButton />
        </div>
    )
}

export default AlertMessage;