import React, {useEffect, useState} from 'react';
import {BusinessPlanAction, BusinessPlanActionStatus, BusinessPlanTopic, BusinessUnitPlan} from "./BusinessPlanModel";
import {Dialog} from "primereact/dialog";
import BusinessPlanActionForm from "./BusinessPlanActionForm";
import DateHelper from "../Shared/DateHelper";
import EmptyMessage from "../Shared/EmptyMessage/EmptyMessage";
import BusinessPlanActionItem from "./ActionItem/BusinessPlanActionItem";

interface BusinessPlanActionProps {
    topic: BusinessPlanTopic;
    planDetail: BusinessUnitPlan;
    updatePlanDetailFn: any;
    toast: any;
    readOnly: boolean;
    refreshPlanFn: any;
}

function BusinessPlanActions(props: BusinessPlanActionProps) {

    const [showDialog, setShowDialog] = useState<boolean>(false);
    const [actions, setActions] = useState<BusinessPlanAction[]>([]);

    const [completedActions, setCompletedActions] = useState<BusinessPlanAction[]>([]);
    const [openActions, setOpenActions] = useState<BusinessPlanAction[]>([]);

    let isSubscribed = true;

    // @ts-ignore
    useEffect(() => {
        if (isSubscribed) {
            setCompletedActions(props.planDetail.currentPlan!.actions?.filter(action => action.status === BusinessPlanActionStatus[BusinessPlanActionStatus.COMPLETED]));
            setOpenActions(props.planDetail.currentPlan!.actions?.filter(action => action.status === BusinessPlanActionStatus[BusinessPlanActionStatus.OPEN] || action.status === BusinessPlanActionStatus[BusinessPlanActionStatus.TRANSFERRED])); 
        }

        return () => isSubscribed = false;
        
    }, [props.planDetail.currentPlan, actions]);

    const updateActions = (actions: BusinessPlanAction[]) => {
        setActions(actions);
        props.planDetail.currentPlan!.actions = actions;
        props.updatePlanDetailFn(props.planDetail);
    }

    return (
        <>
            <div className="plan-actions-container" data-testid="plan-actions-container">
                <h2>Open Actions</h2>
                {openActions?.filter(action => action.topicId === props.topic.id).length > 0 &&
                <div className="grid">

                    {openActions?.filter(action => action.topicId === props.topic.id && !action.deleted)
                        .sort((a1, a2) => DateHelper.sortByDate(new Date(a1.dueDate), new Date(a2.dueDate)))
                        .map((action, index) => <div className="col-12 p-align-stretch" key={`open-parent-${index}`}><BusinessPlanActionItem
                            refreshPlanFn={props.refreshPlanFn}
                            readOnly={props.readOnly}
                            key={`open-${index}`}
                            toast={props.toast} action={action} planDetail={props.planDetail}
                            updateActionsFn={updateActions}/></div>)
                    }
                </div>
                }

                {openActions?.filter(action => action.topicId === props.topic.id && !action.deleted).length === 0 &&
                <EmptyMessage message="No actions are currently open for this topic"/>}

                {!props.readOnly &&
                    <button type="button" className="app-button add-action-button"
                            onClick={() => setShowDialog(true)}>Add
                        Action
                    </button>
                }
                <div className="clearfix"/>
                    

                <h2>Completed Actions</h2>
                {completedActions?.filter(action => action.topicId === props.topic.id && !action.deleted).length > 0 &&
                <div className="grid">

                    {completedActions?.filter(action => action.topicId === props.topic.id)
                        .filter(action => action.status === BusinessPlanActionStatus[BusinessPlanActionStatus.COMPLETED])
                        .sort((a1, a2) => DateHelper.sortByDate(new Date(a1.dueDate), new Date(a2.dueDate)))
                        .map((action, index) => <div className="col-6 p-align-stretch" key={`completed-parent-${index}`}><BusinessPlanActionItem
                            refreshPlanFn={props.refreshPlanFn}
                            readOnly={props.readOnly}
                            key={`completed-${index}`}
                            toast={props.toast} action={action} planDetail={props.planDetail}
                            updateActionsFn={updateActions}/></div>)
                    }
                </div>
                }
                {completedActions?.filter(action => action.topicId === props.topic.id).length === 0 &&
                <EmptyMessage message="No actions have been completed for this topic"/>}


                <Dialog header={`Add Action: ${props.topic?.name}`} visible={showDialog} style={{width: '50vw'}}
                        onHide={() => setShowDialog(false)}>
                    <BusinessPlanActionForm toast={props.toast} topic={props.topic} planDetail={props.planDetail}
                                            updateActionsFn={updateActions} showDialogFn={setShowDialog}/>
                </Dialog>
            </div>
        </>
    )
}

export default BusinessPlanActions;