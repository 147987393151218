import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {Message} from "primereact/message";
import {confirmDialog} from "primereact/confirmdialog";
import * as Yup from 'yup';
import {AutoComplete} from "primereact/autocomplete";
import {SelectItem} from "primereact/selectitem";
import {InputText} from "primereact/inputtext";
import {v4} from "uuid";
import {PermissionService} from "./PermissionService";
import {PositionToRole} from "./PermissionModel";
import {Employee} from "../../Shared/UserModel";

interface PositionRoleToForm {
    showDialogFn: Dispatch<SetStateAction<boolean | undefined>>
    updateRolesFn: (role: PositionToRole) => void
    positionTitles: string[];
    employees: Employee[];
    selectedRow: PositionToRole | null;
    existingRoles: PositionToRole[];
}


export const positionToRoleFormValidation = Yup.object().shape({
    name: Yup.string()
        .required('You must provide a name'),
});

function PositionToRoleForm(props: PositionRoleToForm) {

    const [positionTitles, setPositionTitles] = useState<SelectItem[]>();
    const [employees, setEmployees] = useState<SelectItem[]>();
    const [filteredPositionTitles, setFilteredPositionTitles] = useState<SelectItem[]>();
    const [filteredEmployees, setFilteredEmployees] = useState<SelectItem[]>();
    const [selectedPositionTitles, setSelectedPositionTitles] = useState<SelectItem[]>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<SelectItem[]>([]);

    const formik = useFormik({
        initialValues: {
            name: props.selectedRow?.name || '',
            associatedPositionTitles: props.selectedRow?.associatedPositionTitles.map(x => {
                return  {label: x, value: x};
            }),
            associatedEmployeeNumbers: props.selectedRow?.associatedEmployeeNumbers?.map(x => {
                const employee = props.employees.find(y => y.employeeCode === x);
                return {label: `${employee?.preferredName} ${employee?.surname}`, value: employee?.employeeCode};
            }),
        },
        validationSchema: positionToRoleFormValidation,
        onSubmit: (data) => {
            let objectToPersist: PositionToRole;
            if (props.selectedRow) {
                objectToPersist = {...props.selectedRow};
                objectToPersist.name = data.name!;
                objectToPersist.associatedPositionTitles = selectedPositionTitles.map(x => x.value);
                objectToPersist.associatedEmployeeNumbers = selectedEmployees.map(x => x.value);
            } else {
                objectToPersist = {
                    id: v4(),
                    name: data.name!,
                    associatedPositionTitles: selectedPositionTitles.map(x => x.value),
                    associatedEmployeeNumbers: selectedEmployees.map(x => x.value),
                    active: true
                }
            }
           
            PermissionService.getInstance().upsertPositionToRole(objectToPersist).then(response => {
                props.showDialogFn(false);
                props.updateRolesFn(response.data);
            });
        }
    });
    
    function updateSelectedEmployee(value: any) {
       setSelectedEmployees(value.value);
    }
    
    useEffect(() => {
        const mappedTitles = props.positionTitles.map(x => {
           return  {label: x, value: x}; 
        });
        
        setPositionTitles(mappedTitles);
        
        if (props.selectedRow?.associatedPositionTitles) {
            setSelectedPositionTitles(props.selectedRow?.associatedPositionTitles.map(x => {
                return {label: x, value: x}
            }))
        }
    }, [props.positionTitles, props.selectedRow])

    useEffect(() => {
        const mappedEmployees = props.employees?.map(x => {
            return  {label: `${x.preferredName} ${x.surname}`, value: x.employeeCode};
        });
            
        setEmployees(mappedEmployees);

        if (props.selectedRow?.associatedEmployeeNumbers) {
            
            setSelectedEmployees(props.selectedRow.associatedEmployeeNumbers.map(x => {
                const employee = props.employees.find(y => y.employeeCode === x);
                return {label: `${employee?.preferredName} ${employee?.surname}`, value: x}

            }))
        }
    }, [props.employees, props.selectedRow, props.positionTitles])

    
    const searchTitle = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredPositionTitles;
            if (!event.query.trim().length) {
                _filteredPositionTitles = [...positionTitles!];
            }
            else {
                _filteredPositionTitles = positionTitles!.filter((title) => {
                    return title.value.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            
            setFilteredPositionTitles(_filteredPositionTitles!);
        }, 250);
    }

    const searchEmployees = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredEmployees;
            if (!event.query.trim().length) {
                _filteredEmployees = [...employees!];
            }
            else {
                _filteredEmployees = employees!.filter((employee) => {
                    return employee.label?.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredEmployees(_filteredEmployees!);
        }, 250);
    }

    const confirm = () => {
        confirmDialog({
            message: 'This record has not been saved. Are you sure you want to close the record without saving?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                props.showDialogFn(false);
                formik.resetForm()
            },
        });
    }

    return (
        <>

            <form onSubmit={formik.handleSubmit} className="role-form">
                <div className="field">
                    <label htmlFor="nameInput">Role Name</label>
                    <InputText id="nameInput" name="name" value={formik.values.name}
                                   onChange={formik.handleChange} placeholder="Enter the role name e.g. Administrator, Nurse, Doctor"/>
                    {formik.errors.name && formik.touched.name ? (
                        <>
                            <Message severity="warn" text={formik.errors.name}/>
                        </>
                    ) : null}
                </div>
                <div className="field">
                    <label htmlFor="title-input">Associated Position Titles</label>
                    <span className="p-fluid">
                <AutoComplete name="associatedPositionTitles" id="title-input" value={selectedPositionTitles} suggestions={filteredPositionTitles} completeMethod={searchTitle}
                              field="label" multiple onChange={(e) => setSelectedPositionTitles(e.value)}/>
                            </span>
                </div>

                <div className="field">
                    <label htmlFor="employee-input">Associated Employees</label>
                    <span className="p-fluid">
                <AutoComplete name="associatedEmployeeNumbers" id="employee-input" value={selectedEmployees} suggestions={filteredEmployees} completeMethod={searchEmployees}
                              field="label" multiple onChange={(e) => updateSelectedEmployee(e)}/>
                            </span>
                </div>

                <div className="form-buttons">
                    <button type="button" className="app-button" onClick={() => confirm()}>Close</button>
                    <button type="button" className="app-button" onClick={() => formik.resetForm()}>Clear</button>
                    <button type="submit" className="app-button">Add</button>
                </div>
            </form>

        </>


    );
}

export default PositionToRoleForm;
