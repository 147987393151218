import React, {useContext, useEffect, useRef, useState} from "react";
import {BusinessPlanBusinessDriver} from "./BusinessPlanModel";
import {v4} from "uuid";
import {userContext} from '../App';
import {BusinessPlanService} from "./BusinessPlanService";
import DriverManager from "./DriverManager";
import BusinessPlanHelper from "./BusinessPlanHelper";
import {trackPromise} from "react-promise-tracker";
import {Toast} from "primereact/toast";
import AddValueForm from "./AddValueForm";

function PlanStructureManager() {

    const user = useContext(userContext);
    const [drivers, setDrivers] = useState<BusinessPlanBusinessDriver[]>([]);
    const [driverValue, setDriverValue] = useState('');
    const toast = useRef(null);

    useEffect(() => {
        trackPromise(
            BusinessPlanService.getInstance().getStructure(true).then(response => setDrivers(response.data))
        )
    }, []);

    const addDriver = (setLoadingFn: any) => {
        if (!BusinessPlanHelper.isInputValid(driverValue)) {
            // @ts-ignore
            toast.current.show({
                severity: 'warn',
                summary: 'Invalid',
                detail: `Please enter a valid name.`,
                life: 3000
            });
            return;
        }

        const driver: BusinessPlanBusinessDriver = {
            id: v4(),
            name: driverValue,
            active: true,
            order: BusinessPlanHelper.getNextOrderNumber(drivers),
            dateAdded: new Date(),
            addedBy: user.precedaNumber,
            topics: []
        }

        BusinessPlanService.getInstance().upsertDriver(driver).then(response => {
            setDriverValue('');
            setDrivers(prevState => [...prevState ? prevState : [], response.data]);
            setLoadingFn(false);
        })
    }

    return (
        <userContext.Consumer>
            {currentUser => (
                <div>
                    <Toast ref={toast}/>
                    <h2>Manage Business Plan Structure</h2>
                    <div className="drivers-container">
                        {drivers?.map(driver => {
                            return (
                                <DriverManager setDriversFn={setDrivers} key={`${driver.id}-${Math.random()}`} user={currentUser}
                                               businessDriver={driver} toast={toast}/>
                            )
                        })}
                        <div className="driver-item">
                            <div className="add-value-form">
                                <h3>Add Business Driver</h3>
                                <AddValueForm value={driverValue} setValueFn={setDriverValue} onSubmitFn={addDriver}
                                              placeholder="Enter metric name"/>
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </userContext.Consumer>
    )
}

export default PlanStructureManager;