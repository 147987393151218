import {AxiosResponse} from "axios";
import {HierarchyBusinessUnit, User} from "./UserModel";
import {httpClient} from "../AuthClient";

export class UserService {
    static myInstance = null;

    static getInstance() {
        return new UserService();
    }

    public getHeaders(accessToken: string) {
        return {
            'Authorization': `Bearer ${accessToken}`
        }
    }

    public setUser(user: User) {
        localStorage.setItem('user', JSON.stringify(user));
    }

    public getUser() {
        const user = localStorage.getItem('user');
        return JSON.parse(user ? user : '');
    }

    public fetchUser(): Promise<AxiosResponse<User>> {
        return httpClient.get<User>('/api/User');
    }

    public fetchAreas(): Promise<AxiosResponse<HierarchyBusinessUnit[]>> {
        return httpClient.get<HierarchyBusinessUnit[]>('/api/User/areas');
    }

    public fetchPositionTitles(): Promise<AxiosResponse<string[]>> {
        return httpClient.get<string[]>('/api/User/positionTitles');
    }
    
    
}

export default UserService;