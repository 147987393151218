import {AutoComplete} from "primereact/autocomplete";
import React, {useState} from "react";


interface TagDropdownProps {
    value: string;
    tags: string[];
    changeFn: any;
}

function TagDropdown(props: TagDropdownProps) {

    const [filteredTagSuggestions, setFilteredTagSuggestions] = useState<any[]>([]);
    const [value, setValue] = useState<string>(props.value);
    
    const searchSuggestions = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredSuggestions;
            if (!event.query.trim().length) {
                _filteredSuggestions = [...props.tags];
            } else {
                _filteredSuggestions = props.tags.filter((tag) => {
                    return tag.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredTagSuggestions(_filteredSuggestions);
        }, 250);
    }
    
    function changeTag(e: any) {
        setValue(e.value);
        props.changeFn({
            target: {
                name: 'tag',
                value: e.value
            }});
    }
    
    return (
        <AutoComplete name="tag" id="tag-input"
                      data-testid="tag-dropdown"
                      value={value} suggestions={filteredTagSuggestions}
                      completeMethod={(e) => searchSuggestions(e)}
                      dropdown onChange={(e) => changeTag(e)} />
    )
}

export default TagDropdown