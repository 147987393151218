import React from "react";
import {useNavigate} from "react-router-dom";

interface BackButtonProps {
    path?: string;
}

function BackButton(props?: BackButtonProps) {
    let navigate = useNavigate()

    const style = {
        color: '#666',
        cursor: 'pointer',
        fontSize: '20px'
    };

    return (
        <i className="pi pi-arrow-circle-left p-mr-2 back-button" style={style} title="Go Back"
           onClick={() => props!.path ? navigate(props!.path) : navigate(-1)}/>
    )
}

export default BackButton;