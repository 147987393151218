import React from 'react';
import {Menubar} from "primereact/menubar";
import './DotMenu.css';
import {MenuItem} from "primereact/menuitem";

interface DotMenuProps {
    items: MenuItem[];
}

function DotMenu(props: DotMenuProps) {
    
    let menuItems = [
        {
            icon: 'pi pi-ellipsis-h',
            items: props.items
        }
    ];

    return (
        <>
            <div className="dot-menu">
                <Menubar model={menuItems}/>
            </div>
        </>
        
    );
}

export default DotMenu;