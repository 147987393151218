import React, {useContext, useEffect, useState} from 'react';
import {
    BusinessPlanItemStatusType,
    BusinessPlanTopic,
    BusinessPlanType,
    BusinessUnitPlan,
    DirectionalStatement
} from "./BusinessPlanModel";
import {User} from "../Shared/UserModel";
import BusinessUnitService from "../Shared/BusinessUnitService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import DirectionalStatementDialog from "./DirectionalStatementDialog";
import BusinessPlanItemStatusIndicator from "./BusinessPlanItemStatusIndicator";
import {ItemSummary} from "./BusinessPlanSummary";
import BusinessPlanHelper from "./BusinessPlanHelper";
import {v4} from "uuid";
import {BusinessPlanService} from "./BusinessPlanService";
import {userContext} from "../App";

interface DirectionalStatementsProps {
    topic?: BusinessPlanTopic | null;
    planDetail: BusinessUnitPlan;
    toast?: any;
    allowManagement?: boolean;
    showPending?: boolean;
    itemSummary?: ItemSummary[];
    approvedOnly?: boolean;
    readOnly?: boolean;
    itemManagerTemplate?: any;
    manageItemsFn?: any;
    refreshPlanFn?: any;
    isReference: boolean;
}

export interface StatementToUserMap {
    user: User;
    statement: DirectionalStatement | null | undefined;
}


function DirectionalStatements(props: DirectionalStatementsProps) {

    const [userStatements, setUserStatements] = useState<StatementToUserMap[]>([]);
    const [selectedStatementRows, setSelectedStatementRows] = useState<StatementToUserMap[]>([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState<StatementToUserMap>();
    const [formLoading, setFormLoading] = useState<boolean>(false);
    const STAFF_CACHE_KEY = 'buStaff';
    const user = useContext(userContext);
    let isSubscribed = true;

    // @ts-ignore
    useEffect(() => {
            mapStatementsToUser(props.planDetail.currentPlan!);
            return () => isSubscribed = false;
    }, [props.planDetail.currentPlan]);

    function parseStatements(users: User[], currentPlan: BusinessPlanType) {
        const statementMap = users.map(user => {
            const userStatement = currentPlan.directionalStatements ?
                currentPlan.directionalStatements.find(statement => statement.employeeId === user.precedaNumber)
                : null;
            return {user: user, statement: userStatement};
        });

        if (props.showPending) {
            const filteredStatements = statementMap
                .filter(item => item.statement !== undefined)
                .filter(item => BusinessPlanHelper.isItemPending(item.statement!.itemStatusHistory))
            setUserStatements(filteredStatements);
        } else if (props.approvedOnly) {
            const filteredStatements = statementMap
                .filter(item => item.statement !== undefined)
                .filter(item => BusinessPlanHelper.isItemStatusApproved(item.statement!.itemStatusHistory))
            setUserStatements(filteredStatements);
        } else {
            setUserStatements(statementMap);
        }
    }

    const mapStatementsToUser = (currentPlan: BusinessPlanType) => {
        if (props.isReference) {
            
            const staff = BusinessPlanHelper.generateStaffForReferencePlan(currentPlan);
            if (isSubscribed) {
                parseStatements(staff, currentPlan);
            }

        } else {
            const cachedStaff = sessionStorage.getItem(`${STAFF_CACHE_KEY}_${currentPlan.businessUnit}`);

            if (cachedStaff) {
                if (isSubscribed) {
                    parseStatements(JSON.parse(sessionStorage.getItem(`${STAFF_CACHE_KEY}_${currentPlan.businessUnit}`)!), currentPlan);
                }
            } else {
                BusinessUnitService.getBusinessUnitStaff(currentPlan.businessUnit).then(response => {
                    if (isSubscribed) {
                        sessionStorage.setItem(`${STAFF_CACHE_KEY}_${currentPlan.businessUnit}`, JSON.stringify(response.data));
                        parseStatements(response.data, currentPlan); 
                    }
                });
            }
        }
    }
    
    const updateStatements = (plan: BusinessPlanType) => {
        mapStatementsToUser(plan);
    }

    const showCurrentStatus = (row: StatementToUserMap) => {
        if (row.statement) {
            return <BusinessPlanItemStatusIndicator statusHistory={row.statement!.itemStatusHistory}/>
        }
    }

    const statementRowTemplate = (rowData: StatementToUserMap) => {
        return rowData.statement? <div className="pre-line-format" data-testid="statement-row" dangerouslySetInnerHTML={{__html: rowData.statement.statement}} /> : 'No Directional Statement has been created for this team member';
    }

    const statusRowTemplate = (rowData: StatementToUserMap) => {
        return rowData.statement && <BusinessPlanItemStatusIndicator statusHistory={rowData.statement.itemStatusHistory} />;
    }

    const showForm = (rowData: StatementToUserMap) => {
        setSelectedRow(rowData);
        setDisplayDialog(true);
    }
    
    const getButtonName = (rowData: StatementToUserMap) => {
       return rowData.statement ? 'Change' : 'Add';
    }
    
    const handleDirectionalStatementSubmit = (data: any) => {
        setFormLoading(true);
        if (selectedRow!.statement == null) {
            const statementToAdd = {
                id: v4(),
                addedBy: user.precedaNumber,
                dateAdded: new Date(),
                employeeId: selectedRow!.user.precedaNumber,
                statement: data.statement,
                reviewedWithTeamMember: false,
                itemStatusHistory: [
                    {id: v4(), status: BusinessPlanItemStatusType[BusinessPlanItemStatusType.NEW], addedBy: user.precedaNumber, dateAdded: new Date()}
                ],
                itemHistory: [],
                deleted: false
            }
            props.planDetail.currentPlan!.directionalStatements.push(statementToAdd);

        } else {
            const currentState: DirectionalStatement = {...selectedRow!.statement};
            currentState.itemHistory = [];
            const filteredStatements = props.planDetail.currentPlan!.directionalStatements.filter(statement => statement.id !== selectedRow!.statement?.id);

            selectedRow!.statement.statement = data.statement;
            selectedRow!.statement.dateModified = new Date();

            if (selectedRow!.statement.itemStatusHistory == null) selectedRow!.statement.itemStatusHistory = [];
            if (selectedRow!.statement.itemHistory == null) selectedRow!.statement.itemHistory = [];

            if (BusinessPlanHelper.shouldPersistItemChange(selectedRow!.statement.itemStatusHistory)) {
                selectedRow!.statement.itemStatusHistory.push({
                    id: v4(),
                    status: BusinessPlanItemStatusType[BusinessPlanItemStatusType.CHANGED],
                    addedBy: user.precedaNumber,
                    dateAdded: new Date()
                })

                selectedRow!.statement.itemHistory.push(
                    {id: v4(), addedBy: user.precedaNumber, dateAdded: new Date(), stateSnapshot: currentState}
                )
            }

            filteredStatements.push(selectedRow!.statement);
            props.planDetail.currentPlan!.directionalStatements = filteredStatements;
        }

        if (props.planDetail.currentPlan!.directionalStatements == null) props.planDetail.currentPlan!.directionalStatements = [];

        BusinessPlanService.getInstance().upsertBusinessPlan(props.planDetail.currentPlan!).then(response => {
            props.toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: 'Directional statement successfully updated!',
                life: 3000
            });
            
            setDisplayDialog(false)
            updateStatements(response.data);
            props.refreshPlanFn(response.data);
            setFormLoading(false);
        });
    }
    
    
    const approveSelectedStatements = () => {
        if (selectedStatementRows.length === 0) {
            // @ts-ignore
            toast.current.show({
                severity: 'warn',
                summary: 'Whoops!',
                detail: `Please select the insights that you wish to approve.`,
                life: 3000
            });

            return;
        }

        const statementSummaries = selectedStatementRows.map(row => {
            return {driver: null, topic: null, item: row.statement};
        });
        
        props.manageItemsFn(statementSummaries, BusinessPlanItemStatusType.APPROVED, 'directionalStatements');
    }
    
    return (
        <>
            <div className="directional-statements-container" data-testid="directional-statements-container">
                <h2>Directional Statements</h2>

                <div className="card">
                    <DataTable sortMode="multiple" 
                               value={userStatements}
                               responsiveLayout="scroll" 
                               selection={selectedStatementRows}
                               onSelectionChange={e => setSelectedStatementRows(e.value)}>
                        {props.showPending && <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>}
                        <Column field="user.fullName" sortable header="Team Member"/>
                        <Column field="user.title" sortable header="Position Title"/>
                        <Column field="statements" header="Directional Statement" body={statementRowTemplate}/>
                        {!props.readOnly && <Column field="status" header="Approval Status" body={statusRowTemplate}/>}
                        {!props.readOnly && <Column field="actionButton" body={(rowData) => <button data-testid={`statement-dialog-button-${rowData.user.precedaNumber}`} className="app-button" onClick={() => showForm(rowData)}>{getButtonName(rowData)}</button>}/>}
                        {props.showPending && <Column field="status" header="Status" body={showCurrentStatus}/> }
                        {props.showPending && <Column field="statements" header="Manage Item" body={props.itemManagerTemplate}/> }
                    </DataTable>
                </div>
                {(props.allowManagement) &&
                    <div className="summary-approval-button">
                        <button className="app-button" data-testid="approve-selected-statements" onClick={() => approveSelectedStatements()}>Approve
                            Selected
                        </button>
                    </div>
                }
            </div>

            <Dialog maximizable header={`Directional Statement: ${selectedRow?.user.fullName}`} visible={displayDialog}
                    style={{width: '70vw'}} onHide={() => setDisplayDialog(false)}>
                <DirectionalStatementDialog loading={formLoading} handleSubmit={handleDirectionalStatementSubmit}  toggleDialogFn={setDisplayDialog} userStatementData={selectedRow!} />
            </Dialog>
            


        </>
    )
}

export default DirectionalStatements;