import {utcToZonedTime} from "date-fns-tz";

const DateHelper = {

    sortByDate(date1: Date, date2: Date): number {

        if (date1 < date2) {
            return -1;
        }

        if (date1 > date2) {
            return 1;
        }

        return 0;
    },
    
    toLocalTime(date: Date) {
        return utcToZonedTime(date, 'Australia/Brisbane');
    }

  
}

export default DateHelper;
