import * as React from 'react';
import {Panel} from "primereact/panel";
import {SkillConversation} from "../CoachingModel";
import CoachingHelper from "../CoachingHelper";
import {SmartGoalViewer} from "../SmartGoalViewer";
import {User} from "../../Shared/UserModel";

type Props = {
    conversation: SkillConversation;
    updateConversationsFn?: any;
    user: User;
};

export function SkillConversationViewer(props: Props) {
    
    const formModel = CoachingHelper.skillFormModel;
    
    return (
        <div className="skill-conversation-viewer">
            <Panel header="What happened and why?" className="mb-5 skill-panel" toggleable>
                <div className="conversation-response-group skill-container">
                    <div className="field conversation-response">
                        <h3>{formModel.formField.strengths.label}</h3>
                        <p>{props.conversation.strengths}</p>
                    </div>
                    <div className="field conversation-response">
                        <h3>{formModel.formField.strengthsExplained.label}</h3>
                        <p>{props.conversation.strengthsExplained}</p>
                    </div>
                </div>

                <div className="conversation-response-group skill-container">
                    <div className="field conversation-response">
                        <h3>{formModel.formField.opportunities.label}</h3>
                        <p>{props.conversation.opportunities}</p>
                    </div>
                    <div className="field conversation-response">
                        <h3>{formModel.formField.opportunitiesExplained.label}</h3>
                        <p>{props.conversation.opportunitiesExplained}</p>
                    </div>
                </div>
            </Panel>

            <Panel header="Coaching Topic" className="mb-5 topic-panel" toggleable>
                <div className="conversation-response-group topic-container">
                    <div className="field conversation-response">
                        <h3>{formModel.formField.coachingTopic.label}</h3>
                        <p>{props.conversation.coachingTopic}</p>
                    </div>
                    {props.conversation.coachingTopicSkill &&
                        <div className="field conversation-response">
                            <h3>{formModel.formField.coachingTopicSkill.label}</h3>
                            <p>{props.conversation.coachingTopicSkill}</p>
                        </div>
                    }
                </div>
            </Panel>

            <Panel header="SMART Goal" className="smart-goal-panel mb-5" toggleable>
                <div className="conversation-response-group smart-goal-container">
                    <SmartGoalViewer user={props.user} conversation={props.conversation} updateConversationsFn={props.updateConversationsFn} />
                </div>
            </Panel>
        </div>
    );
}

export default SkillConversationViewer;