import * as React from 'react';
import {useField} from "formik";
import SkillConversationViewer from "./SkillConversationViewer";
import {SkillConversation} from "../CoachingModel";
import {User} from "../../Shared/UserModel";

type Props = {
    formField: any;
    user: User;
};

export function SkillCoachingFormSummary(props: Props) {
    const {
        formField: {
            strengths,
            strengthsExplained,
            coachingTopic,
            coachingTopicSkill,
            opportunities,
            opportunitiesExplained,
            smartGoal,
            smartGoalDueDate
        }
    } = props;

    const [strengthsField, ] = useField(strengths);
    const [strengthsExplainedField, ] = useField(strengthsExplained);
    const [opportunitiesField, ] = useField(opportunities);
    const [opportunitiesExplainedField, ] = useField(opportunitiesExplained);
    const [topicField, ] = useField(coachingTopic);
    const [skillField, ] = useField(coachingTopicSkill);
    const [smartGoalField, ] = useField(smartGoal);
    const [smartGoalDueDateField, ] = useField(smartGoalDueDate);
    
    const conversation: SkillConversation = {
        coachingTopic: topicField.value,
        coachingTopicSkill: skillField.value,
        dateAdded: new Date(),
        employeeId: "",
        id: "",
        opportunities: opportunitiesField.value,
        opportunitiesExplained: opportunitiesExplainedField.value,
        smartGoal: {employeeId: "", smartGoalText: smartGoalField.value, dueDate: smartGoalDueDateField.value, id: "", dateAdded: new Date(), status: "OPEN"},
        strengths: strengthsField.value,
        strengthsExplained: strengthsExplainedField.value

    }

    return (
        <div className="coaching-summary-container">
            <h2>Review your conversation</h2>
            <SkillConversationViewer user={props.user} conversation={conversation}  />
        </div>
    );
}