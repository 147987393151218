import React, {useImperativeHandle, useState} from "react";
import {BusinessUnitPlan} from "./BusinessPlanModel";
import {PuffLoader} from "react-spinners";

interface PropsType {
    planDetail: BusinessUnitPlan;
    createPlanFn: any;
    loadingRefFn: any;
}

function EmptyPlanTile(props: PropsType) {

    const planDetail = props.planDetail;
    const [loading, setLoading] = useState(false);
    const [color] = useState("#ffffff");

    useImperativeHandle(
        props.loadingRefFn,
        () => ({
            cancelLoading() {
                setLoading(false);
            }
        }),
    )
    
    const createPlan = () => {
        if (!loading) {
            setLoading(true);
            props.createPlanFn(planDetail.businessUnit); 
        }
    }
    
    return (
        <div>
            <div className="no-plan"  onClick={() => createPlan()} data-testid="emptyPlanTile">
                {
                    loading ?
                        <div className="content">
                            <div className="loading-spinner">
                                <PuffLoader color={color} loading={loading} size={30}/>
                            </div>
                        </div>
                        :
                        <div className="content">
                            <div className="title">{planDetail.businessUnit.businessUnitName}</div>
                            <div className="subtitle">Click to create</div>
                        </div>
                }

            </div>
        </div>
    )
}

export default EmptyPlanTile;