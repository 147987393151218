import React, {useEffect, useState} from "react";
import HierarchyService from "../../Shared/HierarchyService";

function UserList() {

    const [users, setUsers] = useState<any[]>([]);
    
    useEffect(() => {
        HierarchyService.getInstance().fetchUsers().then(response => {
            setUsers(response.data)
        })
    }, [])

    return (
        <>
            <h2>Users</h2>
            {users.map(x => <p key={x.employeeCode}>{x.employeeCode} - {x.preferredName} {x.surname}</p>)} 
        </>

    )
}

export default UserList;