import React, {useContext, useEffect, useRef, useState} from "react";
import {userContext} from "./App";
import {trackPromise} from "react-promise-tracker";
import UserService from "./Shared/UserService";
import {HierarchyBusinessUnit} from "./Shared/UserModel";
import {Toast} from "primereact/toast";
import ActionHub from "./BusinessPlan/ActionHub/ActionHub";
import UserHelper from "./Shared/UserHelper";
import {Permission} from "./Admin/Permissions/PermissionModel";
import {Card} from "primereact/card";

function Dashboard() {

    const toast = useRef(null);
    const user = useContext(userContext);

    const [businessUnitHierarchy, setBusinessUnitHierarchy] = useState<HierarchyBusinessUnit[]>();


    useEffect(() => {
        fetchBusinessUnitHierarchy();
    }, []);


    const fetchBusinessUnitHierarchy = () => {
        trackPromise(
            UserService.getInstance().fetchAreas().then(response => {
                setBusinessUnitHierarchy(response.data);
            })
        )
    }

    return (
        <>
            <Toast ref={toast}/>

            {user?.positionRole === null && 
                <Card title="Why can't I see anything?" className="no-position-error">
                    <i className="pi pi-question-circle" />
                    <p>Your position has not been given permission to access Canvas. Please contact the Service Desk if you require access.</p>
                </Card>
            } 

            {UserHelper.hasPermission(user, Permission.BUSINESS_PLAN_VIEWER) &&
                <ActionHub toast={toast} businessUnitHierarchy={businessUnitHierarchy!} />
            }
        </>

    );
}

export default Dashboard;