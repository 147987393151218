import React, {useEffect, useRef, useState} from 'react';
import {User} from "../Shared/UserModel";
import {Dialog} from "primereact/dialog";
import {ErrorMessage, FormikProvider, useFormik} from "formik";
import * as Yup from "yup";
import {InputText} from "primereact/inputtext";
import './Observations.css';
import {ObservationService} from "./ObservationService";
import {Toast} from "primereact/toast";
import {useLocation} from "react-router";
import BackButton from "../Shared/BackButton";
import {Button} from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";
import {useNavigate} from "react-router-dom";
import {Inplace, InplaceContent, InplaceDisplay} from "primereact/inplace";
import {AutoComplete} from "primereact/autocomplete";
import {InputTextarea} from "primereact/inputtextarea";
import {InputNumber} from "primereact/inputnumber";
import {InputSwitch} from "primereact/inputswitch";
import {Message} from "primereact/message";
import {v4} from "uuid";
import ArrayHelper from "../Shared/ArrayHelper";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Pie} from 'react-chartjs-2';
import randomColor from 'randomcolor';
import {ObservationTemplate, ObservationTemplateStep, ObservationTemplateStepResponse} from "./ObservationsModel";
import TagDropdown from "./TagDropdown";
import 'chart.js/auto';

interface ObservationTemplateEditorProps {
    user: User;
}


function ObservationTemplateEditor(props: ObservationTemplateEditorProps) {
    const location = useLocation();
    const state = location.state as any;
    const [template, setTemplate] = useState<ObservationTemplate>(state.template);
    const [tags, ] = useState<string[]>(state.tags);
    const [templateSteps, setTemplateSteps] = useState<ObservationTemplateStep[]>();
    const [displayNewStepModal, setDisplayNewStepModal] = useState<boolean>();
    const [statusLoading, setStatusLoading] = useState<boolean>();
    const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
    const [deleteStepLoading, setDeleteStepLoading] = useState<boolean>();
    const [newStepFormLoading, setNewStepFormLoading] = useState<boolean>();
    const [tagChangeLoading, setTagChangeLoading] = useState<boolean>(false);
    const [saveEditLoading, setSaveEditLoading] = useState<boolean>();
    const [titleEditActive, setTitleEditActive] = useState<boolean>();
    const [descriptionEditActive, setDescriptionEditActive] = useState<boolean>();
    const [selectedRow, setSelectedRow] = useState<ObservationTemplateStep | null>();
    const [templateName, setTemplateName] = useState<string>(template.name);
    const [templateDescription, setTemplateDescription] = useState<string>(template.description);
    const [tagValue, setTagValue] = useState<string>();
    const [weightsByStage, setWeightsByStage] = useState<any>({labels: [], datasets: []});
    const [showChangeTagModal, setShowChangeTagModal] = useState<boolean>(false);
    const toast = useRef(null);
    let navigate = useNavigate()
    const templateStepValidation = Yup.object().shape({
        stage: Yup.string()
            .required('Stage is a required field'),
        skill: Yup.string()
            .required('Skill is a required field'),
        question: Yup.string()
            .required("Question is a required field"),
        weighting: Yup.number()
            .required("Weighting is a required field").positive().moreThan(0)
    });

    const [stageSuggestions, setStageSuggestions] = useState<any[]>([]);
    const [filteredStageSuggestions, setFilteredStageSuggestions] = useState<any>(null);

    const [skillSuggestions, setSkillSuggestions] = useState<any[]>([]);
    const [filteredSkillSuggestions, setFilteredSkillSuggestions] = useState<any[]>([]);

    const defaultResponses = [
        {
            id: v4(),
            label: 'No/limited Attempt',
            description: '',
            active: true,
            percentageOfWeighting: 0,
            dateAdded: new Date(),
            addedBy: props.user.precedaNumber
        },
        {
            id: v4(),
            label: 'Intermediate Attempt – Limited Effectiveness',
            description: '',
            active: true,
            percentageOfWeighting: 50,
            dateAdded: new Date(),
            addedBy: props.user.precedaNumber
        },
        {
            id: v4(),
            label: 'All Components Demonstrated Effectively',
            description: '',
            active: true,
            percentageOfWeighting: 100,
            dateAdded: new Date(),
            addedBy: props.user.precedaNumber
        }
    ]
    const [stepResponses, setStepResponses] = useState<ObservationTemplateStepResponse[]>([]);

    function calculateStageWeighting() {
        
        const labels = template.steps.filter(x => !x.deleted).map(x => x.stage);
        // @ts-ignore
        const uniqueLabels = [...new Set(labels)];
        const totalWeighting = template.steps.filter(x => !x.deleted).reduce((previous, current) => previous + current.weighting, 0);
        const weightsByStage: number[] = [];
        const colours: string[] = [];
        
        uniqueLabels.map(x => {
            const totalStageWeighting = template.steps
                .filter(y => !y.deleted && y.stage == x)
                .reduce((previous, current) => previous + current.weighting, 0);
            
            weightsByStage.push((totalStageWeighting / totalWeighting) * 100);
            
            colours.push(randomColor({
                hue: 'green',
                format: 'rgb',
                seed: x
            }));
        });
        
        
        setWeightsByStage({
            labels: uniqueLabels,
            datasets: [
                {
                    label: '% of Weighting',
                    data: weightsByStage, 
                    backgroundColor: colours,
                    borderColor: ['rgba(255,255,255,1)'],
                    borderWidth: 2,
                },
            ],
        })
    }

    useEffect(() => {
        setStageSuggestions(Array.from(new Set(template.steps.filter(x => !x.deleted).map(x => x.stage))));
        setSkillSuggestions(Array.from(new Set(template.steps.filter(x => !x.deleted).map(x => x.skill))));
        const orderedFilteredSteps = ArrayHelper.sortByOrder(template.steps).filter(x => !x.deleted);
        setTemplateSteps(orderedFilteredSteps);
        calculateStageWeighting();
    }, [template])

    const searchSuggestions = (event: { query: string }, suggestions: any[], setFilteredFn: any) => {
        setTimeout(() => {
            let _filteredSuggestions;
            if (!event.query.trim().length) {
                _filteredSuggestions = [...suggestions];
            } else {
                _filteredSuggestions = suggestions.filter((stage: any) => {
                    return stage.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
            setFilteredFn(_filteredSuggestions);
        }, 250);
    }

    function toggleActiveStatus() {

        if (!template.active && template.steps.length === 0) {
            // @ts-ignore
            toast.current.show({
                severity: 'warn',
                summary: 'Whoops!',
                detail: `You must add at least one step before activating a template`,
                life: 3000
            });
            return;
        }

        setStatusLoading(true);
        template.active = !template.active;
        template.dateModified = new Date();
        template.modifiedBy = props.user.precedaNumber;

        ObservationService.getInstance().upsertTemplate(template).then(response => {
            // @ts-ignore
            toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: `Template status updated to ${response.data.active ? 'ACTIVE' : 'INACTIVE'}`,
                life: 3000
            });
            setStatusLoading(false);
            setTemplate(response.data);
        });
    }

    function markTemplateAsDeleted() {
        setDeleteLoading(true);
        template.deleted = true;
        template.dateModified = new Date();
        template.modifiedBy = props.user.precedaNumber;

        ObservationService.getInstance().upsertTemplate(template).then(_ => {
            navigate(-1);
        });
    }

    function confirmDeleteTemplate() {
        confirmDialog({
            message: 'Are you sure you want to delete this template?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => markTemplateAsDeleted(),
        });
    }

    function updateName() {
        setSaveEditLoading(true);
        template.name = templateName;
        template.dateModified = new Date();
        template.modifiedBy = props.user.precedaNumber;

        ObservationService.getInstance().upsertTemplate(template).then(response => {
            setTitleEditActive(false);
            setTemplateName(response.data.name);
            setSaveEditLoading(false);
            // @ts-ignore
            toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: `Template name successfully updated`,
                life: 3000
            });
        });
    }

    function updateDescription() {
        setSaveEditLoading(true);
        template.description = templateDescription;
        template.dateModified = new Date();
        template.modifiedBy = props.user.precedaNumber;

        ObservationService.getInstance().upsertTemplate(template).then(response => {
            setDescriptionEditActive(false);
            setTemplateDescription(response.data.description);
            setSaveEditLoading(false);
            // @ts-ignore
            toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: `Template description successfully updated`,
                life: 3000
            });
        });
    }

    function submitNameOnEnter(key: string) {
        if (key === 'Enter') {
            updateName();
        }
    }

    function submitDescriptionOnEnter(key: string) {
        if (key === 'Enter') {
            updateDescription();
        }
    }

    function resetForm() {
        newStepForm.resetForm();
        setNewStepFormLoading(false);
        setStepResponses([]);
        setSelectedRow(null);
    }

    function showNewStepModal() {
        resetForm();
        setStepResponses(getInitialResponses());
        setDisplayNewStepModal(true);
    }
    
    function getInitialResponses() {
        if (templateSteps?.length === 0) {
            return defaultResponses;
        }
        
        const initialResponses = templateSteps![0].responses;
        initialResponses.forEach(x => {
            x.id = v4();
            x.description = ''
        });
        return initialResponses;
    }

    function updateStep(data: any) {
        setNewStepFormLoading(true);
        if (selectedRow) {
            setSelectedRow(prevState => {
                if (prevState) {
                    prevState.stage = data.stage;
                    prevState.skill = data.skill;
                    prevState.question = data.question;
                    prevState.showNotObservable = data.showNotObservable;
                    prevState.showNotes = data.showNotes;
                    prevState.weighting = data.weighting;
                    prevState.dateModified = new Date();
                    prevState.modifiedBy = props.user.precedaNumber;
                    prevState.responses = stepResponses;
                }

                return prevState;
            });

            const filteredSteps = template.steps.filter(x => x.id !== selectedRow.id);
            filteredSteps.push(selectedRow);
            template.steps = filteredSteps;

            ObservationService.getInstance().upsertTemplate(template).then(result => {
                setTemplate(result.data);
                setDisplayNewStepModal(false);
                resetForm();
                setNewStepFormLoading(false);

                // @ts-ignore
                toast.current.show({
                    severity: 'success',
                    summary: 'Success!',
                    detail: `Step successfully updated`,
                    life: 3000
                });
            });
        }
    }

    const newStepForm = useFormik({
        initialValues: {
            stage: '',
            skill: '',
            question: '',
            showNotObservable: false,
            showNotes: true,
            weighting: 0,
            order: null,
        },
        validationSchema: templateStepValidation,
        onSubmit: (data) => {
            
            if (stepResponses.length < 2) {
                // @ts-ignore
                toast.current.show({
                    severity: 'warn',
                    summary: 'Whoops!',
                    detail: `You must have at least two response options for each step`,
                    life: 3000
                });
                return;
            }
            
            const nullLabels = stepResponses.filter(x => x.label == '' || x.label == null);
            
            if (nullLabels.length > 0) {
                // @ts-ignore
                toast.current.show({
                    severity: 'warn',
                    summary: 'Whoops!',
                    detail: `Each response option must have a label`,
                    life: 3000
                });
                return; 
            }
            
            if (selectedRow) {
                // We are updating
                updateStep(data);
            } else {
                setNewStepFormLoading(true);
                const additionalFields = {
                    id: v4(),
                    order: ArrayHelper.getNextOrderNumber(template.steps),
                    deleted: false,
                    addedBy: props.user.precedaNumber,
                    dateAdded: new Date(),
                    responses: stepResponses
                }

                const templateStep: ObservationTemplateStep = {...data, ...additionalFields};


                if (template.steps == null) template.steps = [];
                template.steps.push(templateStep);

                ObservationService.getInstance().upsertTemplate(template).then(result => {
                    setTemplate(result.data);
                    setDisplayNewStepModal(false);
                    newStepForm.resetForm();
                    setNewStepFormLoading(false);
                    // @ts-ignore
                    toast.current.show({
                        severity: 'success',
                        summary: 'Success!',
                        detail: `Step successfully added to template`,
                        life: 3000
                    });
                });
            }
        }
    });

    const stepConfigOptions = (step: ObservationTemplateStep) => {
        return (
            <div className="step-config-options">
                <ul>
                    <li className={`step-config-option ${step.showNotObservable ? 'checked' : 'not-checked'}`}>
                        <div className="step-config-content">
                            Not Obs.
                        </div>
                    </li>
                    <li className={`step-config-option ${step.showNotes ? 'checked' : 'not-checked'}`}>
                        <div className="step-config-content">
                            Notes
                        </div>
                    </li>
                </ul>
            </div>
        )
    }

    function editRow(rowData: any) {
        const step: ObservationTemplateStep = rowData.data;
        setSelectedRow(step);
        newStepForm.setFieldValue('stage', step.stage);
        newStepForm.setFieldValue('skill', step.skill);
        newStepForm.setFieldValue('question', step.question);
        newStepForm.setFieldValue('showNotObservable', step.showNotObservable);
        newStepForm.setFieldValue('showNotes', step.showNotes);
        newStepForm.setFieldValue('weighting', step.weighting);
        setStepResponses(step.responses);
        setDisplayNewStepModal(true);
    }

    function reorderSteps(e: any) {
        const steps = [...templateSteps!];
        steps![e.dragIndex].order = e.dropIndex;
        steps![e.dropIndex].order = e.dragIndex;
        const orderedSteps = ArrayHelper.sortByOrder(steps);
        template.steps = steps;
        setTemplateSteps(orderedSteps);

        ObservationService.getInstance().upsertTemplate(template).then();
    }

    function markStepAsDeleted() {
        if (selectedRow) {
            setDeleteStepLoading(true);
            selectedRow.deleted = true;

            const filteredSteps = template.steps.filter(x => x.id !== selectedRow.id);
            filteredSteps.push(selectedRow);
            template.steps = filteredSteps;

            ObservationService.getInstance().upsertTemplate(template).then(response => {
                setTemplate(response.data);
                setDisplayNewStepModal(false);
                setDeleteStepLoading(false);
                // @ts-ignore
                toast.current.show({
                    severity: 'success',
                    summary: 'Success!',
                    detail: `Step successfully deleted`,
                    life: 3000
                });
            });
        }
    }

    function confirmDeleteStep() {
        confirmDialog({
            message: 'Are you sure you want to delete this step?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => markStepAsDeleted(),
        });
    }

    function calculateStepPercentage(responses: any[]) {
        const onePointScale = (responses.length > 1) ? Math.round((100 / (responses.length - 1))) : 0;
        responses.forEach(function (response, index) {
            responses[index].percentageOfWeighting = (index === responses.length - 1) ? 100 : index * onePointScale;
        });
    }

    function changeResponseHandler(index: number, value: string, field: string) {
        const newResponses = [...stepResponses];
        newResponses[index][field] = value;
        setStepResponses(newResponses);
    }

    function changeTag(data: any) {
        setTagValue(data.target.value);
    }
    
    function submitTagChange() {
        if (!tagValue) {
            return false;
        }
        
        setTagChangeLoading(true);
        template.tag = tagValue;

        ObservationService.getInstance().upsertTemplate(template).then(response => {
            setTemplate(response.data);
            setShowChangeTagModal(false);
            setTagChangeLoading(false);
            // @ts-ignore
            toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: `Tag successfully changed`,
                life: 3000
            });
        });
    }

    function addStepResponse() {
        let responses = [...stepResponses];
        responses.push({
            id: v4(),
            label: '',
            description: '',
            active: true,
            percentageOfWeighting: 0,
            dateAdded: new Date(),
            addedBy: props.user.precedaNumber
        });

        calculateStepPercentage(responses);
        setStepResponses(responses);
    }

    function removeStepResponse(index: number) {
        let responses = [...stepResponses];
        responses.splice(index, 1);
        calculateStepPercentage(responses);
        setStepResponses(responses);
    }

    function moveResponseUp(response: ObservationTemplateStepResponse, index: number) {
        if (index === 0) {
            return;
        }
        
       const responses = [...stepResponses];
       responses.splice(index, 1);
       responses.splice(index - 1, 0, response);
       calculateStepPercentage(responses);
       setStepResponses(responses);
    }

    function moveResponseDown(response: ObservationTemplateStepResponse, index: number) {
        if (index === stepResponses.length) {
            return;
        }

        const responses = [...stepResponses];
        responses.splice(index, 1);
        responses.splice(index + 1, 0, response);
        calculateStepPercentage(responses);
        setStepResponses(responses);
    }

    return (
        <div className="template-editor-container">
            <Toast ref={toast}/>
            <div className="header-group">
                <BackButton/>
                <Inplace disabled={template.active} active={titleEditActive} onToggle={(e) => setTitleEditActive(e.value)}>
                    <InplaceDisplay>
                        <h2>{templateName}</h2>
                    </InplaceDisplay>
                    <InplaceContent>
                        <InputText disabled={template.active} onKeyPress={(e) => submitNameOnEnter(e.key)} value={templateName} autoFocus
                                   onChange={(e) => setTemplateName(e.target.value)} data-testid="edit-title-input"/>
                        <Button icon="pi pi-check" data-testid="save-template-edit" loading={saveEditLoading} onClick={() => updateName()}/>
                    </InplaceContent>
                </Inplace>
                <div className="manage-template-buttons">
                <span className="p-buttonset">
                    <Button className="app-button" onClick={() => setShowChangeTagModal(true)}
                            label={`${template.tag ? `${template.tag}` : 'Add Tag'}`} icon="pi pi-tag"/>
                    <Button loading={statusLoading} className="app-button" onClick={() => toggleActiveStatus()}
                            label={`Make ${template.active ? 'Inactive' : 'Active'}`}
                            icon={`pi ${template.active ? 'pi-times' : 'pi-check'}`}/>
                    <Button disabled={template.active} loading={deleteLoading} className="app-button" onClick={() => confirmDeleteTemplate()}
                            label="Delete" data-testid="delete-template-button" icon="pi pi-trash"/>
                </span>
                </div>
            </div>
            <div className="template-description">
                <Inplace disabled={template.active} active={descriptionEditActive} onToggle={(e) => setDescriptionEditActive(e.value)}>
                    <InplaceDisplay>
                        <p>{templateDescription}</p>
                    </InplaceDisplay>
                    <InplaceContent>
                        <InputText data-testid="edit-description-input" disabled={template.active} onKeyPress={(e) => submitDescriptionOnEnter(e.key)} value={templateDescription} autoFocus
                                   onChange={(e) => setTemplateDescription(e.target.value)}/>
                        <Button data-testid="save-description-edit" icon="pi pi-check" loading={saveEditLoading} onClick={() => updateDescription()}/>
                    </InplaceContent>
                </Inplace>
            </div>

            <div className="step-table">
                <div className="card">
                    <DataTable emptyMessage="No steps have have been added" value={templateSteps}
                               onRowReorder={reorderSteps} responsiveLayout="scroll" onRowClick={editRow}>
                        <Column rowReorder style={{width: '3em'}}/>
                        <Column field="stage" header="Stage" style={{width: '150px'}}/>
                        <Column field="skill" header="Skill" style={{width: '150px'}}/>
                        <Column field="weighting" header="Weighting"/>
                        <Column field="question" header="Question"/>
                        <Column header="" body={stepConfigOptions} style={{width: '250px'}}/>
                    </DataTable>
                </div>
            </div>

            {!template.active && <button className="app-button add-step-button" onClick={() => showNewStepModal()}>Add Step</button>}
            <div className="clearfix"/>
            <Dialog header="Add Step" visible={displayNewStepModal} style={{width: '70vw'}}
                    onHide={() => setDisplayNewStepModal(false)}>
                <FormikProvider value={newStepForm}>
                    <form onSubmit={newStepForm.handleSubmit} className="new-step-form">
                        <div className="field">
                            <label htmlFor="stage-input">Stage</label>
                            <AutoComplete name="stage" id="stage-input" placeholder="Enter the step's stage..."
                                          disabled={template.active}
                                          value={newStepForm.values.stage} suggestions={filteredStageSuggestions}
                                          completeMethod={(e) => searchSuggestions(e, stageSuggestions, setFilteredStageSuggestions)}
                                          dropdown onChange={(e) => newStepForm.handleChange({
                                target: {
                                    name: 'stage',
                                    value: e.value
                                }
                            })}/>
                            {newStepForm.errors.stage && newStepForm.touched.stage ? (
                                <Message severity="warn" text={newStepForm.errors.stage}/>
                            ) : null}
                        </div>

                        <div className="field">
                            <label htmlFor="stage-input">Skill</label>
                            <AutoComplete name="skill" id="stage-input" placeholder="Enter the step's skill..."
                                          disabled={template.active}
                                          value={newStepForm.values.skill} suggestions={filteredSkillSuggestions}
                                          completeMethod={(e) => searchSuggestions(e, skillSuggestions, setFilteredSkillSuggestions)}
                                          dropdown onChange={(e) => newStepForm.handleChange({
                                target: {
                                    name: 'skill',
                                    value: e.value
                                }
                            })}/>
                            {newStepForm.errors.skill && newStepForm.touched.skill ? (
                                <Message severity="warn" text={newStepForm.errors.skill}/>
                            ) : null}
                        </div>
                        <div className="grid">
                            <div className="col-10">
                                <div className="field">
                                    <label htmlFor="question-input">Question</label>
                                    <InputTextarea value={newStepForm.values.question}
                                                   onChange={newStepForm.handleChange}
                                                   rows={7} name="question" id="question-input"
                                                   disabled={template.active}
                                                   placeholder="Enter the question that will be displayed to the observer..."/>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="field">
                                    <label htmlFor="weighting-input">Weighting</label>
                                    <InputNumber name="weighting" placeholder="30" value={newStepForm.values.weighting}
                                                 id="weighting-input" onValueChange={newStepForm.handleChange} step={5}
                                                 mode="decimal" showButtons buttonLayout="vertical"
                                                 disabled={template.active}
                                                 decrementButtonClassName="p-button-secondary"
                                                 incrementButtonClassName="p-button-secondary"
                                                 incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"/>
                                </div>
                            </div>
                        </div>

                        {newStepForm.errors.question && newStepForm.touched.question ? (
                            <Message severity="warn" text={newStepForm.errors.question}/>
                        ) : null}

                        {newStepForm.errors.weighting && newStepForm.touched.weighting ? (
                            <Message severity="warn" text={newStepForm.errors.weighting}/>
                        ) : null}

                        <div className="grid">
                            <div className="col-4">
                                <div className="field aligned-field">
                                    <label htmlFor="not-observable-input">Show 'Not Observable'</label>
                                    <InputSwitch name="showNotObservable" id="not-observable-input"
                                                 disabled={template.active}
                                                 checked={newStepForm.values.showNotObservable}
                                                 onChange={newStepForm.handleChange}/>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="field aligned-field">
                                    <label htmlFor="show-notes-input">Show Notes</label>
                                    <InputSwitch name="showNotes" id="notes-input"
                                                 disabled={template.active}
                                                 checked={newStepForm.values.showNotes}
                                                 onChange={newStepForm.handleChange}/>
                                </div>
                            </div>
                        </div>

                        <div className="step-responses-container">
                            <div className="response-header-group flex align-items-center justify-content-between">
                                <h3 className="m-0 font-bold">Response Options</h3>
                                <Button label="Add Response" type="button" onClick={() => addStepResponse()} />
                            </div>
                            <div className="step-response-list">
                                {stepResponses?.map((response, index) => (
                                    <div className="grid step-response-form" key={index}>
                                        <div className="col-1 flex align-items-center">
                                            <div className="order-arrows mx-auto">
                                                <i data-testid="move-response-up-arrow" className="pi pi-arrow-up mb-4" onClick={() => moveResponseUp(response, index)} />
                                                <i data-testid="move-response-down-arrow" className="pi pi-arrow-down" onClick={() => moveResponseDown(response, index)}/>
                                                
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="field">
                                                <label htmlFor={`responses.${index}.label`}>Label</label>
                                                <InputTextarea rows={5} className="response-input"
                                                               value={response?.label} name={`responses.${index}.label`}
                                                               placeholder="Enter a label"
                                                               disabled={template.active}
                                                               onChange={(e) => changeResponseHandler(index, e.target.value, 'label')}/>
                                                <ErrorMessage
                                                    name={`responses.${index}.label`}
                                                    component="div"
                                                    className="field-error"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-4">
                                            <div className="field">
                                                <label htmlFor={`responses.${index}.description`}>Description</label>
                                                <InputTextarea className="response-input" value={response?.description}
                                                               name={`responses.${index}.description`}
                                                               placeholder="Enter a description"
                                                               disabled={template.active}
                                                               rows={5}
                                                               onChange={(e) => changeResponseHandler(index, e.target.value, 'description')}/>
                                                <ErrorMessage
                                                    name={`responses.${index}.description`}
                                                    component="div"
                                                    className="field-error"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-2">
                                            <div className="field" style={{textAlign: 'center'}}>
                                                <label htmlFor={`responses.${index}.percentageOfWeighting`}>% of
                                                    Weighting</label>
                                                <span className="response-input-weighting">{response.percentageOfWeighting}</span>
                                                <ErrorMessage
                                                    name={`responses.${index}.percentageOfWeighting`}
                                                    component="div"
                                                    className="field-error"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-1 flex align-items-center">
                                            <div className="field" style={{textAlign: 'center'}}>
                                                <i className="pi pi-times remove-response-button" data-testid="remove-response-button" onClick={() => removeStepResponse(index)} /> 
                                            </div> 
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {!template.active &&
                                <div className='flex justify-content-end mt-2 mb-5'>
                                    <Button label="Add Response" type="button" onClick={() => addStepResponse()}/>
                                </div>
                            }
                        </div>

                        {!template.active &&
                            <div className="form-buttons">
                                {!newStepForm.isValid && newStepForm.submitCount > 0 && (
                                    <Message severity="warn" text="Your form has validation errors. Please correct them." />

                                )}
                                {selectedRow &&
                                    <Button type="button" className="app-button danger" loading={deleteStepLoading}
                                            onClick={() => confirmDeleteStep()} label="Delete"/>
                                }
                                {!selectedRow &&
                                    <button type="button" className="app-button"
                                            onClick={() => resetForm()}>Clear</button>
                                }
                                <Button type="submit" className="app-button" loading={newStepFormLoading}
                                        label={selectedRow ? 'Update' : 'Save'}/>
                            </div>
                        }

                    </form>
                </FormikProvider>
            </Dialog>
            {template.steps.length > 0 &&
                <div className="grid mt-3">
                    <div className="col-6">
                        <h4 className="weighting-chart-title">Weighting by Stage</h4>
                    </div>
                    <div className="col-6">
                        <div className="weighting-chart-container">
                            <Pie data={weightsByStage}/>
                        </div>
                    </div>
                </div>
            }

            <Dialog header={template.tag ? 'Change Tag' : 'Add Tag'} visible={showChangeTagModal} onHide={() => setShowChangeTagModal(false)}>
                <TagDropdown value={tagValue!} tags={tags} changeFn={changeTag} />
                <div className="mt-3 flex">
                    <Button label="Submit" className="app-button block ml-auto" onClick={() => submitTagChange()} loading={tagChangeLoading} />
                </div>
            </Dialog>
        </div>
    )


}

export default ObservationTemplateEditor;