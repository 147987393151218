import React, {useState} from 'react';
import './Observations.css';
import {Employee, User} from "../Shared/UserModel";
import {InputTextarea} from "primereact/inputtextarea";
import {v4} from "uuid";
import {
    ObservationResult,
    ObservationStepResult,
    ObservationTemplateStep, ObservationTemplateStepCompact,
    ObservationTemplateStepResponse
} from "./ObservationsModel";

interface TemplateStepProps {
    step: ObservationTemplateStep;
    updateResultsFn: any;
    candidate: Employee;
    observer: User;
}

function TemplateStep(props: TemplateStepProps) {
    
    const [selectedResponse, setSelectedResponse] = useState<ObservationTemplateStepResponse | null>()
    const [notes, setNotes] = useState<string | null>()
    const [notObserved, setNotObserved] = useState<boolean>(false)

    function selectResponse(response: ObservationTemplateStepResponse) {
        setNotObserved(false);
        setSelectedResponse(response); 
        const result: ObservationStepResult = {
            dateAdded: new Date(),
            notObserved: notObserved,
            notes: response.notes,
            scoreLabel: response.label,
            scorePercent: response.percentageOfWeighting,
            id: v4(),
            stepDetails: new ObservationTemplateStepCompact(props.step)
        }
        
        props.updateResultsFn(result);
    }

    function selectNotObserved() {
        setSelectedResponse(null);
        setNotObserved(true);
        
        const result: ObservationStepResult = {
            dateAdded: new Date(),
            notObserved: true,
            notes: '',
            scoreLabel: 'Not observed',
            scorePercent: 0,
            id: v4(),
            stepDetails: new ObservationTemplateStepCompact(props.step)
        }

        props.updateResultsFn(result);
    }

    function handleNotesChange(note: string) {
       const response = selectedResponse;
       response!.notes = note;
       selectResponse(response!);
    }

    return (
        <div className='observation-step-container'>
            <div className="skill">{props.step.skill}</div>
            <div className="stage">{props.step.stage}</div>
            
            <div className="observation-step-content">
                <div className="question">{props.step.question}</div>

                <div className="step-response-options">
                    {props.step.showNotObservable &&
                        <div className={`step-response-option not-observable ${notObserved ? 'selected' : ''}`} onClick={() => selectNotObserved()}>
                            <span>Unable to observe</span>
                        </div>
                    }
                    {props.step.responses.map(x => {
                        return (
                            <div key={x.id} className={`step-response-option ${selectedResponse === x ? 'selected' : ''}`} onClick={() => selectResponse(x)}>
                                <span>{x.label}</span>
                            </div>
                        )

                    })}
                </div>

                {(selectedResponse && selectedResponse.description != '') &&
                    <div className="step-response-description">
                        {selectedResponse.description}
                    </div>
                }

                {(selectedResponse && props.step.showNotes) &&
                    <InputTextarea
                        placeholder="Add notes..."    
                        className="step-notes"
                        rows={5}
                        onChange={(e) =>  handleNotesChange(e.target.value)} />
                } 
            </div>

        </div>
    )


}

export default TemplateStep;