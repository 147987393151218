import React, {useEffect, useState} from 'react';
import BusinessPlanInsightsForm from "./BusinessPlanInsightsForm";
import {BusinessPlanType, BusinessPlanInsight, BusinessPlanTopic, BusinessUnitPlan} from "./BusinessPlanModel";
import BusinessPlanInsightItem from "./BusinessPlanInsightItem";
import EmptyMessage from "../Shared/EmptyMessage/EmptyMessage";

interface BusinessPlanInsightsProps {
    topic: BusinessPlanTopic;
    planDetail: BusinessUnitPlan;
    updatePlanDetailFn: any;
    toast: any;
    readOnly: boolean;
    refreshPlanFn: any;
}

function BusinessPlanInsights(props: BusinessPlanInsightsProps) {
    
    const [insights, setInsights] = useState<BusinessPlanInsight[]>([]);
    useEffect(() => {
        setInsights(props.planDetail.currentPlan!.insights.sort(sortByDate));
    }, [props.planDetail.currentPlan, insights]);
    
    const sortByDate = (item1: BusinessPlanInsight, item2: BusinessPlanInsight) => {
        const item1Date = new Date(item1.dateAdded);
        const item2Date = new Date(item2.dateAdded);

        if (item1Date < item2Date) {
            return 1;
        }

        if (item1Date > item2Date) {
            return -1;
        }

        return 0; 
    }
    
    const updateInsights = (plan: BusinessPlanType) => {
        setInsights(plan.insights.sort(sortByDate));
        props.planDetail.currentPlan = plan;
        props.updatePlanDetailFn(props.planDetail);
    }
    
    return (
        <>
            <div className="plan-insights-container" data-testid="plan-insights-container">
                <h2>Insights</h2>

                {insights?.filter(insight => insight.topicId === props.topic.id).length === 0 &&
                    <EmptyMessage message="No insights are currently active for this topic"/>}
                
                    {insights.filter(insight => insight.topicId === props.topic.id && !insight.deleted) 
                        .map(insight => <BusinessPlanInsightItem refreshPlanFn={props.refreshPlanFn} readOnly={props.readOnly} toast={props.toast} key={insight.id} planDetail={props.planDetail} insight={insight} updateInsightsFn={updateInsights} />)}

                
                {!props.readOnly &&
                    <BusinessPlanInsightsForm topic={props.topic} planDetail={props.planDetail} updateInsightsFn={updateInsights} toast={props.toast} />
                }
            </div>

        </>
    )
}

export default BusinessPlanInsights;