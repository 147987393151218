import React, {useEffect, useState} from "react";
import {ItemSummary} from "./BusinessPlanSummary";
import {BusinessPlanBusinessDriver, BusinessPlanTopic, BusinessUnitPlan} from "./BusinessPlanModel";
import DirectionalStatements from "./DirectionalStatements";
import {format} from "date-fns";

interface PrintableSummaryProps {
    actions: ItemSummary[];
    insights: ItemSummary[];
    planDetail: BusinessUnitPlan;
    structure: BusinessPlanBusinessDriver[];
}

interface PrintableStructure {
    actions: ItemSummary[];
    insights: ItemSummary[];
    driver: BusinessPlanBusinessDriver;
    topic: BusinessPlanTopic;
}

export const PrintableSummary = React.forwardRef((props: PrintableSummaryProps, ref) => {

    const [groupedItems, setGroupedItems] = useState<PrintableStructure[]>([]);
    const groupByTopic = (list: ItemSummary[]) => list.reduce((hash: any, obj: any) => ({
        ...hash,
        [obj['topic']['name']]: (hash[obj['topic']['name']] || []).concat(obj)
    }), {});

    useEffect(() => {
        const actions = groupByTopic(props.actions);
        const insights = groupByTopic(props.insights);
        const groupedItems: PrintableStructure[] = [];

        props.structure?.forEach(driver => {
            driver.topics.forEach(topic => {
                if (topic.hasActions && topic.hasInsights) {
                    groupedItems.push({
                        actions: actions[topic.name],
                        insights: insights[topic.name],
                        driver: driver,
                        topic: topic
                    });
                }
            })
        });

        setGroupedItems(groupedItems);
    }, [props.actions, props.insights, props.structure]);

    return (
        // @ts-ignore
        <div ref={ref} className="plan-print-container">
            <h1 className="printable-header">{props.planDetail.businessUnit.businessUnitName} -
                FY{props.planDetail.currentPlan!.fiscalYear} Q{props.planDetail.currentPlan!.fiscalQuarter}</h1>



                {groupedItems.map((structureItem, index) => (
                    <>
                        <h2> {structureItem.topic.name} ({structureItem.driver.name})</h2>
                        {(structureItem.actions?.length === undefined && structureItem.insights?.length === undefined) &&
                            <p>No content</p>
                        }
                        {structureItem.insights?.length > 0 &&

                            <div className="printable-insights-container">
                                <h4 className="topic-title">Insights</h4>
                                {
                                    structureItem.insights?.map((x, index) => (
                                        <>
                                            <div key={`in-${index}`} className="printable-insight"
                                                 dangerouslySetInnerHTML={{__html: x.item.insightText}}/>
                                        </>
                                    ))
                                }
                            </div>
                        }

                        {structureItem.actions?.length > 0 &&

                            <div className="printable-actions-container">
                                <h4 className="topic-title">Actions</h4>
                                {structureItem.actions?.map((x, index) => (
                                    <>
                                        <div className="printable-action" key={`ac-${index}`}>
                                            <h4>Action</h4>
                                            <div className="action-text"
                                                 dangerouslySetInnerHTML={{__html: x.item.action}}/>
                                            <h4>Outcome</h4>
                                            <div className="outcome-text"
                                                 dangerouslySetInnerHTML={{__html: x.item.outcome}}/>
                                            <div className="grid">
                                                <div className="col">
                                                    <h4>Target Date</h4>
                                                    <div className="target-date-text"
                                                         dangerouslySetInnerHTML={{__html: format(new Date(x.item.dueDate), 'dd/MM/yyyy')}}/>
                                                </div>
                                                <div className="col">
                                                    <h4>Status</h4>
                                                    <div className="status-text"
                                                         dangerouslySetInnerHTML={{__html: x.item.status}}/>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))
                                }
                            </div>
                        }
                    </>
                ))
            }


            {props.planDetail.currentPlan!.directionalStatements.length > 0 &&
                <div className="printable-directional-statements">
                    <DirectionalStatements isReference={false} approvedOnly={false} planDetail={props.planDetail}
                                           allowManagement={false} readOnly={true}/>
                </div>
            }
        </div>
    );
});