import React, {useContext, useEffect, useRef, useState} from 'react';
import './BusinessPlan.css';
import '../Shared/AlertMessage/AlertMessage.css';
import {v4} from "uuid";
import {BusinessPlanAction, BusinessPlanActionStatus, BusinessPlanType, BusinessUnitPlan} from "./BusinessPlanModel";
import {BusinessPlanService} from "./BusinessPlanService";
import {FiscalDateHelper} from "../Shared/FiscalDateHelper";
import {BusinessUnit, HierarchyBusinessUnit, User} from "../Shared/UserModel";
import PlanTile from "./PlanTile";
import EmptyPlanTile from "./EmptyPlanTile";
import {Toast} from 'primereact/toast';
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import BusinessPlanHelper from "./BusinessPlanHelper";
import BusinessPlanExplorer from "./BusinessPlanExplorer";
import UserService from "../Shared/UserService";
import HierarchyHelper from "../Shared/HierarchyHelper";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Link} from "react-router-dom";
import {Card} from "primereact/card";
import {MultiSelect} from "primereact/multiselect";
import {FilterMatchMode} from "primereact/api";
import UserHelper from "../Shared/UserHelper";
import {Permission} from "../Admin/Permissions/PermissionModel";
import {Accordion, AccordionTab} from "primereact/accordion";
import DateHelper from "../Shared/DateHelper";
import {Dialog} from "primereact/dialog";
import BusinessPlanActionItem from "./ActionItem/BusinessPlanActionItem";
import {Fieldset} from "primereact/fieldset";

interface Props {
    user: User;
}

function BusinessPlanDashboard(props: Props) {

    const [currentPlans, setCurrentPlans] = useState<BusinessUnitPlan[]>([]);
    const [selectedActions, setSelectedActions] = useState<BusinessPlanAction[]>([]);
    const [displayOutstandingActionsModal, setDisplayOutstandingActionsModal] = useState<boolean>(false);
    const [lastPlan, setLastPlan] = useState<BusinessUnitPlan>();
    const [outstandingActions, setOutstandingActions] = useState<BusinessPlanAction[]>([]);
    const [pastPlans, setPastPlans] = useState<BusinessUnitPlan[]>([]);
    const [areaData, setAreaData] = useState<HierarchyBusinessUnit[]>();
    const [referencePlan, setReferencePlan] = useState<BusinessUnitPlan>();
    const fiscalDateHelper = new FiscalDateHelper(new Date());
    const toast = useRef(null);
    const [filters, setFilters] = useState({
        'businessUnit.businessUnitName': {value: null, matchMode: FilterMatchMode.IN},
        'currentPlan.fiscalQuarter': {value: null, matchMode: FilterMatchMode.IN},
        'currentPlan.fiscalYear': {value: null, matchMode: FilterMatchMode.IN},
    });
    const loadingRef = useRef();
    let isSubscribed = true;

    // @ts-ignore
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
        fetchReferencePlan();
        
        return () => isSubscribed = false;
    }, []);

    const fetchReferencePlan = () => {
        BusinessPlanService.getInstance()
            .getPlansByBusinessUnitsAndPeriod([BusinessPlanHelper.REFERENCE_PLAN_BUSINESS_UNIT], BusinessPlanHelper.REFERENCE_PLAN_QUARTER, BusinessPlanHelper.REFERENCE_PLAN_YEAR)
            .then(response => {
                if (isSubscribed) {
                    if (response.data.length === 1) {
                        const planDetail = {
                            businessUnit: BusinessPlanHelper.createReferenceBusinessUnitObject(),
                            currentPlan: response.data[0],
                            pendingChanges: 0
                        }
                        setReferencePlan(planDetail);
                    } 
                }

            })
    }

    const fetchData = (): any => {
        trackPromise(
            UserService.getInstance().fetchAreas().then(response => {
                if (isSubscribed) {
                    setAreaData(response.data);
                    fetchPlans(response.data); 
                }

            })
        )
    }
    const {promiseInProgress} = usePromiseTracker();

    const fetchPlans = (areaData: HierarchyBusinessUnit[]): any => {
        if (!canViewExplorer()) {
            setCurrentPlans([]);
            setPastPlans([]);
            trackPromise(
                BusinessPlanService.getInstance().getPlansForCurrentUser().then(response => {
                    const plans: BusinessPlanType[] = response.data;
                    if (UserHelper.isAreaManager(props.user)) {
                        props.user.availableBusinessUnits = props.user.availableBusinessUnits.filter(x => x.businessUnitNumber !== props.user.assignedBusinessUnit.businessUnitNumber);
                    }

                    props.user.availableBusinessUnits = props.user.availableBusinessUnits.filter((value, index, self) =>
                            index === self.findIndex((t) => (
                                t.businessUnitNumber === value.businessUnitNumber
                            ))
                    )

                    props.user.availableBusinessUnits?.forEach(availableBu => {
                        const plansByBusinessUnit = plans?.filter(plan => plan.businessUnit === availableBu.businessUnitNumber);
                        const currentPlan = plansByBusinessUnit.find(plan => plan.fiscalYear === fiscalDateHelper.getYear() && plan.fiscalQuarter === fiscalDateHelper.getQuarter())
                        if (!currentPlan) {
                            const planBuObject = {
                                businessUnit: availableBu,
                                currentPlan: null,
                                pendingChanges: 0,
                                parent: HierarchyHelper.getParentBusinessUnit(areaData!, availableBu.businessUnitNumber)
                            };

                            setCurrentPlans(prev => [...prev, planBuObject]);
                        }

                        plansByBusinessUnit.forEach(x => {
                            const planBuObject = {
                                businessUnit: availableBu,
                                currentPlan: x,
                                pendingChanges: BusinessPlanHelper.countPendingChanges(x),
                                parent: HierarchyHelper.getParentBusinessUnit(areaData!, x.businessUnit)
                            };
                            BusinessPlanHelper.isPlanCurrent(x) ? setCurrentPlans(prev => [...prev, planBuObject]) : setPastPlans(prev => [...prev, planBuObject]);
                        });
                    });
                })
            )
        }
    }

    const businessPlanPreCheck = (businessUnit: BusinessUnit) => {
        const previousPlan = pastPlans
            .filter(x => x.businessUnit === businessUnit)
            .sort((b1, b2) => DateHelper.sortByDate(new Date(b1.currentPlan!.dateAdded), new Date(b2.currentPlan!.dateAdded)))[0];

        if (!previousPlan) {
            createBusinessPlan(businessUnit);
            return;
        }

        const incompleteActions = deep(previousPlan?.currentPlan!.actions
            .filter(x => !x.deleted)
            .filter(x => x.status === BusinessPlanActionStatus[BusinessPlanActionStatus.OPEN]));

        if (incompleteActions.length > 0) {
            incompleteActions.forEach(x => {
                x.copyCount = ++x.copyCount! || 0;
                x.isCopied = true;
            });
            
            setOutstandingActions(incompleteActions);
            setLastPlan(previousPlan);
            setDisplayOutstandingActionsModal(true);
        } else {
            createBusinessPlan(businessUnit);
        }
    }

    function deep<T extends object>(source: T): T {
        return JSON.parse(JSON.stringify(source))
    }

    const closeOutstandingActionDialog = () => {
        // @ts-ignore
        loadingRef.current.cancelLoading();
        setSelectedActions([]);
        setDisplayOutstandingActionsModal(false);
    }

    const copyActionsAndCreatePlan = () => {
        
        if (selectedActions.length === 0) {
            
        }
        
        setLastPlan(prevState => prevState?.currentPlan?.actions.forEach(lastPlanAction => {
            if (selectedActions.some(selectedAction => selectedAction.id === lastPlanAction.id)) {
                lastPlanAction.status = BusinessPlanActionStatus[BusinessPlanActionStatus.TRANSFERRED];
                lastPlanAction.statusUpdatedDate = new Date();
                return lastPlanAction;
            }
        })!);

        BusinessPlanService.getInstance().upsertBusinessPlan(lastPlan?.currentPlan!).then(_ => {
            if (isSubscribed) {
                setDisplayOutstandingActionsModal(false);
                createBusinessPlan(lastPlan?.businessUnit!, selectedActions); 
            }
        });
    }

    const createBusinessPlan = (businessUnit: BusinessUnit, actionsToCopy?: BusinessPlanAction[]) => {
        const plan = {
            id: v4(),
            businessUnit: businessUnit.businessUnitNumber,
            fiscalYear: fiscalDateHelper.getYear(),
            fiscalQuarter: fiscalDateHelper.getQuarter(),
            active: true,
            dateAdded: new Date(),
            addedBy: props.user.precedaNumber,
            directionalStatements: [],
            insights: [],
            actions: actionsToCopy ? actionsToCopy : [],
        }

        BusinessPlanService.getInstance().upsertBusinessPlan(plan).then((response) => {
            // @ts-ignore
            toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: `Business plan created for ${businessUnit.businessUnitName}`,
                life: 3000
            });
            const planDetail = currentPlans.find(planDetail => planDetail.businessUnit.businessUnitNumber === response.data.businessUnit);
            if (planDetail) {
                const index = currentPlans.indexOf(planDetail);
                currentPlans[index] = {businessUnit: businessUnit, currentPlan: response.data}
                setCurrentPlans([...currentPlans]);
            }
        })
    }

    const canViewExplorer = () => {
        return UserHelper.hasPermission(props.user, Permission.BUSINESS_PLAN_EXPLORER_VIEWER);
    }

    const addToSelectedActions = (action: BusinessPlanAction) => {
        if (selectedActions.includes(action)) {
            const newActions = selectedActions.filter(x => x !== action);
            setSelectedActions(newActions);
        } else {
            setSelectedActions([...selectedActions, action]);
        }
    }

    const isActionSelected = (action: BusinessPlanAction): boolean => {
        return selectedActions.some(x => action.id === x.id);
    }

    const viewPlanTemplate = (options: BusinessUnitPlan) => {
        return (
            <Link to={`/business-plan/current`}
                  state={{planDetail: options, structure: null, readOnly: false, reference: false}}>
                <button type="button" className="app-button">View</button>
            </Link>)
    }

    const fiscalYears = BusinessPlanHelper.getFiscalYearsArray();

    const clinicRowFilterTemplate = (options: any) => {
        const clinicsForPastPlans: any = pastPlans
            .map(x => {
                return {value: x.businessUnit.businessUnitName, name: x.businessUnit.businessUnitName}
            })
            .filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.name === value.name && t.value === value.value
                    ))
            )

        return <MultiSelect value={options.value} options={clinicsForPastPlans}
                            onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="name" placeholder="Any"
                            className="p-column-filter" maxSelectedLabels={1}/>;
    }

    const quarterRowFilterTemplate = (options: any) => {
        return <MultiSelect value={options.value} options={[1, 2, 3, 4].map(x => {
            return {value: x, label: x}
        })}
                            onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="label" placeholder="Any"
                            className="p-column-filter" maxSelectedLabels={1}/>;
    }

    const yearRowFilterTemplate = (options: any) => {
        return <MultiSelect value={options.value} options={fiscalYears.map(x => {
            return {value: x, label: x}
        })}
                            onChange={(e) => options.filterApplyCallback(e.value)} optionLabel="label" placeholder="Any"
                            className="p-column-filter" maxSelectedLabels={1}/>;
    }

    if (canViewExplorer()) {
        return (<>
            <BusinessPlanExplorer/>
        </>);
    } else {
        return (
            <>
                <Toast ref={toast}/>
                <div className="grid">
                    <div className="lg:col-7 md:col-12 sm:col-12">
                        {!promiseInProgress &&
                            <Card className="plans-card-container"
                                  title={`Current Plan${currentPlans.length > 1 ? 's' : ''}: FY${fiscalDateHelper.getYear()} Q${fiscalDateHelper.getQuarter()}`}
                                  subTitle={props.user.assignedBusinessUnit?.businessUnitName}>
                                <div>
                                    <div className={"business-plan-list"}>
                                        {currentPlans?.map((planDetail: any, index) =>
                                            planDetail.currentPlan ?
                                                <PlanTile readOnly={false} key={planDetail.businessUnit.businessUnitNumber}
                                                          planDetail={planDetail} reference={false}/>
                                                : <EmptyPlanTile key={index} planDetail={planDetail}
                                                                 createPlanFn={businessPlanPreCheck} loadingRefFn={loadingRef} />
                                        )}
                                    </div>
                                    {referencePlan &&
                                        <Accordion className="reference-plan-accordion">
                                            <AccordionTab header="Best Practice Example">
                                                <div className="reference-plan-container">
                                                    <PlanTile readOnly={true} key={referencePlan?.businessUnit.businessUnitNumber}
                                                              planDetail={referencePlan!} reference={true}/>
                                                </div>
                                            </AccordionTab>
                                        </Accordion>
                                    }
                                </div>
                            </Card>
                        }

                    </div>
                    <div className="lg:col-5 md:col-12 sm:col-12">
                        {!promiseInProgress &&
                            <Card title="Past Plans" subTitle={props.user.assignedBusinessUnit?.businessUnitName}
                                  className="plans-card-container">
                                <div className="past-plans">
                                    <div className={"business-plan-list"}>
                                        <DataTable responsiveLayout="scroll" paginator rows={8} sortMode="multiple"
                                                   filters={filters}
                                                   value={pastPlans} className="past-plans-list">
                                            <Column filter showFilterMatchModes={false}
                                                    filterField="businessUnit.businessUnitName"
                                                    filterElement={clinicRowFilterTemplate}
                                                    headerStyle={{width: '300px'}} field="businessUnit.businessUnitName"
                                                    header="Clinic" sortable/>
                                            <Column filter showFilterMatchModes={false}
                                                    filterField="currentPlan.fiscalQuarter"
                                                    filterElement={quarterRowFilterTemplate}
                                                    field="currentPlan.fiscalQuarter" header="Quarter" sortable/>
                                            <Column filter showFilterMatchModes={false}
                                                    filterField="currentPlan.fiscalYear"
                                                    filterElement={yearRowFilterTemplate} field="currentPlan.fiscalYear"
                                                    header="Fiscal Year" sortable/>
                                            <Column field="currentPlan.id" body={viewPlanTemplate}/>
                                        </DataTable>
                                    </div>
                                </div>
                            </Card>
                        }
                    </div>
                </div>
                <Dialog header="Manage Outstanding Actions" visible={displayOutstandingActionsModal}
                        style={{width: '80%'}}
                        onHide={() => closeOutstandingActionDialog()}>
                    <p>The following actions exist in the previous plan and are not yet completed. Outstanding actions
                        must either be completed, or transferred to a new plan.</p>
                    <p>Select the actions below that you wish to transfer to the new plan.</p>
                    <div className="outstanding-actions-list">
                        <Fieldset legend="Outstanding Actions (click to select)">
                            <div className="grid">
                                {outstandingActions?.map(x =>
                                    <div className="col-4" key={x.id}>
                                        <div
                                            className={`action-select-container ${isActionSelected(x) ? 'selected' : ''}`}
                                            onClick={() => addToSelectedActions(x)}>
                                            <BusinessPlanActionItem action={x} planDetail={lastPlan!}
                                                                    updateActionsFn={() => {
                                                                    }} toast={toast} readOnly={true}
                                                                    refreshPlanFn={() => {
                                                                    }}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </Fieldset>
                    </div>
                    <div className="outstanding-action-buttons">
                        <button className="app-button" onClick={() => closeOutstandingActionDialog()}>Cancel
                        </button>
                        <button className="app-button" onClick={() => copyActionsAndCreatePlan()}>Copy Selected and
                            Continue
                        </button>
                    </div>

                </Dialog>
            </>
        )
            ;
    }

}

export default BusinessPlanDashboard;

