import React, {useRef, useState} from 'react';
import './Observations.css';
import {useLocation} from "react-router";
import {Employee, User} from "../Shared/UserModel";
import TemplateStep from "./TemplateStep";
import {confirmDialog} from "primereact/confirmdialog";
import {useNavigate} from "react-router-dom";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {ObservationResult, ObservationStepResult, ObservationTemplate} from "./ObservationsModel";
import {v4} from "uuid";
import {ObservationService} from "./ObservationService";
import {usePrompt} from "../Shared/useBlocker";

interface PerformObservationProps {
    user: User;
}

function PerformObservation(props: PerformObservationProps) {
    const toast = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as any;
    const [candidate, setCandidate] = useState<Employee>(state.candidate);
    const [template, setTemplate] = useState<ObservationTemplate>(state.template);
    const [responses, setResponses] = useState<ObservationStepResult[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isDirty, setIsDirty] = useState<boolean>(false);

    function confirmClose() {
        confirmDialog({
            message: 'Are you sure you want to cancel this observation?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => cancelObservation()
        });
    }
    
    usePrompt("Are you sure you want to cancel this observation?", isDirty);
    
    function cancelObservation() {
        setIsDirty(false);
        submitObservation(true, '/observation');
    }
    
    function updateResponses(result: ObservationStepResult) {
        setIsDirty(true);
        const filtered = responses?.filter(x => x.stepDetails?.id !== result.stepDetails?.id);
        filtered.push(result);
        setResponses(filtered);
    }

    function submitObservation(isCancelled = false, navigateAfterSubmitPath = '/observation/view') {
        
        if (!isCancelled) {
            const notObservedCount = responses.filter(x => x.notObserved).length;
            if (responses.filter(x => !x.notObserved).length !== (template.steps.filter(i => i.deleted === false).length - notObservedCount)) {
                // @ts-ignore
                toast.current.show({
                    severity: 'warn',
                    summary: 'Unable to submit',
                    detail: `Please provide a response to all questions.`,
                    life: 3000
                });

                return;
            } 
        }
        
        setLoading(true);
        setIsDirty(false);

        const observationResult: ObservationResult = {
            candidate: candidate.employeeCode,
            dateAdded: new Date(),
            datePublished: undefined,
            id: v4(),
            isCancelled: isCancelled,
            observer: props.user.precedaNumber,
            published: false,
            responses: responses,
            templateId: template.id,
            templateName: template.name,
            templateTag: template.tag,
            templateDescription: template.description,
            isDeleted: false
        }
        
        ObservationService.getInstance().upsertObservationResult(observationResult).then(response => {
            navigate(navigateAfterSubmitPath, {state: {result: response.data}})
        })
    }

    return (
        <div className='perform-observation-container'>
            <Toast ref={toast} />
            <div className="header">
                <div className="template-name">{template.name}</div>
                <div className="template-description">{template.description}</div>
                <div className="candidate">
                    {candidate.preferredName} {candidate.surname} | {candidate.fullTitle} | {candidate.businessUnitName}
                </div>
                <i className="pi pi-times-circle close-icon" data-testid="close-observation" onClick={() => confirmClose()} />
            </div>
            
            <div className="observation-steps-container">
                {template.steps.filter(i => i.deleted === false)?.map(x => <TemplateStep key={x.id} step={x} updateResultsFn={updateResponses} candidate={candidate} observer={props.user} />)}
            </div>
            
            <Button label="Submit" loading={loading} onClick={() => submitObservation()} className="app-button submit-observation-button" />
        </div>
    )
}

export default PerformObservation;