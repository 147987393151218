import * as React from 'react';
import {useField} from "formik";
import {Calendar} from "primereact/calendar";

type Props = {
    formField: any;
    renderErrorTextFn: any;
};

export function SmartGoalForm(props: Props) {
    const {
        formField: {
            smartGoal,
            smartGoalDueDate
        }
    } = props;

    const [smartGoalField, smartGoalMeta] = useField(smartGoal);
    const [smartGoalDueDateField, smartGoalDueDateMeta] = useField(smartGoalDueDate);
    
    return (
        <div className="smart-goal-form-container">
            <div className="field smart-goal-field">
                <h2>What is your SMART goal?</h2>
                <textarea rows={10} {...smartGoalField} data-testid="smart-goal-input"
                          className="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full"/>
                {props.renderErrorTextFn(smartGoalMeta)}
            </div>
            <div className="field">
                <h2>Timeframe / Due date</h2>
                <Calendar {...smartGoalDueDateField} inline showWeek minDate={new Date()} />
                {props.renderErrorTextFn(smartGoalDueDateMeta)}
            </div>
        </div>
    );
}