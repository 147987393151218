import {InputText} from "primereact/inputtext";
import React, {Dispatch, SetStateAction, useState} from "react";
import {PuffLoader} from "react-spinners";


interface AddValueFormProps {
    value: string
    setValueFn: Dispatch<SetStateAction<string>>;
    onSubmitFn: any;
    placeholder: string;
}

function AddValueForm(props: AddValueFormProps) {

    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    const onSubmit = () => {
        setButtonLoading(true);
        props.onSubmitFn(setButtonLoading);
    }

    const handleKeypress = (e: any) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <div className="input-button-container">
            <InputText placeholder={props.placeholder} value={props.value} onKeyPress={handleKeypress}
                       onChange={(e) => props.setValueFn(e.target.value)}/>

            {buttonLoading ?
                <div className="driver-item-spinner">
                    <PuffLoader loading={buttonLoading} size={30}/>
                </div>
                :
                <i className="pi pi-plus icon-button" data-testid="add-value-submit-button" onClick={() => onSubmit()}/>
            }
        </div>
    )
}

export default AddValueForm;