import {AxiosResponse} from "axios";
import {PermissionToRole, PositionToRole} from "./PermissionModel";
import {httpClient} from "../../AuthClient";


export class PermissionService {
    static myInstance = null;

    static getInstance() {
        return new PermissionService();
    }

    baseUrl = 'api/permission';

    public fetchPermissions(): Promise<AxiosResponse<string[]>> {
        return httpClient.get<string[]>(`${this.baseUrl}`);
    }
    
    public upsertPositionToRole(positionToRole: PositionToRole): Promise<AxiosResponse<PositionToRole>> {
        return httpClient.post<PositionToRole>(`${this.baseUrl}/positionToRole`, positionToRole);
    }

    public fetchPositionToRoles(): Promise<AxiosResponse<PositionToRole[]>> {
        return httpClient.get<PositionToRole[]>(`${this.baseUrl}/positionToRole`);
    }

    public deletePositionToRole(id: string): Promise<AxiosResponse<PositionToRole[]>> {
        return httpClient.delete<PositionToRole[]>(`${this.baseUrl}/positionToRole/${id}`);
    }
    
    public upsertPermissionToRole(permissionToRole: PermissionToRole): Promise<AxiosResponse<PermissionToRole>> {
        return httpClient.post<PermissionToRole>(`${this.baseUrl}/permissionToRole`, permissionToRole);
    }

    public fetchPermissionToRoles(): Promise<AxiosResponse<PermissionToRole[]>> {
        return httpClient.get<PermissionToRole[]>(`${this.baseUrl}/permissionToRole`);
    }

    public deletePermissionToRole(id: string): Promise<AxiosResponse<PermissionToRole[]>> {
        return httpClient.delete<PermissionToRole[]>(`${this.baseUrl}/permissionToRole/${id}`);
    }
}