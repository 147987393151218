import {Fieldset} from "primereact/fieldset";
import React from "react";
import ConfigTile from "./ConfigTile";
import './ConfigurationManager.css';
import UserHelper from "../../Shared/UserHelper";
import {Permission} from "../Permissions/PermissionModel";
import {User} from "../../Shared/UserModel";

interface ConfigurationManagerProps {
    user: User;
}

function ConfigurationManager(props: ConfigurationManagerProps) {

    const canViewConfigureBusinessPlan = (): boolean => {
        return UserHelper.hasPermission(props.user, Permission.BUSINESS_PLAN_CONFIGURATOR);
    }

    return (
        <>
            <h2>Configuration</h2>
            <div className="configuration-manager-container">
                <div className="grid">
                    {canViewConfigureBusinessPlan() &&
                        <div className="col-4">
                            <Fieldset legend="Business Plan">
                                <ConfigTile path="/business-plan/manage" icon="pi-list" name="Topics"/>
                                <ConfigTile path="/business-plan/manage/reference" icon="pi-clone"
                                            name="Reference Plan"/>
                            </Fieldset>
                        </div>
                    }
                    <div className="col-3">
                        <Fieldset legend="Observations">
                            <ConfigTile path="/observation/templates" icon="pi-file" name="Templates"/>
                        </Fieldset> 
                    </div>
                </div>



            </div>
        </>
        
    )
}

export default ConfigurationManager;