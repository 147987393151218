import {ObservationResult, ObservationStepResult} from "./ObservationsModel";
import {addDays, isAfter} from "date-fns";

export const ObservationHelper = {

    calculateOverallScore(observationResult: ObservationResult) {
        let total = 0;
        let score = 0;
        observationResult.responses.filter(x => !x.notObserved).forEach(x => {
            total += x.stepDetails.weighting;
            score += this.percentage(x.stepDetails.weighting, x.scorePercent);
        });

        let overall = Math.round((score / total) * 100)
        return {pointsScore: score, percentageScore: overall, totalPoints: total}
    },

    percentage(num: number, percentage: number) {
        return (num / 100) * percentage;
    },

    isOverdueForPublishing(observation: ObservationResult, daysThreshold: number) {
        const overdueDate = addDays(new Date(observation.dateAdded), daysThreshold);
        return isAfter(new Date(), overdueDate);
    },

    calculateScoreByStage(observation: ObservationResult) {
        function calculateScoreValue(response: ObservationStepResult) {
            return response.stepDetails.weighting * (response.scorePercent / 100)
        }

        const scoreByStage: any[] = [];

        observation.responses.forEach(x => {
            if (scoreByStage[x.stepDetails.stage] == undefined) {
                scoreByStage[x.stepDetails.stage] = {
                    totalWeighting: x.stepDetails.weighting,
                    totalScore: calculateScoreValue(x)
                }
            } else {
                scoreByStage[x.stepDetails.stage].totalWeighting += x.stepDetails.weighting;
                scoreByStage[x.stepDetails.stage].totalScore += calculateScoreValue(x);
            }

        });

        return Object.keys(scoreByStage).map(x => {
            return {
                stage: x,
                totalWeighting: scoreByStage[x].totalWeighting,
                totalScore: scoreByStage[x].totalScore,
                scorePercent: (scoreByStage[x].totalScore / scoreByStage[x].totalWeighting) * 100
            }
        });

    },
}