import React, {useEffect, useState} from "react";
import {ObservationResult} from "./ObservationsModel";
import {Chart} from "primereact/chart";
import randomColor from "randomcolor";

interface ObservationStageResultGraphProps {
    result: ObservationResult;
}

function ObservationStageResultGraph(props: ObservationStageResultGraphProps) {
   
    const [chartData, setChartData] = useState<any>();
    
    useEffect(() => {
        const values: any[] = [];
        const labels: string[] = [];
        const colours: any[] = [];
        props.result.responses.forEach(x => {
            labels.push(x.stepDetails.stage);
            if (values[x.stepDetails.stage] == undefined) {
                values[x.stepDetails.stage] = 0
            }
            values[x.stepDetails.stage] += x.stepDetails.weighting * (x.scorePercent / 100);
            colours.push(randomColor({
                hue: 'green',
                format: 'rgb',
                seed: x.stepDetails.stage
            }));
        });

        setChartData({
            labels: Array.from(new Set(labels)),
            datasets: [
                {
                    data: Object.values(values),
                    backgroundColor: Array.from(new Set(colours)),
                    hoverBackgroundColor: colours,
                }]
        });
        
    }, [props.result]);

    const [lightOptions] = useState({
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                bodyFont: {
                    size: 10
                },
            }
        }
    });

    
    return (
        <>
            <Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: 'relative', width: '75%' }} />
        </>
    )
}

export default ObservationStageResultGraph;