import {format} from 'date-fns'

export enum Month {
    JANUARY = 1,
    FEBRUARY = 2,
    MARCH = 3,
    APRIL = 4,
    MAY = 5,
    JUNE = 6,
    JULY = 7,
    AUGUST = 8,
    SEPTEMBER = 9,
    OCTOBER = 10,
    NOVEMBER = 11,
    DECEMBER = 12
}

export class FiscalDateHelper {
    private readonly FIRST_FISCAL_MONTH = 7;
    private readonly LAST_FISCAL_MONTH = this.FIRST_FISCAL_MONTH - 1;
    private readonly QUARTER_ONE = [Month.JULY, Month.AUGUST, Month.SEPTEMBER];
    private readonly QUARTER_TWO = [Month.OCTOBER, Month.NOVEMBER, Month.DECEMBER];
    private readonly QUARTER_THREE = [Month.JANUARY, Month.FEBRUARY, Month.MARCH];
    private readonly QUARTER_FOUR = [Month.APRIL, Month.MAY, Month.JUNE];

    private readonly date: Date;

    constructor(date: Date) {
        this.date = date;
    }

    getQuarter() {
        const month = +format(this.date, 'M');
        if (this.QUARTER_ONE.includes(month)) {
            return 1;
        } else if (this.QUARTER_TWO.includes(month)) {
            return 2;
        } else if (this.QUARTER_THREE.includes(month)) {
            return 3;
        } else {
            return 4;
        }
    }

    getYear() {
        const month = +format(this.date, 'M');
        const year = +format(this.date, 'y');

        return month >= this.FIRST_FISCAL_MONTH ? year + 1 : year;
    }

    static getFiscalPeriodString(quarter: number, year: number) {
        return `FY${year} Q${quarter}`;
    }
}
