import * as React from 'react';
import {ObservationTemplate} from "../../Observations/ObservationsModel";
import {useEffect, useState} from "react";
import {Dropdown} from "primereact/dropdown";
import {useField} from "formik";

type Props = {
    formField: any;
    topics: ObservationTemplate[];
    renderErrorTextFn: any;
};

export function CoachingTopicForm(props: Props) {
    const {
        formField: {
            coachingTopic,
            coachingTopicSkill
        }
    } = props;

    const [topics, setTopics] = useState<{ label: string, id: string }[]>([]);
    const [skills, setSkills] = useState<string[]>([]);
    const [topicField, topicMeta] = useField(coachingTopic);
    const [skillField, skillMeta] = useField(coachingTopicSkill);
    const [skillsFieldVisible, setSkillsFieldVisible] = useState<boolean>(false);
    const nonTemplateDropdownOptions = [{label: 'Leadership', id: 'Leadership'}];

    useEffect(() => {
        if (topics.length === 0) {
            setTopics([...props.topics.map(x => {
                return {label: x.name, id: x.name}
            }), ...nonTemplateDropdownOptions]);
        }

        // This populates the skill field if we have a pre-selected value coming from other tabs
        if (topicField.value) {
            const obj = {
                target:
                    {
                        "name": "coachingTopic",
                        "id": null,
                        "value": topicField.value
                    },
                value: topicField.value
            }
            selectCoachingTopic(obj);
        }

    }, [props.topics]);

    function selectCoachingTopic(e: any) {
        const selectedTopic = props.topics.find(x => x.name === e.value)!;
        
        if (selectedTopic?.steps.length > 0) {
            setSkills(selectedTopic.steps.map(x => x.skill))
            setSkillsFieldVisible(true);
        } else {
            setSkillsFieldVisible(false);
            skillField.value = null;
            skillField.onChange({
                target:
                    {
                        "name": "coachingTopicSkill",
                        "id": null,
                        "value": null
                    },
                value: null
            })
        }
        
        topicField.onChange(e);
    }

    return (
        <div className="coaching-topic-container">
            
            <div className="field">
                <h2>What were you coached on today?</h2>
                <Dropdown options={topics} optionLabel="label" optionValue="id" onChange={(e) => selectCoachingTopic(e)}
                          name={topicField.name} value={topicField.value}/>
                {props.renderErrorTextFn(topicMeta)}
            </div>

            {skillsFieldVisible &&
                <div className="field">
                    <h2>What specific skill are you focused on?</h2>
                    <Dropdown options={skills} {...skillField} />
                    {props.renderErrorTextFn(skillMeta)}
                </div>
            }
        </div>
    );
}