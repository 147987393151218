import {Employee} from "../Shared/UserModel";

export interface SkillConversation {
   id: string;
   employeeId: string;
   strengths: string;
   strengthsExplained: string;
   opportunities: string;
   opportunitiesExplained: string;
   coachingTopic: string;
   coachingTopicSkill: string;
   smartGoal: SmartGoal;
   dateAdded: Date;
   employeeDetails?: Employee;
}

export interface SmartGoal {
    id: string;
    smartGoalText: string;
    dueDate: Date;
    employeeId: string;
    achieved?: Date | null;
    dateAdded: Date;
    status: string;
}

export enum SmartGoalStatus {
    OPEN,
    COMPLETED
}