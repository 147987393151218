import React, {useEffect, useState} from 'react';

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.min.css";
import 'primeicons/primeicons.css';
import 'animate.css';
import './App.css';

import {EventType} from "@azure/msal-browser";
import {BrowserRouter} from 'react-router-dom';
import SignIn from "./SignIn/Signin";
import {PuffLoader} from "react-spinners";
import Layout from "./Layout";
import {User} from "./Shared/UserModel";
import UserService from "./Shared/UserService";
import {msalApiScopes} from "./Shared/AuthConfig";
import {msalInstance} from "./AuthClient";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";

const userContext = React.createContext(new User());
export {userContext};

function App() {

    const [loading, setLoading] = useState(false);
    const [currentUser, setCurrentUser] = useState<User>(new User());

    async function setUserContext() {
        setLoading(true);
        await UserService.getInstance().fetchUser().then(user => {
            setLoading(false);
            if (user.data.precedaNumber == null) {
                return;
            }
            setCurrentUser(user.data)
            setLoading(false);
        });
    }

    const doSignIn = async () => {
        await msalInstance.loginRedirect({scopes: msalApiScopes});
    }

    const doSignOut = async () => {
        await msalInstance.logoutPopup({account: msalInstance.getActiveAccount()});
    }

    useEffect(() => {
        msalInstance.addEventCallback((event) => {
            // @ts-ignore
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
                // @ts-ignore
                const account = event.payload.account;
                msalInstance.setActiveAccount(account);
                setUserContext();
            }
        });
        
        let accounts = msalInstance.getAllAccounts();
        if (accounts != null && accounts.length === 1) {
            setUserContext();
        }
        // eslint-disable-next-line 
    }, []);
    
    return (
        <>
            <AuthenticatedTemplate>
                <div className="App">
                    <BrowserRouter>
                        <userContext.Provider value={currentUser}>
                            {loading ?
                                <div className="loading-spinner">
                                    <PuffLoader loading={loading} size={150}/>
                                </div>
                                :
                                <Layout fnSignOut={doSignOut}/>}
                        </userContext.Provider>
                    </BrowserRouter>
                </div> 
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <SignIn fnSignIn={doSignIn}/>
            </UnauthenticatedTemplate>

        </>
    );
}

export default App;
