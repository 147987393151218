import React, {useEffect, useState} from "react";
import UserService from "../../Shared/UserService";
import {Dialog} from "primereact/dialog";
import PositionToRoleForm from "./PositionToRoleForm";
import './PermissionManager.css';
import {Permission, PermissionToRole, PositionToRole} from "./PermissionModel";
import {PermissionService} from "./PermissionService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Chip} from "primereact/chip";
import {TabPanel, TabView} from "primereact/tabview";
import {Card} from "primereact/card";
import {AutoComplete} from "primereact/autocomplete";
import {v4} from "uuid";
import HierarchyService from "../../Shared/HierarchyService";
import {Employee} from "../../Shared/UserModel";
import AreaChart from "./AreaChart";

function PermissionManager() {

    const [positionTitles, setPositionTitles] = useState<string[]>();
    const [employees, setEmployees] = useState<Employee[]>();
    const [selectedRoles, setSelectedRoles] = useState<PositionToRole[]>();
    const [filteredRoles, setFilteredRoles] = useState<any[]>();
    const [displayRoleModal, setDisplayRoleModal] = useState<boolean>();
    const [displayPermissionModal, setDisplayPermissionModal] = useState<boolean>();
    const [roles, setRoles] = useState<PositionToRole[]>([]);
    const [selectedRow, setSelectedRow] = useState<PositionToRole | null>(null);
    const [selectedPermission, setSelectedPermission] = useState<PermissionToRole >();
    const [permissionToRoles, setPermissionToRoles] = useState<PermissionToRole[]>();

    function fetchRoles() {
        PermissionService.getInstance().fetchPositionToRoles().then(x => setRoles(x.data))
    }

    function fetchPermissions() {
        PermissionService.getInstance().fetchPermissions().then(x => {
            const masterPermissionList = x.data;
            PermissionService.getInstance().fetchPermissionToRoles().then(x => {
                const permissionToRoles = x.data;
                const permissionMapping: PermissionToRole[] = masterPermissionList.map(masterPermission => {
                    const existingMapping = permissionToRoles.find(x => Permission[Permission[x.permission]] === masterPermission);
                    return {
                        id: existingMapping?.id,
                        permission: Permission[masterPermission],
                        associatedRoles: existingMapping?.associatedRoles!
                    }
                });
                setPermissionToRoles(permissionMapping);
            });
        });
    }

    useEffect(() => {
        UserService.getInstance().fetchPositionTitles().then(x => setPositionTitles(x.data));
        // @ts-ignore
        HierarchyService.getInstance().fetchUsers().then(x => setEmployees(x.data.result));
        fetchPermissions();
        fetchRoles();
    }, []);

    const updateRoles = () => {
        fetchRoles();
    }

    const showDialogForEdit = (row: PositionToRole) => {
        setSelectedRow(row);
        setDisplayRoleModal(true);
    }

    const positionTitleTemplate = (row: PositionToRole) => {
        return (
            <>
                {row.associatedPositionTitles.map(x => <Chip key={x} onRemove={(e) => onTitleRemove(row, x)} label={x}
                                                             removable/>)}
            </>

        )
    }

    const employeeTemplate = (row: PositionToRole) => {
        return (
            <>
                {row.associatedEmployeeNumbers?.map(x => {
                    const employee = employees?.find(y => y.employeeCode === x);
                    return <Chip key={`employee-${x}`} onRemove={(e) => onEmployeeRemove(row, x)} label={`${employee?.preferredName} ${employee?.surname}`}
                                 removable/>
                    }
                )}
            </>

        )
    }

    const roleButtonTemplate = (row: PositionToRole) => {
        return <button className="app-button" onClick={() => showDialogForEdit(row)}><i className="pi pi-plus" /></button>
    }
    
    const permissionTemplate = (row: PermissionToRole) => {
        return Permission[row.permission];
    }

    const onTitleRemove = (row: PositionToRole, chip: string) => {
        row.associatedPositionTitles = row.associatedPositionTitles.filter(x => x !== chip);

        if (row.associatedPositionTitles.length === 0 && row.associatedEmployeeNumbers.length === 0) {
            PermissionService.getInstance().deletePositionToRole(row.id).then(response => {
                setRoles(prevState => prevState?.filter(x => x !== row));
            });
            return;
        }

        PermissionService.getInstance().upsertPositionToRole(row).then(response => {
            setRoles(prevState => [...prevState?.filter(x => x !== row), row])
        });
    }

    const onEmployeeRemove = (row: PositionToRole, chip: string) => {
        row.associatedEmployeeNumbers = row.associatedEmployeeNumbers.filter(x => x !== chip);

        if (row.associatedEmployeeNumbers.length === 0 && row.associatedPositionTitles.length === 0) {
            PermissionService.getInstance().deletePositionToRole(row.id).then(response => {
                setRoles(prevState => prevState?.filter(x => x !== row));
            });
            return;
        }

        PermissionService.getInstance().upsertPositionToRole(row).then(response => {
            setRoles(prevState => [...prevState?.filter(x => x !== row), row])
        });
    }

    const onPermissionRemove = (row: PermissionToRole, chip: any) => {
        row.associatedRoles = row.associatedRoles.filter(x => x !== chip);

        PermissionService.getInstance().upsertPermissionToRole(row).then(response => {
            setPermissionToRoles(prevState => [...prevState!.filter(x => x !== row), row])
        });
    }

    const newRoleModal = () => {
        setSelectedRow(null);
        setDisplayRoleModal(true);
    }

    const showAddRole = (row: PermissionToRole) => {
        setSelectedPermission(row);
        setSelectedRoles(row.associatedRoles);
        setDisplayPermissionModal(true);
    }

    const permissionButtonTemplate = (row: PermissionToRole) => {
        return <button className="add-role-button app-button" onClick={() => showAddRole(row)}>Add Role</button>
    }

    const searchRoles = (event: { query: string }) => {
        setTimeout(() => {
            let _filteredRoles
            if (!event.query.trim().length) {
                _filteredRoles = [...roles!];
            } else {
                _filteredRoles = roles!.filter((role) => {
                    return role.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }

            setFilteredRoles(_filteredRoles);
        }, 250);
    }

    const savePermission = () => {

        const permission: PermissionToRole = {
            id: selectedPermission?.id ? selectedPermission?.id : v4(),
            // @ts-ignore
            permission: Permission[selectedPermission?.permission!],
            associatedRoles: selectedRoles! || []
        }

        PermissionService.getInstance().upsertPermissionToRole(permission).then(x => {
            fetchPermissions();
            setDisplayPermissionModal(false);
        });
    }
    
    const rolesListTemplate = (row: PermissionToRole) => {
        return (
            <>
                {row.associatedRoles?.map(x => <Chip key={x.name} label={x.name} onRemove={(e) => onPermissionRemove(row, x)} removable/>)}
            </>
        )
    }

    return (
        <div className="permission-manager">
            <h2>Configure Permissions</h2>
            <Card>
                <TabView>
                    <TabPanel header="Permissions">
                        <DataTable value={permissionToRoles} responsiveLayout="scroll" paginator rows={10}>
                            <Column field="permission" header="Permission" body={permissionTemplate}/>
                            <Column field="roles" header="Roles" body={rolesListTemplate}/>
                            <Column header="" body={permissionButtonTemplate}/>
                        </DataTable>
                        <Dialog header="Add Role" visible={displayPermissionModal} 
                                onHide={() => setDisplayPermissionModal(false)}>
                            <div className="role-input-container">
                                <AutoComplete name="associatedRoles" id="role-input"
                                              value={selectedRoles} suggestions={filteredRoles} completeMethod={searchRoles}
                                              field="name" multiple dropdown onChange={(e) => setSelectedRoles(e.value)}/> 
                            </div>
                            <button className="app-button pull-right save-permission-button" onClick={() => savePermission()}>Save</button>
                            <div className="clearfix"/>
                        </Dialog>
                    </TabPanel>
                    <TabPanel header="Manage Permission Groups">
                        <Dialog header="Add Group" visible={displayRoleModal} style={{width: '50vw'}}
                                onHide={() => setDisplayRoleModal(false)}>
                            <PositionToRoleForm positionTitles={positionTitles!}
                                                employees={employees!}
                                                showDialogFn={setDisplayRoleModal}
                                                updateRolesFn={updateRoles} selectedRow={selectedRow}
                                                existingRoles={roles}/>
                        </Dialog>
                        <DataTable value={roles} responsiveLayout="scroll" paginator rows={10}>
                            <Column field="name" header="Name"/>
                            <Column field="associatedPositionTitles" header="Position Titles"
                                    body={positionTitleTemplate}/>
                            <Column field="associatedEmployees" header="Employees"
                                    body={employeeTemplate}/>
                            <Column header="" body={roleButtonTemplate}/>
                        </DataTable>
                        <button className="app-button pull-right" onClick={() => newRoleModal()}>Add New Role Mapping</button>
                        <div className="clearfix"/>
                    </TabPanel>
                    <TabPanel header="Organisation">
                        <div>
                            <AreaChart />
                            <div className="clearfix"/>
                        </div>
                    </TabPanel>
                </TabView>
            </Card>
        </div>
    )
}

export default PermissionManager;