import {Employee} from "../Shared/UserModel";

export interface ObservationTemplate {
    id: string;
    name: string;
    description: string;
    active: boolean;
    tag: string;
    deleted: boolean;
    addedBy: string;
    addedByUser?: Employee;
    dateAdded: Date;
    modifiedBy?: string;
    dateModified?: Date;
    steps: ObservationTemplateStep[];
}

export interface ObservationTemplateStep {
    id: string;
    stage: string;
    skill: string;
    question: string;
    showNotObservable: boolean;
    showNotes: boolean;
    weighting: number;
    order: number;
    deleted: boolean;
    addedBy: string;
    dateAdded: Date;
    modifiedBy?: string;
    dateModified?: Date;
    responses: ObservationTemplateStepResponse[];
}

export class ObservationTemplateStepCompact {
    id: string;
    stage: string;
    skill: string;
    question: string;
    weighting: number;
    order: number;
    
    constructor(step: ObservationTemplateStep) {
        this.id = step.id;
        this.stage = step.stage;
        this.skill = step.skill;
        this.question = step.question;
        this.order = step.order;
        this.weighting = step.weighting;
    }
}

export interface ObservationTemplateStepResponse {
    id: string;
    label: string;
    description: string;
    percentageOfWeighting: number;
    notes?: string;
    active: boolean;
    addedBy: string;
    dateAdded: Date;
    modifiedBy?: string;
    dateModified?: Date;
}

export interface ObservationResult {
    id: string;
    templateId: string;
    templateName: string;
    templateTag: string;
    templateDescription: string;
    candidate: string;
    candidateDetails?: Employee;
    observerDetails?: Employee;
    observer: string;
    dateAdded: Date;
    published: boolean;
    publishedBy?: string;
    datePublished?: Date | null;
    isCancelled: boolean;
    isDeleted: boolean;
    responses: ObservationStepResult[];
}

export interface ObservationStepResult {
    id: string;
    scorePercent: number;
    scoreLabel: string;
    notObserved: boolean;
    notes?: string;
    dateAdded: Date;
    stepDetails: ObservationTemplateStepCompact;
}