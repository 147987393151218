import React, {useEffect, useRef, useState} from 'react';
import './Observations.css';
import {User} from "../Shared/UserModel";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {ObservationResult, ObservationStepResult} from "./ObservationsModel";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import ObservationResultSummary from "./ObservationResultSummary";
import {ObservationService} from "./ObservationService";
import UserHelper from "../Shared/UserHelper";
import {Permission} from "../Admin/Permissions/PermissionModel";
import {confirmDialog} from "primereact/confirmdialog";

interface ViewObservationProps {
    user: User;
}

function ViewObservation(props: ViewObservationProps) {

    const toast = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as any;
    const returnPath = state.returnPath;
    const [observationResults, setObservationResult] = useState<ObservationResult>(state.result);
    const [publishLoading, setPublishLoading] = useState<boolean>(false);
    
    function scoreTemplate(value: ObservationStepResult) {
        return value.notObserved ? '--' : ` ${value.scorePercent}%`
    }

    // JA-159 This code was hidden on purpose
    // function togglePublishStatus() {
    //     setPublishLoading(true);
    //     if (observationResults.published) {
    //         observationResults.datePublished = null;
    //     } else {
    //         observationResults.datePublished = new Date();
    //     }
    //    
    //     observationResults.published = !observationResults.published;
    //    
    //     ObservationService.getInstance().upsertObservationResult(observationResults).then(response => {
    //         setObservationResult(response.data);
    //         setPublishLoading(false);
    //         // @ts-ignore
    //         toast.current.show({
    //             severity: 'success',
    //             summary: 'Status updated',
    //             detail: `Observation status updated to ${response.data.published ? 'Published' : 'Unpublished'}`,
    //             life: 3000
    //         });
    //     });
    // }

    function returnTo() {
        navigate(returnPath ? returnPath : '/observation');
    }
    
    function canDelete(): boolean {
            return UserHelper.hasPermission(props.user, Permission.CONSULTING_OBSERVATION_DELETE); 
    }
    
    // JA-159 This code was hidden on purpose
    // function canPublish() {
    //     return observationResults.candidateDetails?.managerEmployeeCode === props.user.precedaNumber
    //         || UserHelper.hasPermission(props.user, Permission.CONSULTING_OBSERVATION_PUBLISH);
    // }

    function deleteObservation() {
        observationResults.isDeleted = true;
        ObservationService.getInstance().upsertObservationResult(observationResults).then(_ => {
            returnTo();
        })
    }
    
    function confirmDelete() {
        confirmDialog({
            message: 'Are you sure you would like to delete this item?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deleteObservation()
        });
    }

    return (
        <div className="observation-result-container">
            <Toast ref={toast} />
            <ObservationResultSummary observationResults={observationResults} /> 
            <div className="result-detail-container">
                <DataTable value={observationResults.responses} scrollable scrollHeight="400px" responsiveLayout="scroll">
                    <Column field="stepDetails.stage" header="Stage"/>
                    <Column field="stepDetails.skill" header="Skill"/>
                    <Column field="stepDetails.question" header="Question"/>
                    <Column field="scoreLabel" header="Response"/>
                    <Column field="scorePercent" header="Score" body={scoreTemplate}/>
                    <Column field="notes" header="Notes" />
                </DataTable>
            </div>
            <div className="observation-result-buttons">
                { canDelete() && <Button label="Delete" className="app-button danger" onClick={() => confirmDelete()} />}
                {/* JA-159 - This code was hidden on purpose */}
                {/*{canPublish() && <Button loading={publishLoading} label={observationResults.published ? 'Unpublish Observation' : 'Publish Observation'} onClick={() => togglePublishStatus()} className="app-button" />}*/}
                <Button label={`Return to Dashboard`} onClick={() => returnTo()} className="app-button right-button" />
                <div className="clearfix" />
            </div>
        </div>
    )
}

export default ViewObservation;