import {BusinessUnit, HierarchyBusinessUnit} from "./UserModel";
import TreeNode from "primereact/treenode";

const HierarchyHelper = {
    
    searchTree(tree: HierarchyBusinessUnit[], matchingKey: string): HierarchyBusinessUnit | null {
        if (!tree) {
           return null; 
        }
        
        const selectedNode = tree.find(x => x.businessUnit.businessUnitNumber === matchingKey);

        if (!selectedNode) {
            for (let n of tree) {
                for (let i of n.children!) {
                    if (i.businessUnit.businessUnitNumber === matchingKey) return i;
                }
            }
        }

        return selectedNode!;
    },
    
    getParentBusinessUnit(tree: HierarchyBusinessUnit[], businessUnit: string): BusinessUnit | null {
       const buObject = this.searchTree(tree, businessUnit);
       return buObject ? buObject.parent : null;
    },


    generateTreeNode(object: HierarchyBusinessUnit, level?: string): TreeNode {
        return {
            key: object.businessUnit.businessUnitNumber,
            label: object.businessUnit.businessUnitName,
            data: level ? level : 'area',
            children: object.children.map(y => this.generateTreeNode(y, 'clinic'))
        }
    }
}

export default HierarchyHelper;
