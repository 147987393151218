import {User} from "../Shared/UserModel";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {BusinessPlanService} from "./BusinessPlanService";
import {confirmDialog} from "primereact/confirmdialog";
import {InputText} from "primereact/inputtext";
import BusinessPlanHelper from "./BusinessPlanHelper";
import {Checkbox} from "primereact/checkbox";
import {BusinessPlanBusinessDriver, BusinessPlanTopic} from "./BusinessPlanModel";
import {Editor} from "primereact/editor";
import {Accordion, AccordionTab} from "primereact/accordion";

interface MetricManagerProps {
    topic: BusinessPlanTopic;
    driver: BusinessPlanBusinessDriver;
    user: User;
    setTopicsFn: Dispatch<SetStateAction<BusinessPlanTopic[]>>;
    toast: any;
}

function TopicManager(props: MetricManagerProps) {
    const [editing, isEditing] = useState(false);
    const [editValue, setEditValueValue] = useState('');
    const [actionsChecked, setActionsChecked] = useState<boolean>(false);
    const [insightsChecked, setInsightsChecked] = useState<boolean>(false);
    const [statementsChecked, setStatementsChecked] = useState<boolean>(false);
    const [description, setDescription] = useState<string>(props.topic.description!);
    const [helpText, setHelpText] = useState<string>(props.topic.helpText! || '');

    const confirm = (topicToBeDeactivated: BusinessPlanTopic) => {
        confirmDialog({
            message: 'Are you sure you would like to make this item inactive?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deactivateTopic(topicToBeDeactivated)
        });
    }

    useEffect(() => initCheckboxes(props.topic), [props.topic]);

    const editorHeader = (
        <>
               <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"/>
                <button className="ql-italic" aria-label="Italic"/>
                <button className="ql-underline" aria-label="Underline"/>
            </span>
            <span className="ql-formats">
                <button className="ql-list" aria-label="List Ordered" value="ordered"/>
                <button className="ql-list" aria-label="List Bullet" value="bullet"/>
            </span>
        </>

    );

    const deactivateTopic = (topicToDeactivate: BusinessPlanTopic) => {
        topicToDeactivate.active = false;
        const filteredTopics = props.driver.topics.filter(_topic => _topic.id !== topicToDeactivate.id);
        filteredTopics.push(topicToDeactivate);
        props.driver.topics = filteredTopics;
        upsertDriver(props.driver);
    }

    function initCheckboxes(topic: BusinessPlanTopic) {
        setActionsChecked(topic.hasActions);
        setInsightsChecked(topic.hasInsights);
        setStatementsChecked(topic.hasStatements);
    }

    const editTopic = (editedTopic: BusinessPlanTopic) => {

        if (!BusinessPlanHelper.isInputValid(editValue)) {
            props.toast.current.show({
                severity: 'warn',
                summary: 'Invalid',
                detail: `Please enter a valid name.`,
                life: 3000
            });
            return;
        }

        editedTopic.modifiedBy = props.user.precedaNumber
        editedTopic.dateModified = new Date();
        editedTopic.name = editValue;

        const filteredTopics = props.driver.topics.filter(_topic => _topic.id !== editedTopic.id);
        filteredTopics.push(editedTopic);
        props.driver.topics = filteredTopics;
        upsertDriver(props.driver);
    }

    const upsertDriver = (driver: BusinessPlanBusinessDriver) => {
        BusinessPlanService.getInstance().upsertDriver(driver).then(response => {
            props.setTopicsFn(response.data.topics);

            props.toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: `Topic successfully updated!`,
                life: 3000
            });
        })
    }

    const setEditMode = (topic: BusinessPlanTopic) => {
        setEditValueValue(topic.name);
        isEditing(true);
    }

    const checkInsights = (topic: BusinessPlanTopic, checked: boolean) => {
        setInsightsChecked(checked);
        topic.hasInsights = checked;

        props.driver.topics = BusinessPlanHelper.editListObject(props.driver.topics, topic);
        upsertDriver(props.driver);
    }

    const checkActions = (topic: BusinessPlanTopic, checked: boolean) => {
        setActionsChecked(checked);
        topic.hasActions = checked;

        props.driver.topics = BusinessPlanHelper.editListObject(props.driver.topics, topic);
        upsertDriver(props.driver);
    }

    const checkStatements = (topic: BusinessPlanTopic, checked: boolean) => {
        setStatementsChecked(checked);
        topic.hasStatements = checked;

        props.driver.topics = BusinessPlanHelper.editListObject(props.driver.topics, topic);
        upsertDriver(props.driver);
    }

    const updateHelpText = () => {
        props.topic.helpText = helpText;
        props.driver.topics = BusinessPlanHelper.editListObject(props.driver.topics, props.topic);
        upsertDriver(props.driver);
    }

    const updateDescriptionText = () => {
        props.topic.description = description;
        props.driver.topics = BusinessPlanHelper.editListObject(props.driver.topics, props.topic);
        upsertDriver(props.driver);
    }

    return (
        <>
            <div className="topic-item structure-item">
                {editing ?
                    <div className="inline-edit-container">
                        <InputText value={editValue} autoFocus
                                   onChange={(e) => setEditValueValue(e.target.value)}/>
                        <div className="edit-button-group">
                            <i className="pi pi-check" onClick={() => editTopic(props.topic)}/>
                            <i className="pi pi-times" onClick={() => isEditing(false)}/>
                        </div>
                    </div>
                    :
                    <>
                        <div className="header-group">
                            <h3 className="name editable" onClick={() => setEditMode(props.topic)}>
                                {props.topic.name}
                            </h3>
                            <i className="pi pi-times delete-button" onClick={() => confirm(props.topic)}/>
                        </div>
                    </>
                }
                <div className="metric-configuration-container grid">
                    <div className="insights-actions-configuration col-4">
                        <h4>Toggle Features</h4>
                        <div className="checkbox-container">
                            <Checkbox inputId={`insights-checkbox-${props.topic.id}`}
                                      onChange={e => checkInsights(props.topic, e.checked)}
                                      checked={insightsChecked}/>
                            <label htmlFor={`insights-checkbox-${props.topic.id}`}
                                   className="p-checkbox-label">Insights</label>
                        </div>
                        <div className="checkbox-container">
                            <Checkbox inputId={`actions-checkbox-${props.topic.id}`}
                                      onChange={e => checkActions(props.topic, e.checked)}
                                      checked={actionsChecked}/>
                            <label htmlFor={`actions-checkbox-${props.topic.id}`}
                                   className="p-checkbox-label">Actions</label>
                        </div>
                        <div className="checkbox-container">
                            <Checkbox inputId={`directional-checkbox-${props.topic.id}`}
                                      onChange={e => checkStatements(props.topic, e.checked)}
                                      checked={statementsChecked}/>
                            <label htmlFor={`directional-checkbox-${props.topic.id}`}
                                   className="p-checkbox-label">Directional Statements</label>
                        </div>
                    </div>
                    <div className="topic-metadata-config col-8">

                        <Accordion>
                            <AccordionTab header="Description">
                                <div className="topic-description-container">
                                    <div className="topic-description-text">
                                        <Editor style={{height: '320px'}} value={description}
                                                onTextChange={(e) => setDescription(e.htmlValue!)} className="topic-configuration-editor" headerTemplate={editorHeader}/>
                                        <button className="p-button p-button-icon-only save-icon-button"
                                                onClick={(e) => updateDescriptionText()}>
                                            <span className="p-button-icon p-c pi pi-check"/>
                                            <span className="p-button-label p-c">&nbsp;</span>
                                        </button>
                                        <div className="clearfix"/>
                                    </div>
                                </div>
                            </AccordionTab>
                            <AccordionTab header="Help">
                                <div className="topic-help-container">
                                    <Editor style={{height: '320px'}} value={helpText}
                                            onTextChange={(e) => setHelpText(e.htmlValue!)} headerTemplate={editorHeader} className="topic-configuration-editor"/>
                                    <button className="p-button p-button-icon-only save-icon-button"
                                            onClick={() => updateHelpText()}>
                                        <span className="p-button-icon p-c pi pi-check"/>
                                        <span className="p-button-label p-c">&nbsp;</span>
                                    </button>
                                    <div className="clearfix"/>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        
                        
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopicManager;