import React, {useEffect, useRef, useState} from 'react';
import {ObservationResult} from "./ObservationsModel";
import {ObservationService} from "./ObservationService";
import UserService from "../Shared/UserService";
import TreeNode from "primereact/treenode";
import HierarchyHelper from "../Shared/HierarchyHelper";
import {TreeSelect} from "primereact/treeselect";
import {Dropdown} from "primereact/dropdown";
import BusinessPlanHelper from "../BusinessPlan/BusinessPlanHelper";
import ObservationDatatable from "./ObservationDatatable";
import {Toast} from "primereact/toast";
import {HierarchyBusinessUnit} from "../Shared/UserModel";
import {Card} from "primereact/card";
import {Bar} from "react-chartjs-2";

function ObservationExplorer() {

    const toast = useRef(null);
    const [observations, setObservations] = useState<ObservationResult[]>([]);
    const [nodes, setNodes] = useState<TreeNode[]>([]);
    const [hierarchyData, setHierarchyData] = useState<HierarchyBusinessUnit[]>([]);
    const [areaChartData, setAreaChartData] = useState<any>({labels: [], datasets: []});
    const [clinicChartData, setClinicChartData] = useState<any>({labels: [], datasets: []});
    const [selectedNodeKey, setSelectedNodeKeys] = useState<any[] | null>([]);
    const [selectedMonth, setSelectedMonth] = useState<number | null>(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState<number | null>(new Date().getFullYear());
    const [years, setYears] = useState<number[]>();
    const [observationsLoading, setObservationsLoading] = useState<boolean>(false);
    const SAVED_FILTERS_KEY = 'observationSavedFilters';

    const chartOptions = {
        responsive: true,
        scales: {
            y: {
                title: {
                    display: true,
                    text: '# Observations'
                }

            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
    };

    useEffect(() => {
        fetchAreas();
        setYears(BusinessPlanHelper.getFiscalYearsArray());
    }, []);

    const fetchAreas = (): any => {
        setObservationsLoading(true);
        UserService.getInstance().fetchAreas().then(response => {
            setHierarchyData(response.data);
            const treeData = response.data.map(x => HierarchyHelper.generateTreeNode(x))
            setNodes(treeData);

            const savedFilters = JSON.parse(localStorage.getItem(SAVED_FILTERS_KEY)!);
            if (savedFilters != null) {
                setSelectedNodeKeys(savedFilters.nodes);
                setSelectedMonth(savedFilters.month);
                setSelectedYear(savedFilters.year);
                getObservationResults(savedFilters.nodes, treeData, savedFilters.month, savedFilters.year);
                return;
            }

            const selectedNodes: any = {};
            treeData.forEach(x => {
                if (x.data == 'area') {
                    selectedNodes[`${x.key}`] = true;
                }
            });
            setSelectedNodeKeys(selectedNodes);
            getObservationResults(selectedNodes, treeData, selectedMonth!, selectedYear!);
        });
    }

    function clearFilters() {
        setSelectedMonth(null);
        setSelectedYear(null);
        setSelectedNodeKeys(null);
        localStorage.removeItem(SAVED_FILTERS_KEY);
        setObservations([]);
        setAreaChartData({labels: [], datasets: []});
        setClinicChartData({labels: [], datasets: []});
    }

    const getObservationResults = (nodeKeys: any, nodeData: any[], month: number, year: number) => {
        if (nodeKeys === null || Object.keys(nodeKeys!).length === 0) {
            // @ts-ignore
            toast.current.show({
                severity: 'warn',
                summary: 'Invalid',
                detail: `Please select at least one Business Unit`,
                life: 3000
            });
            return;
        }

        setObservationsLoading(true);

        const selectedNodes: TreeNode[] = [];
        // @ts-ignore
        Object.keys(nodeKeys).forEach(x => {
            selectedNodes.push(searchTree(nodeData, x));
        });

        const selectedBusinessUnits: string[] = selectedNodes.map(x => {
            if (x?.data === 'area') {
                const children = x.children!.map(y => y.key!.toString());
                children.push(x.key!.toString());
                return children;
            } else {
                return x?.key!.toString();
            }
        }).flat();
        localStorage.setItem(SAVED_FILTERS_KEY, JSON.stringify({month: month, year: year, nodes: nodeKeys}));

        const uniqueBusinessUnits: string[] = selectedBusinessUnits.filter((item, index) => selectedBusinessUnits.indexOf(item) === index);

        ObservationService.getInstance().getObservationsByBusinessUnitsAndPeriod(uniqueBusinessUnits, month, year).then(response => {
            setObservations(response.data);
            setObservationsLoading(false);
        })
    }

    function buildDistributionByClinicData(groupedCount: {}) {
        const countByClinic: any = {};

        Object.keys(groupedCount).forEach(x => {
            const businessUnit = HierarchyHelper.searchTree(hierarchyData, x);
            if (countByClinic[businessUnit!.businessUnit.businessUnitNumber]) {
                countByClinic[businessUnit!.businessUnit.businessUnitNumber].count = countByClinic[businessUnit!.businessUnit.businessUnitNumber].count + groupedCount[x];
            } else {
                countByClinic[businessUnit!.businessUnit.businessUnitNumber] = {
                    count: groupedCount[x],
                    businessUnit: businessUnit!.businessUnit
                };
            }
        });

        const chartLabels = Object.values(countByClinic).map((x: any) => x.businessUnit.businessUnitName);

        const chartData = {
            labels: chartLabels,
            datasets: [
                {
                    label: 'Observations',
                    data: Object.values(countByClinic).map((x: any) => x.count),
                    backgroundColor: 'rgb(138,99,255)',
                },
            ],
        };

        setClinicChartData(chartData);
    }

    function buildDistributionByAreaData(groupedCount: {}) {
        const countByArea: any = {};

        Object.keys(groupedCount).forEach(x => {
            const businessUnit = HierarchyHelper.searchTree(hierarchyData, x);
            if (countByArea[businessUnit!.parent.businessUnitNumber]) {
                countByArea[businessUnit!.parent.businessUnitNumber].count = countByArea[businessUnit!.parent.businessUnitNumber].count + groupedCount[x];
            } else {
                countByArea[businessUnit!.parent.businessUnitNumber] = {
                    count: groupedCount[x],
                    businessUnit: businessUnit!.parent
                };
            }
        });

        const chartLabels = Object.values(countByArea).map((x: any) => x.businessUnit.businessUnitName);

        const chartData = {
            labels: chartLabels,
            datasets: [
                {
                    label: 'Observations',
                    data: Object.values(countByArea).map((x: any) => x.count),
                    backgroundColor: 'rgba(255, 99, 132, 1)',
                },
            ],
        };

        setAreaChartData(chartData);
    }

    function buildSkillRanking() {
        const responsesByTemplate: any = {};
        observations.forEach(x => {
            if (responsesByTemplate[x.templateName]) {
                responsesByTemplate[x.templateName] = responsesByTemplate[x.templateName].concat(x.responses);
            } else {
                responsesByTemplate[x.templateName] = x.responses;
            }
        });

        let groupedByScoreTotal = new Map<string, any>();

        Object.keys(responsesByTemplate).forEach(y => {
                responsesByTemplate[y].forEach((x: any) => {
                    if (groupedByScoreTotal.has(y)) {
                        const responses = groupedByScoreTotal.get(y);
                        if (responses[x.stepDetails.id]) {
                            responses[x.stepDetails.id].score = responses[x.stepDetails.id].score + x.scorePercent;
                        } else {
                            responses[x.stepDetails.id] = {score: x.scorePercent, stepDetails: x.stepDetails}
                        }
                        groupedByScoreTotal.set(y, responses);
                    } else {
                        const responses: any = {};
                        responses[x.stepDetails.id] = {score: x.scorePercent, stepDetails: x.stepDetails}
                        groupedByScoreTotal.set(y, Object.values(responses))
                    }
                });
            }
        );

    }

    useEffect(() => {
        const observationsBusinessUnits = observations.map(x => x.candidateDetails?.businessUnit);

        const groupedCount = observationsBusinessUnits.reduce((total, value) => {
            total[value!] = (total[value!] || 0) + 1;
            return total;
        }, {});

        buildDistributionByAreaData(groupedCount);
        buildDistributionByClinicData(groupedCount);
        // buildSkillRanking();
    }, [observations]);

    const searchTree = (nodes: TreeNode[], matchingKey: string): any => {
        const selectedNode = nodes.find(x => x.key === matchingKey);
        if (!selectedNode) {
            for (let n of nodes) {
                for (let i of n.children!) {
                    if (i.key === matchingKey) return i;
                }
            }
        }
        return selectedNode;
    }

    function selectNodeKeys(e: any) {
        setSelectedNodeKeys(e.value);
    }

    return (
        <div className="observation-explorer-container">
            <Toast ref={toast}/>
            <div className="explorer-form">
                <h2>Observation Explorer</h2>
                <div className="formgroup-inline">
                    <div className="field">
                       <span className="p-float-label">
                            <TreeSelect
                                className="inputfield bp-explorer-business-unit"
                                selectionMode="multiple"
                                filter
                                inputId="bp-explorer-business-unit"
                                display="chip"
                                metaKeySelection={false}
                                value={selectedNodeKey}
                                options={nodes}
                                onChange={(e: any) => selectNodeKeys(e)}/>
                            <label htmlFor="bp-explorer-business-unit">Select Business Unit</label>
                       </span>
                    </div>

                    <div className="field">
                           <span className="p-float-label">
                                <Dropdown className="inputfield obs-explorer-month" value={selectedMonth}
                                          options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                                          inputId="obs-explorer-month"
                                          showClear
                                          onChange={(e) => setSelectedMonth(e.value)}
                                />
                                <label htmlFor="bp-explorer-quarter">Month</label>
                           </span>
                    </div>
                    <div className="field">
                          <span className="p-float-label">
                            <Dropdown inputId="bp-explorer-year" className="inputfield bp-explorer-year"
                                      value={selectedYear} options={years}
                                      showClear
                                      onChange={(e) => setSelectedYear(e.value)}/>
                              <label htmlFor="bp-explorer-year">Year</label>
                          </span>
                    </div>
                </div>
                <div className="field explorer-form-buttons">
                    <button className="app-button mr-2" onClick={() => clearFilters()}>Clear</button>
                    <button className="app-button"
                            onClick={() => getObservationResults(selectedNodeKey, nodes, selectedMonth!, selectedYear!)}>Find
                    </button>
                </div>
            </div>

            <div className="observation-results">

                <ObservationDatatable highlightOverdue={false} scrollHeight={800} observations={observations}
                                      loading={observationsLoading}
                                      title={`Observations (${observations.length} results)`}
                                      returnPath="/observation/explorer"
                                      columns={['candidateDetails', 'scoreByStage', 'published', 'score', 'templateName', 'observerDetails', 'candidateDetails.fullTitle', 'dateAdded', 'candidateDetails.businessUnitName']}/>

                <Card className="mt-5">
                    <div className="grid">
                        <div className="col">
                            <div className="flex align-items-center justify-content-center h-full">
                                <h1>Distribution by Area</h1>
                            </div>
                        </div>
                        <div className="col">
                            <Bar options={chartOptions} data={areaChartData}/>
                        </div>
                    </div>
                </Card>

                <Card className="mt-5">
                    <div className="grid">
                        <div className="col">
                            <Bar options={chartOptions} data={clinicChartData}/>
                        </div>
                        <div className="col">
                            <div className="flex align-items-center justify-content-center h-full">
                                <h1>Distribution by Clinic</h1>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default ObservationExplorer;