import React, {useContext, useEffect, useState} from 'react';

import {Dropdown} from "primereact/dropdown";
import {differenceInDays, format} from "date-fns";
import {Inplace, InplaceContent, InplaceDisplay} from "primereact/inplace";
import {Calendar} from "primereact/calendar";
import {v4} from "uuid";
import './ActionItem.css';
import {BusinessPlanService} from "../BusinessPlanService";
import DotMenu from "../../Shared/DotMenu/DotMenu";
import {
    BusinessPlanAction,
    BusinessPlanActionStatus,
    BusinessPlanItemStatusType,
    BusinessUnitPlan
} from "../BusinessPlanModel";
import BusinessPlanHelper from "../BusinessPlanHelper";
import BusinessPlanItemStatusIndicator from "../BusinessPlanItemStatusIndicator";
import {userContext} from "../../App";
import {actionValidation} from "../BusinessPlanActionForm";
import {confirmDialog} from "primereact/confirmdialog";
import ReactQuill from "react-quill";

interface BusinessPlanActionItemProps {
    action: BusinessPlanAction;
    planDetail: BusinessUnitPlan;
    updateActionsFn: (actions: BusinessPlanAction[], updatedAction: BusinessPlanAction) => void;
    toast: any;
    readOnly: boolean;
    refreshPlanFn: any;
    showPlanInformation?: boolean;
}

function BusinessPlanActionItem(props: BusinessPlanActionItemProps) {

    const [editActive, setEditActive] = useState<boolean>(false);
    const [actionEditText, setActionEditText] = useState<string>(props.action.action);
    const [outcomeEditText, setOutcomeEditText] = useState<string>(props.action.outcome);
    const [dueDateEditText, setDueDateEditText] = useState<Date>(new Date(props.action.dueDate));
    const [selectedStatus, setSelectedStatus] = useState<any>(null);
    const [dueDateIcon, setDueDateIcon] = useState<any>(null);

    const user = useContext(userContext);
    
    const editAction = () => {
        const currentState: BusinessPlanAction = {...props.action};
        currentState.itemHistory = [];
        
        props.action.action = actionEditText;
        props.action.outcome = outcomeEditText;
        props.action.dueDate = format(dueDateEditText, "yyyy-MM-dd'T'HH:mm:ss.SSS");
        props.action.dateModified = new Date();
        props.action.modifiedBy = user.precedaNumber;

        actionValidation.validate(props.action).then(_ => {
            if (props.action.itemStatusHistory == null) props.action.itemStatusHistory = [];
            if (props.action.itemHistory == null) props.action.itemHistory = [];

            if (BusinessPlanHelper.shouldPersistItemChange(props.action.itemStatusHistory)) {

                props.action.itemStatusHistory.push({
                        id: v4(),
                        status: BusinessPlanItemStatusType[BusinessPlanItemStatusType.CHANGED],
                        addedBy: user.precedaNumber,
                        dateAdded: new Date()
                    }
                )

                props.action.itemHistory.push(
                    {id: v4(), addedBy: user.precedaNumber, dateAdded: new Date(), stateSnapshot: currentState}
                )
            }

            const amendedActions = props.planDetail.currentPlan!.actions.filter(item => item.id !== props.action.id);
            amendedActions.push(props.action);
            props.planDetail.currentPlan!.actions = amendedActions;

            BusinessPlanService.getInstance().upsertBusinessPlan(props.planDetail.currentPlan!).then(response => {
                props.updateActionsFn(response.data.actions, props.action);
                setEditActive(false);
                props.refreshPlanFn(response.data);
                props.toast.current.show({
                    severity: 'success',
                    summary: 'Success!',
                    detail: 'Your action was edited successfully.',
                    life: 3000
                });
            }) 
        }).catch(x => {
            props.toast.current.show({
                severity: 'warn',
                summary: 'Invalid!',
                detail: x.message,
                life: 3000
            }); 
        });
        

    }



    useEffect(() => {

        // Calculate due date icon
        const daysUntilDueDate = differenceInDays(new Date(props.action.dueDate).setHours(0, 0, 0), new Date().setHours(0, 0, 0));
        const dueDateIcon = 'pi pi-circle-on due-date-icon';
        switch (true) {
            case (daysUntilDueDate > 3):
                setDueDateIcon(`${dueDateIcon} not-due`);
                break;
            case (daysUntilDueDate <= 2 && daysUntilDueDate >= 0):
                setDueDateIcon(`${dueDateIcon} due-soon`);
                break;
            case (daysUntilDueDate < 0):
                setDueDateIcon(`${dueDateIcon} overdue`);
        }
        
        setSelectedStatus(props.action.status);
        
        return () => {
            setSelectedStatus('');
        }
    }, [props.action.status, props.action.dueDate]);

    const showEditField = () => {
        setActionEditText(props.action.action);
        setOutcomeEditText(props.action.outcome);
        setDueDateEditText(new Date(props.action.dueDate));
        setEditActive(true);
    }

    const cancelAction = () => {
        if (BusinessPlanHelper.canCancelItem(props.action.itemStatusHistory)) {
            props.action.itemStatusHistory.push({
                    id: v4(),
                    status: BusinessPlanItemStatusType[BusinessPlanItemStatusType.CANCELLED],
                    addedBy: user.precedaNumber,
                    dateAdded: new Date()
                }
            )

            const amendedActions = props.planDetail.currentPlan!.actions.filter(item => item.id !== props.action.id);
            amendedActions.push(props.action);
            props.planDetail.currentPlan!.actions = amendedActions;
        } else {
            props.planDetail.currentPlan!.actions = props.planDetail.currentPlan!.actions.filter(item => item.id !== props.action.id);
        }

        BusinessPlanService.getInstance().upsertBusinessPlan(props.planDetail.currentPlan!).then(response => {
            props.updateActionsFn(response.data.actions, props.action);
            props.toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: 'Your action was marked for deletion.',
                life: 3000
            });
        });
    }

    const confirmCancel = () => {
        const message = BusinessPlanHelper.canCancelItem(props.action.itemStatusHistory)
            ? 'Are you sure you want to mark this action as cancelled?'
            : 'Are you sure you want to delete this action?'
        confirmDialog({
            message: message,
            icon: 'pi pi-exclamation-triangle',
            accept: () => cancelAction(),
        });
    }

    const actionStatuses = Object.keys(BusinessPlanActionStatus).filter(x => x !== BusinessPlanActionStatus[BusinessPlanActionStatus.TRANSFERRED]).filter(key => isNaN(Number(key)));

    const contextMenu = [
        {
            label: 'Edit',
            command: showEditField
        },
        {
            label: BusinessPlanHelper.canCancelItem(props.action.itemStatusHistory) ? 'Cancel' : 'Delete',
            command: confirmCancel
        }
    ]

    const updateStatus = (e: { value: any }) => {
        setSelectedStatus(e.value);

        props.action.status = e.value;
        props.action.statusUpdatedDate = new Date();

        const filteredActions = props.planDetail.currentPlan!.actions.filter(action => action.id !== props.action.id);
        filteredActions.push(props.action);

        props.planDetail.currentPlan!.actions = filteredActions;

        BusinessPlanService.getInstance().upsertBusinessPlan(props.planDetail.currentPlan!).then(response => {
            props.toast.current.show({
                severity: 'success',
                summary: 'Success!',
                detail: 'Action status updated successfully.',
                life: 3000
            });
            props.updateActionsFn(response.data.actions, props.action)
        });
    }

    const handleDateChange = (date: any) => {
        setDueDateEditText(date.target.value);
    }

    return <>
        <div className="action-item">
            {!props.readOnly
                && (props.action.status !== BusinessPlanActionStatus[BusinessPlanActionStatus.COMPLETED] && props.action.status !== BusinessPlanActionStatus[BusinessPlanActionStatus.TRANSFERRED]) && <DotMenu items={contextMenu}/> }

            {props.showPlanInformation &&
                <div className="plan-information">
                    <h3 className="plan-information-clinic">{props.planDetail.businessUnit.businessUnitName}</h3>
                    <h3>{`FY${props.planDetail.currentPlan?.fiscalYear} Q${props.planDetail.currentPlan?.fiscalQuarter}`}</h3>
                </div> 
            }

            <div className="action-text action-section">
                    <h3>Action</h3>
                    <Inplace active={editActive} onToggle={() => setEditActive(false)}>
                        <InplaceDisplay>
                            <div className="pre-line-format" dangerouslySetInnerHTML={{__html: props.action.action}} />
                        </InplaceDisplay>
                        <InplaceContent>
                            <ReactQuill className="bp-editor"
                                        modules={BusinessPlanHelper.EDITOR_MODULES}
                                        theme="snow"
                                        value={actionEditText}
                                        onChange={setActionEditText}/>
                        </InplaceContent>
                    </Inplace>
            </div>
            <div className="outcome-text action-section">
                    <h3>Expected Outcome</h3>
                    <Inplace active={editActive} onToggle={() => setEditActive(false)}>
                        <InplaceDisplay>
                            <div className="pre-line-format" dangerouslySetInnerHTML={{__html: props.action.outcome}} />
                        </InplaceDisplay>
                        <InplaceContent>
                            <ReactQuill className="bp-editor"
                                        modules={BusinessPlanHelper.EDITOR_MODULES}
                                        theme="snow"
                                        value={outcomeEditText}
                                        onChange={setOutcomeEditText}/>
                        </InplaceContent>
                    </Inplace>

            </div>
            <div className="grid">
                <div className="due-date-text action-section col">
                    <h3>{props.action.status !== BusinessPlanActionStatus[BusinessPlanActionStatus.COMPLETED] 
                    && (<i className={dueDateIcon} />)} Target Date</h3>
                    <Inplace active={editActive} onToggle={() => setEditActive(false)}>
                        <InplaceDisplay>
                            {format(new Date(props.action.dueDate), 'dd/MM/yyyy')}
                        </InplaceDisplay>
                        <InplaceContent>
                                <Calendar dateFormat="dd/mm/yy" name="dueDate" readOnlyInput minDate={new Date()}
                                      id="dueDateInput"
                                      value={dueDateEditText}
                                      onChange={handleDateChange} showIcon/>
                        </InplaceContent>
                    </Inplace>
                </div>
                <div className="status-selector col action-section">
                    <h3>Status</h3>
                    {props.action.status === BusinessPlanActionStatus[BusinessPlanActionStatus.TRANSFERRED] ?
                        <span>TRANSFERRED</span> :
                        <Dropdown
                            disabled={props.readOnly}
                            data-testid="status-dropdown"
                            value={selectedStatus} options={actionStatuses} onChange={updateStatus}/>
                    }
                </div>
            </div>

            {editActive && <>
                <button className="p-button p-button-icon-only edit-icon-button" onClick={() => editAction()}>
                    <span className="p-button-icon p-c pi pi-check" />
                    <span className="p-button-label p-c">&nbsp;</span>
                </button>
                <button type="button" className="p-button p-component p-inplace-content-close p-button-icon-only" onClick={() => setEditActive(false)}>
                <span className="p-button-icon p-c pi pi-times pi pi-times"> </span>
                <span className="p-button-label p-c">&nbsp;</span>
                </button>
                </>}
                
            <div className="action-metadata">
                <BusinessPlanItemStatusIndicator statusHistory={props.action.itemStatusHistory} />
                <div className="action-date-added">Added on {format(new Date(props.action.dateAdded), 'dd/MM/yyyy')}</div>
            </div>
        </div>
    </>
}

export default BusinessPlanActionItem;