import {PermissionToRole, PositionToRole} from "../Admin/Permissions/PermissionModel";

export class User {
    assignedBusinessUnit: BusinessUnit;
    id: string;
    managerId: string;
    firstName: string;
    surname: string;
    fullName: string;
    precedaNumber: string;
    title: string;
    upn: string;
    userAccessToken: string;
    positionRole: PositionToRole;
    permissions: PermissionToRole[];
    availableBusinessUnits: BusinessUnit[];
}

export class Employee {
    employeeCode: string;
    preferredName: string;
    firstNames: string;
    surname: string;
    positionId: string;
    hireDate: Date;
    businessUnit: string;
    businessUnitName: string;
    employmentType: string;
    fullTitle: string;
    reportsToPosition: string;
    reportsToPositionTitle: string;
    managerPosition: string;
    bizEmail: string;
    managerEmployeeCode: string;
    managerPositionCode: string;
    managerPositionTitle: string;
}

export type BusinessUnit = {
    businessUnitNumber: string;
    businessUnitName: string;
}

export type HierarchyBusinessUnit = {
    parent: BusinessUnit;
    businessUnit: BusinessUnit;
    children: HierarchyBusinessUnit[];
}