import React, {useContext} from 'react';
import {useFormik} from 'formik';
import {
    BusinessPlanInsight,
    BusinessPlanItemStatusType,
    BusinessPlanTopic,
    BusinessUnitPlan
} from "./BusinessPlanModel";
import {userContext} from "../App";
import {v4} from "uuid";
import {BusinessPlanService} from "./BusinessPlanService";
import {Message} from "primereact/message";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import BusinessPlanHelper from "./BusinessPlanHelper";

const MIN_INSIGHT_CHARACTERS = 10
const MAX_INSIGHT_CHARACTERS = 10000
export const insightValidation = Yup.object().shape({
    insightText: Yup.string()
        .min(MIN_INSIGHT_CHARACTERS, `Too short! The Insight field has a ${MIN_INSIGHT_CHARACTERS} character minimum`)
        .max(MAX_INSIGHT_CHARACTERS, `Too long! The Insight field has a ${MAX_INSIGHT_CHARACTERS} character maximum`)
        .required('Insight is a required field'),
});

interface BusinessPlanInsightFormProps {
    topic: BusinessPlanTopic;
    planDetail: BusinessUnitPlan;
    updateInsightsFn: any;
    toast: any;
}

function BusinessPlanInsightsForm(props: BusinessPlanInsightFormProps) {

    const user = useContext(userContext),
        formik = useFormik({
            initialValues: {
                insightText: '',
            },
            validationSchema: insightValidation,
            onSubmit: (data) => {

                const insight: BusinessPlanInsight = {
                    id: v4(),
                    addedBy: user.precedaNumber,
                    dateAdded: new Date(),
                    insightText: data.insightText,
                    topicId: props.topic.id,
                    active: true,
                    itemStatusHistory: [
                        {
                            id: v4(),
                            status: BusinessPlanItemStatusType[BusinessPlanItemStatusType.NEW],
                            addedBy: user.precedaNumber,
                            dateAdded: new Date()
                        }
                    ],
                    itemHistory: [],
                    deleted: false
                }
                if (props.planDetail.currentPlan!.insights == null) props.planDetail.currentPlan!.insights = [];

                props.planDetail.currentPlan!.insights.push(insight);

                BusinessPlanService.getInstance().upsertBusinessPlan(props.planDetail.currentPlan!).then(result => {
                    props.toast.current.show({
                        severity: 'success',
                        summary: 'Success!',
                        detail: `Your insight was added successfully`,
                        life: 3000
                    });
                    props.updateInsightsFn(result.data);
                });

                formik.resetForm();
            }
        });



    return (

        <form onSubmit={formik.handleSubmit} className="insight-form">
            <div>
                <ReactQuill className="bp-editor" 
                            modules={BusinessPlanHelper.EDITOR_MODULES} 
                            theme="snow" 
                            value={formik.values.insightText} 
                            onChange={(e) => formik.handleChange({target: {name: 'insightText', value: e}})}/>
                <span
                    className="validation-rule">Min {MIN_INSIGHT_CHARACTERS} characters | Max {MAX_INSIGHT_CHARACTERS} characters</span>
            </div>
            {formik.errors.insightText && formik.touched.insightText ? (
                <>
                    <Message severity="warn" text={formik.errors.insightText}/>
                </>
            ) : null}

            <div className="form-buttons">
                <button type="button" className="app-button" onClick={() => formik.resetForm()}>Clear</button>
                <button type="submit" className="app-button">Add</button>
            </div>

        </form>

    );
}

export default BusinessPlanInsightsForm;