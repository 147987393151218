import React, {useEffect, useState} from 'react';
import {useFormik} from "formik";
import './Observations.css';
import * as Yup from "yup";
import {Dropdown, DropdownChangeParams} from "primereact/dropdown";
import {useLocation} from "react-router";
import {CSSTransition} from "primereact/csstransition";
import {format} from "date-fns";
import {Button} from "primereact/button";
import {Employee, User} from "../Shared/UserModel";
import {useNavigate} from "react-router-dom";
import {ObservationTemplate} from "./ObservationsModel";
import _ from 'lodash';

interface PrepareObservationProps {
    user: User;
}

interface TemplateWithMetadata {
    template: ObservationTemplate;
    numberOfQuestions: number;
}

function PrepareObservation(props: PrepareObservationProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const state = location.state as any;
    const [candidates, ] = useState<Employee[]>(state.candidates);
    const [templates, ] = useState<ObservationTemplate[]>(state.templates);
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateWithMetadata | null>();
    const [templateInfo, setTemplateInfo] = useState<any[]>();
    const [showTemplateDropdown, setShowTemplateDropdown] = useState<boolean>(false);

    useEffect(() => {
        const templateData = templates?.filter(x => x.active).map(x => {
            return {
                template: x,
                numberOfQuestions: x.steps.filter(i => i.deleted === false)?.length
            }
        });
        
        const grouped = _.groupBy(templateData, x => x.template.tag);
        const selectItems = Object.keys(grouped).map(x => {
            return {label: x != '' ? _.upperCase(x) : 'OTHER', items: grouped[x]}
        });
        
        setTemplateInfo(selectItems);
    }, [templates]);

    const newObservationValidation = Yup.object().shape({
        candidate: Yup.object().required(),
        template: Yup.object().required()
    });

    const formik = useFormik({
        initialValues: {
            candidate: {},
            template: {},
        },
        validationSchema: newObservationValidation,
        onSubmit: (data) => {
            // @ts-ignore
            navigate('/observation/perform', {state: {template: data.template.template, candidate: data.candidate}});
        }
    });

    const candidateOptionTemplate = (candidate: Employee) => {
        // @ts-ignore
        return (
            <div className="observation-field-template">
                <div className="field-name">{`${candidate?.preferredName} ${candidate?.surname}`}</div>
                <div className="field-metadata">{`${candidate?.fullTitle} | ${candidate?.businessUnitName}`}</div>
            </div>
        )
    }

    const selectedCandidateTemplate = (option: any, props: any) => {
        if (option) {
            return (
                <div className="observation-field-template selected">
                    <div className="field-name">{`${option?.preferredName} ${option?.surname}`}</div>
                    <div className="field-metadata">{`${option?.fullTitle} | ${option?.businessUnitName}`}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const templateOptionTemplate = (option: TemplateWithMetadata) => {
        return (
            <div className="observation-field-template template-item">
                <div className="field-name">
                    {option.template.name}
                </div>
                <div className="field-metadata">
                    <i className="pi pi-comments"/> {option.numberOfQuestions} question{option.numberOfQuestions > 1 && 's'} | Last Updated: {format(new Date(option.template.dateModified ? option.template.dateModified : option.template.dateAdded ), 'dd/MM/yyyy')}
                </div>
            </div>
        )
    }
    
    const groupedOptionTemplate = (item: any) => {
        return (
            <div className="flex align-items-center country-item">
                <i className="pi pi-tag mr-2 color-pink" />
                <div className="text-lg">{item.label}</div>
            </div> 
        )
    }

    const selectedTemplateOptionTemplate = (option: TemplateWithMetadata, props: any) => {
        if (option) {

            return (
                <div className="observation-field-template selected">
                    <div className="field-name">
                        {option.template.name}
                    </div>
                    <div className="field-metadata">
                        <i className="pi pi-comments"/> {option.numberOfQuestions} question{option.numberOfQuestions > 1 && 's'} | Last Updated: {format(new Date(option.template.dateModified ? option.template.dateModified : option.template.dateAdded ), 'dd/MM/yyyy')}
                    </div>
                </div>
            )
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const handleCandidateDropdownChange = (e: any) => {
        if (e.value) {
            setShowTemplateDropdown(true);
        } else {
            setShowTemplateDropdown(false);
            setSelectedTemplate(null);
            formik.values.template = {};
        }
        formik.handleChange(e);
    }

    function handleTemplateDropdownChange(e: DropdownChangeParams) {
        setSelectedTemplate(e.value);
        formik.handleChange(e);
    }

    return (
        <form onSubmit={formik.handleSubmit} className="prepare-observation-form">
            <h2 className="form-header">Prepare Observation</h2>
            <div className="field observation-form-field">
                <label htmlFor="candidate"><i className="pi pi-user observation-form-icon" /> Who are you observing?</label>
                <Dropdown panelClassName="candidate-dropdown-panel" className="observation-dropdown"
                          value={formik.values.candidate} options={candidates}
                          onChange={(e) => handleCandidateDropdownChange(e)}
                          name="candidate" optionLabel="name" filter showClear
                          filterBy="preferredName,surname,fullTitle,businessUnitName"
                          valueTemplate={selectedCandidateTemplate} itemTemplate={candidateOptionTemplate}/>
            </div>

            <CSSTransition
                in={showTemplateDropdown}
                timeout={500}
                classNames="template"
                unmountOnExit>
                <div className="field observation-form-field">
                    <label htmlFor="candidate"><i className="pi pi-eye observation-form-icon" /> Which observation?</label>
                    <Dropdown panelClassName="template-dropdown-panel" className="observation-dropdown"
                              value={formik.values.template} options={templateInfo} onChange={(e) => handleTemplateDropdownChange(e)}
                              name="template" filter showClear optionLabel="name" filterBy="template.name,template.tag"
                              optionGroupLabel="label" optionGroupChildren="items" optionGroupTemplate={groupedOptionTemplate}
                              valueTemplate={selectedTemplateOptionTemplate} itemTemplate={templateOptionTemplate}/>
                </div>
            </CSSTransition>

            <CSSTransition
                in={selectedTemplate != null}
                timeout={500}
                classNames="template"
                unmountOnExit> 
                <div className="field submit-button-field">
                    <Button type="submit" className="app-button prepare-observation-button" label={`Start Observation: ${selectedTemplate?.template.name}`}/>
                </div>
            </CSSTransition>
        </form>
    )


}

export default PrepareObservation;