import {
    BusinessPlanType,
    BusinessPlanItemHistory,
    BusinessPlanItemStatus,
    BusinessPlanItemStatusType
} from "./BusinessPlanModel";
import {compareDesc} from "date-fns";
import {v4} from "uuid";
import {BusinessUnit, User} from "../Shared/UserModel";
import {FiscalDateHelper} from "../Shared/FiscalDateHelper";


const BusinessPlanHelper = {

    BUSINESS_PLAN_ORIGIN_YEAR: 2022,
    REFERENCE_PLAN_BUSINESS_UNIT: 'T9999',
    REFERENCE_PLAN_BUSINESS_UNIT_NAME: 'Sample Clinic',
    REFERENCE_PLAN_QUARTER: 4,
    REFERENCE_PLAN_YEAR: 2022,
    EDITOR_MODULES: {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['clean']
        ],
    },
    
    isPlanCurrent(plan: BusinessPlanType): boolean {
        const fiscalDateHelper = new FiscalDateHelper(new Date());
        return plan.fiscalQuarter === fiscalDateHelper.getQuarter() && plan.fiscalYear === fiscalDateHelper.getYear();
    },

    createReferenceBusinessUnitObject(): BusinessUnit {
        return {
            businessUnitNumber: BusinessPlanHelper.REFERENCE_PLAN_BUSINESS_UNIT,
            businessUnitName: BusinessPlanHelper.REFERENCE_PLAN_BUSINESS_UNIT_NAME
        }
    },
    
    getFiscalYearsArray(): number[] {
        const yearsArr: number[] = [];
        const thisYear = new FiscalDateHelper(new Date());
        for (let i = this.BUSINESS_PLAN_ORIGIN_YEAR; i <= thisYear.getYear(); i++) {
            yearsArr.push(i);
        }
        
        return yearsArr;
    },

    sortByOrder(items: any[]): any[] {

        if (items == null) {
            return items;
        }

        return items.sort((obj1, obj2) => {
            if (obj1.order > obj2.order) {
                return 1;
            }

            if (obj1.order < obj2.order) {
                return -1;
            }

            return 0;
        });
    },

    getNextOrderNumber(items: any[]) {

        if ( !items || items.length === 0) {
            return 1;
        }

        const sortedArray: any[] = this.sortByOrder(items).reverse();
        return sortedArray[0].order + 1;

    },

    isInputValid(input: string) {
        return input.length > 2 && input !== '';
    },

    getMostRecentEdit(itemHistory: BusinessPlanItemHistory[]): BusinessPlanItemHistory {
        const sortedHistory = itemHistory.sort((s1, s2) => {
            return compareDesc(new Date(s1.dateAdded), new Date(s2.dateAdded));
        });

        return sortedHistory[0];
    },

    getCurrentItemStatus(statusHistory: BusinessPlanItemStatus[]) {
        const sortedHistory = statusHistory?.sort((s1, s2) => {
            return compareDesc(new Date(s1.dateAdded), new Date(s2.dateAdded));
        });

        return sortedHistory[0];
    },

    isItemStatusApproved(statusHistory: BusinessPlanItemStatus[]) {
        return BusinessPlanHelper.getCurrentItemStatus(statusHistory).status === BusinessPlanItemStatusType[BusinessPlanItemStatusType.APPROVED];
    },

    isItemPending(statusHistory: BusinessPlanItemStatus[]) {
        return BusinessPlanHelper.getCurrentItemStatus(statusHistory).status !== BusinessPlanItemStatusType[BusinessPlanItemStatusType.APPROVED]
        && BusinessPlanHelper.getCurrentItemStatus(statusHistory).status !== BusinessPlanItemStatusType[BusinessPlanItemStatusType.DECLINED]
    },

    isItemStatusCancelled(statusHistory: BusinessPlanItemStatus[]) {
        return BusinessPlanHelper.getCurrentItemStatus(statusHistory).status === BusinessPlanItemStatusType[BusinessPlanItemStatusType.CANCELLED];
    },

    isItemStatusDeclined(statusHistory: BusinessPlanItemStatus[]) {
        return statusHistory.length > 0 && BusinessPlanHelper.getCurrentItemStatus(statusHistory).status === BusinessPlanItemStatusType[BusinessPlanItemStatusType.DECLINED];
    },

    isItemStatusChanged(statusHistory: BusinessPlanItemStatus[]) {
        return BusinessPlanHelper.getCurrentItemStatus(statusHistory).status === BusinessPlanItemStatusType[BusinessPlanItemStatusType.CHANGED];
    },

    shouldPersistItemChange(statusHistory: BusinessPlanItemStatus[]) {
        const currentItemStatus = this.getCurrentItemStatus(statusHistory);

        return currentItemStatus.status === BusinessPlanItemStatusType[BusinessPlanItemStatusType.DECLINED] ||
            currentItemStatus.status === BusinessPlanItemStatusType[BusinessPlanItemStatusType.APPROVED];
    },

    canCancelItem(statusHistory: BusinessPlanItemStatus[]) {
        return this.isItemStatusApproved(statusHistory)
            || BusinessPlanHelper.getCurrentItemStatus(statusHistory).status === BusinessPlanItemStatusType[BusinessPlanItemStatusType.CHANGED] ||
            BusinessPlanHelper.getCurrentItemStatus(statusHistory).status === BusinessPlanItemStatusType[BusinessPlanItemStatusType.DECLINED]
    },

    generateStatusHistoryObject(employeeId: string, status: BusinessPlanItemStatusType, declineReason?: string): BusinessPlanItemStatus {
        return {
            id: v4(),
            status: BusinessPlanItemStatusType[status],
            addedBy: employeeId,
            dateAdded: new Date(),
            declineReason: declineReason ? declineReason : null
        }
    },

    countPendingChanges(businessPlan: BusinessPlanType) {

        if (!businessPlan) {
            return 0;
        }

        const [, pendingActions] = BusinessPlanHelper.partitionByStatusHistory(businessPlan.actions, BusinessPlanHelper.isItemStatusApproved)
        const [, pendingInsights] = BusinessPlanHelper.partitionByStatusHistory(businessPlan.insights, BusinessPlanHelper.isItemStatusApproved)
        const [, pendingStatements] = BusinessPlanHelper.partitionByStatusHistory(businessPlan.directionalStatements, BusinessPlanHelper.isItemStatusApproved)

        return pendingActions.length + pendingInsights.length + pendingStatements.length;
    },


    partitionByStatusHistory(array: any, isValid: any) {
        return array
            // @ts-ignore
            .filter(item => !item.deleted)
            // @ts-ignore
            .reduce(([pass, fail], elem: any) => {
            return isValid(elem.itemStatusHistory) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
        }, [[], []]);
    },
    
    editListObject(array: any, editedItem: any) {
        // Out with the old
        const filteredItems = array.filter((_item: any) => _item.id !== editedItem.id);
        
        // In with the new
        filteredItems.push(editedItem);
        
        return filteredItems;
    },
    generateStaffForReferencePlan(currentPlan: BusinessPlanType): User[]  {
        return [
            this.generateTestUser(currentPlan.businessUnit, 'Heath Benbow', '111112', 'Cosmetic Nurse'),
            this.generateTestUser(currentPlan.businessUnit, 'Zierau Garcia', '111111', 'Clinic Development Manager'),
            this.generateTestUser(currentPlan.businessUnit, 'Rene Dallas', '111113', 'Dermal Therapist'),
            this.generateTestUser(currentPlan.businessUnit, 'Alyssa Trask', '111114', 'Cosmetic Nurse'),
            this.generateTestUser(currentPlan.businessUnit, 'Erin Bowen', '111115', 'Client Experience Consultant'),
            this.generateTestUser(currentPlan.businessUnit, 'Lara Mcdaniel', '111116', 'Client Experience Consultant'),
            this.generateTestUser(currentPlan.businessUnit, 'Melanie Bradley', '111117', 'Dermal Therapist'),
            this.generateTestUser(currentPlan.businessUnit, 'Estelle Sims', '111118', 'Dermal Therapist'),
            this.generateTestUser(currentPlan.businessUnit, 'Samantha Adams', '111119', 'Cosmetic Nurse'),
            this.generateTestUser(currentPlan.businessUnit, 'Harley Hunter', '111120', 'Cosmetic Nurse'),
        ]
    },
    generateTestUser(businessUnit: string, name: string, precedaNumber: string, positionTitle: string): User {
        return             {
            assignedBusinessUnit: {businessUnitNumber: businessUnit, businessUnitName: 'Sample Clinic'},
            id: v4(),
            managerId: v4(),
            fullName: name,
            firstName: name.split(' ')[0],
            surname: name.split(' ')[1],
            precedaNumber: precedaNumber,
            title: positionTitle,
            upn: 'email@email.com',
            userAccessToken: 'not-a-real-token',
            positionRole: {
                id: v4(),
                name: 'Test',
                associatedPositionTitles: [],
                associatedEmployeeNumbers: [],
                active: true
            },
            permissions: [],
            availableBusinessUnits: []
        }
    }
}

export default BusinessPlanHelper;
