import {User} from "./UserModel";
import {Permission} from "../Admin/Permissions/PermissionModel";

const UserHelper = {
    hasPermission(user: User, ...permissions: Permission[]) {
        return user.permissions?.some(r => permissions.includes(Permission[r.permission]));
    },
    hasRole(user: User, ...roles: string[]): boolean {
        return roles.filter(role => role.toLowerCase() === user?.positionRole?.name.toLowerCase()).length > 0;
    },
    isAreaManager(user: User) {
        return this.hasRole(user, 'Area Manager')
    }
}

export default UserHelper;

