import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {BusinessPlanService} from "./BusinessPlanService";
import {BusinessPlanBusinessDriver, BusinessPlanTopic} from "./BusinessPlanModel";
import {InputText} from "primereact/inputtext";
import {v4} from "uuid";
import {User} from "../Shared/UserModel";
import {confirmDialog} from "primereact/confirmdialog";
import BusinessPlanHelper from "./BusinessPlanHelper";
import AddValueForm from "./AddValueForm";
import TopicManager from "./TopicManager";

interface DriverManagerProps {
    businessDriver: BusinessPlanBusinessDriver
    user: User;
    setDriversFn: Dispatch<SetStateAction<BusinessPlanBusinessDriver[]>>;
    toast: any;
}

function DriverManager(props: DriverManagerProps) {

    const [topics, setTopics] = useState<BusinessPlanTopic[]>([]);
    const [topicValue, setTopicValue] = useState('');
    const [editing, isEditing] = useState(false);
    const [editValue, setEditValue] = useState('');

    const addTopic = (setLoadingCallback: any) => {
        if (!BusinessPlanHelper.isInputValid(topicValue)) {
            // @ts-ignore
            props.toast.current.show({
                severity: 'warn',
                summary: 'Invalid',
                detail: `Please enter a valid name.`,
                life: 3000
            });
            return;
        }

        const topic: BusinessPlanTopic = {
            id: v4(),
            name: topicValue,
            active: true,
            order: BusinessPlanHelper.getNextOrderNumber(topics),
            driverId: props.businessDriver.id,
            dateAdded: new Date(),
            addedBy: props.user.precedaNumber,
            hasInsights: true,
            hasActions: true,
            hasStatements: false,
            insightTrendReasons: []
        }
        if (props.businessDriver.topics == null) props.businessDriver.topics = [];
        props.businessDriver.topics.push(topic);

        BusinessPlanService.getInstance().upsertDriver(props.businessDriver).then(response => {
            setTopics(response.data.topics);
            setTopicValue('');
            setLoadingCallback(false);
        })
    }

    const confirm = (driverToBeDeactivated: BusinessPlanBusinessDriver) => {
        confirmDialog({
            message: 'Are you sure you would like to make this item inactive?',
            header: 'Confirmation',
            icon: 'pi pi-exclamation-triangle',
            accept: () => deactivateItem(driverToBeDeactivated)
        });
    }

    const deactivateItem = (driverToDeactivated: BusinessPlanBusinessDriver) => {
        driverToDeactivated.active = false;
        BusinessPlanService.getInstance().upsertDriver(driverToDeactivated).then(_ => {
            props.setDriversFn(prevState => prevState.filter(metric => metric.id !== driverToDeactivated.id));
        });
    }

    const editDriver = (driver: BusinessPlanBusinessDriver) => {

        if (!BusinessPlanHelper.isInputValid(editValue)) {
            props.toast.current.show({
                severity: 'warn',
                summary: 'Invalid',
                detail: `Please enter a valid name.`,
                life: 3000
            });
            return;
        }

        driver.modifiedBy = props.user.precedaNumber
        driver.dateModified = new Date();
        driver.name = editValue;

        BusinessPlanService.getInstance().upsertDriver(driver).then(response => {
            props.setDriversFn(prevState => {
                const metrics = [...prevState, response.data];
                return BusinessPlanHelper.sortByOrder(metrics);
            });
            isEditing(false);
        })
    }

    const setEditMode = (businessPlanBusinessDriver: BusinessPlanBusinessDriver) => {
        setEditValue(businessPlanBusinessDriver.name);
        isEditing(true);
    }

    useEffect(() => {
        setTopics(BusinessPlanHelper.sortByOrder(props.businessDriver.topics));
    }, [props.businessDriver.topics]);

    return (
        <div className="driver-item structure-item">
            {editing ?
                <div className="inline-edit-container">
                    <InputText value={editValue} autoFocus data-testid="edit-driver-input"
                               onChange={(e) => setEditValue(e.target.value)}/>
                    <div className="edit-button-group">
                        <i className="pi pi-check" data-testid="edit-driver-button" onClick={() => editDriver(props.businessDriver)}/>
                        <i className="pi pi-times" onClick={() => isEditing(false)}/>
                    </div>
                </div>
                :
                <div className="header-group">
                    <h2 className="editable"
                        onClick={() => setEditMode(props.businessDriver)}>{props.businessDriver.name}</h2>
                    <i className="pi pi-times delete-button" onClick={() => confirm(props.businessDriver)}/>
                </div>
            }
            <h4 className="item-header">Topics</h4>
            <div className="topics-container structure-item">
                {topics?.filter(topic => topic.active).map(topic => {
                    return (
                        <TopicManager toast={props.toast} setTopicsFn={setTopics} key={topic.id} topic={topic} driver={props.businessDriver} user={props.user}/>
                    )
                })}
                <div className="add-value-form topic-form">
                    <h3>Add Topic</h3>
                    <AddValueForm value={topicValue} setValueFn={setTopicValue} onSubmitFn={addTopic}
                                  placeholder="Enter topic name"/>
                </div>
            </div>
        </div>
    )
}

export default DriverManager;