import React from 'react';
import {usePromiseTracker} from "react-promise-tracker";
import {PuffLoader} from "react-spinners";

function LoadingIndicator() {
    const {promiseInProgress} = usePromiseTracker();

    return (
        <>
            {
                promiseInProgress && (
                    <div className="loading-spinner">
                        <PuffLoader size={150}/>
                    </div>
                )
            }
        </>

    );
}

export default LoadingIndicator;