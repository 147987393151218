import React, {useState} from 'react';
import BusinessPlanInsights from "./BusinessPlanInsights";
import {BusinessPlanTopic, BusinessUnitPlan} from "./BusinessPlanModel";
import DirectionalStatements from "./DirectionalStatements";
import BusinessPlanActions from "./BusinessPlanActions";
import {Button} from "primereact/button";
import {Sidebar} from "primereact/sidebar";

interface TopicContentProps {
    topic: BusinessPlanTopic;
    planDetail: BusinessUnitPlan
    toast: any;
    updatePlanDetailFn: any;
    readOnly: boolean;
    refreshPlanFn: any;
    isReference: boolean;
}

function TopicContent(props: TopicContentProps) {

    const [helpTextVisible, setHelpTextVisible] = useState<boolean>(false);

    return (
        <>
            <div className="plan-metric-content">

                {props.topic.helpText &&
                    <>
                        <Button className="p-button-rounded help-button" data-testid="help-button" icon="pi pi-question"
                                onClick={() => setHelpTextVisible(true)}/>
                        <Sidebar blockScroll={false} visible={helpTextVisible} position="left" maskClassName="help-text-mask" onHide={() => setHelpTextVisible(false)}>
                            <h2>Topic Help</h2>
                            <div dangerouslySetInnerHTML={{__html: props.topic.helpText}}/>
                        </Sidebar>
                    </>
                }

                {props.topic.description &&
                    <div className="topic-description">
                        <h2>Description</h2>
                        <div className="pre-line-format description-text"
                             dangerouslySetInnerHTML={{__html: props.topic.description}}/>
                    </div>
                }

                {props.topic.hasStatements &&
                    <DirectionalStatements isReference={props.isReference} refreshPlanFn={props.refreshPlanFn} readOnly={props.readOnly}
                                           toast={props.toast} topic={props.topic} planDetail={props.planDetail}/>}

                <div className="grid">
                    <div className="col">
                        {props.topic.hasInsights &&
                            <BusinessPlanInsights refreshPlanFn={props.refreshPlanFn} readOnly={props.readOnly}
                                                  planDetail={props.planDetail} topic={props.topic} toast={props.toast}
                                                  updatePlanDetailFn={props.updatePlanDetailFn}/>}
                    </div>
                    <div className="col">
                        {props.topic.hasActions &&
                            <BusinessPlanActions refreshPlanFn={props.refreshPlanFn} readOnly={props.readOnly}
                                                 planDetail={props.planDetail} topic={props.topic} toast={props.toast}
                                                 updatePlanDetailFn={props.updatePlanDetailFn}/>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopicContent;